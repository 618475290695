/** @format */

import React from "react";
import { useState } from "react";
import { useEffect } from "react";

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Progress,
  Table,
  Input,
  UncontrolledTooltip,
  Pagination,
  PaginationItem,
  PaginationLink,
  Card,
} from "reactstrap";
import _ from "lodash";
import { Link } from "react-router-dom";
import { currentDate, currentDateTime } from "pages/js/operator";
import { useDispatch } from "react-redux";
import orderApi from "services/order";
import { deleteOrder } from "store/actions/order";
import OrderUpdateModal from "./Modal/OrderUpdateModal";
import { statusData } from "pages/js/orderData";
import { useHistory } from "react-router-dom";

const contractList = ["C", "B", "A"];
// const contractList = ["B", "A"];
// const contractList = ["C", "B", "A"];

const statusValue = statusData.map((item) => item.title);

const OrderTable = ({ orderDataList, searchData, pageIndex, searchType }) => {
  const { user, modelName, orderDate, dueDate } = searchData;
  const dispatch = useDispatch();

  const [tableData, setTableData] = useState([]);

  // 검색 수정, 테이블 get 했을 때 table에 쓸일 데이터 업데이트
  useEffect(() => {
    setTableData([
      ...orderDataList.filter((item) => {
        if (searchType.inputType !== "status") {
          return item[searchType.inputType]
            .toUpperCase()
            .includes(searchType.input.toUpperCase());
        } else {
          if (searchType.input == -1) {
            // 전체 보기
            return true;
          }
          return item[searchType.inputType] == searchType.input;
        }
      }),
    ]);
  }, [orderDataList, searchType]);

  const [page, setPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    if (tableData.length % pageIndex) {
      setPage(parseInt(tableData.length / pageIndex + 1));
    } else {
      setPage(parseInt(tableData.length / pageIndex));
    }
    setCurrentPage(1);
  }, [tableData, pageIndex]);

  // 수정
  const [isUpdate, setIsUpdate] = useState(false);
  const [targetOrder, setTargetOrder] = useState({});
  const handleModal = () => {
    setIsUpdate(false);
  };

  // 삭제
  const [isDelete, setIsDelete] = useState(false);
  const history = useHistory();
  const handleClick = (e, id) => {
    history.push({
      pathname: "detail",
      search: `id=${id}`,
    });
    // const dataList = document.querySelectorAll(".order_table_data");
    // dataList.forEach((item) => {
    //   item.className = "order_table_data";
    // });
    // const target = document.querySelector(`#order_${item}`);
    // if (target) {
    //   target.className = "order_table_data table-light";
    // }
  };

  // 주문 삭제
  const handleDelete = async (order) => {
    try {
      await orderApi.requestDeleteOrder({
        data: {
          id: order.id,
        },
      });
      if (tableData.length % pageIndex === 1) {
        if (currentPage !== 1) {
          setCurrentPage(currentPage - 1);
        }
      }
      dispatch(deleteOrder(order));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Card>
        <Table
          className='align-items-center table-flush border-bottom'
          responsive>
          <thead className='thead-light'>
            <tr>
              <th className='order-table' scope='col' style={{ width: "10px" }}>
                #
              </th>
              <th scope='col'>계약 건명</th>
              {/* <th className='order-table' scope='col'>
                품명
              </th> */}
              <th className='order-table' scope='col'>
                수요기관
              </th>
              <th className='order-table' scope='col'>
                계약 번호
              </th>
              <th className='order-table' scope='col'>
                납기 예정일
              </th>
              <th className='order-table' scope='col'>
                납품 완료일
              </th>
              <th scope='col'>상태</th>
              <th scope='col'></th>
            </tr>
          </thead>
          <tbody>
            {tableData

              .filter(
                (_, idx) =>
                  idx <= currentPage * pageIndex - 1 &&
                  (currentPage - 1) * pageIndex <= idx
              )
              .map((item, index) => {
                const { completedStatus } = item;
                return (
                  <tr
                    key={item.id}
                    id={`order_${item.id}`}
                    // className={`order_table_data ${item.isOver ? "over" : ""}`}
                    className={`order_table_data`}
                    onClick={(e) => console.log()}>
                    <td
                      style={{ width: "10px" }}
                      className='order-table detail-btn'
                      onClick={(e) => handleClick(e, item.id)}>
                      {(currentPage - 1) * pageIndex + index + 1}
                    </td>
                    {/* 계약 명 */}
                    <th
                      className='detail-btn'
                      onClick={(e) => handleClick(e, item.id)}>
                      {/* <Link
                      to={{
                        pathname: "detail",
                        search: `id=${item.id}`,
                      }}>
                      <button
                        type='button'
                        className={`btn btn-link ${item.isOver ? "over" : ""}`}>
                        {item.projectName}
                      </button>
                    </Link> */}
                      {item.projectName}
                    </th>
                    {/* 품명 */}
                    {/* <th className=' order-table'>{item.productName}</th> */}
                    {/* 수요기관  */}
                    <th
                      className='modelName order-table detail-btn'
                      onClick={(e) => handleClick(e, item.id)}>
                      {item.institution}
                    </th>
                    {/* 계약 번호 */}
                    <th
                      className='orderDate order-table detail-btn'
                      onClick={(e) => handleClick(e, item.id)}>
                      <span>{item.projectNumber}</span>
                    </th>
                    {/* 납기 일 */}
                    <th
                      className='dueDate order-table detail-btn'
                      onClick={(e) => handleClick(e, item.id)}>
                      <span>{currentDate(item.dueDate)}</span>
                    </th>
                    {/* 납기 일 */}
                    <th
                      className='dueDate order-table detail-btn'
                      onClick={(e) => handleClick(e, item.id)}>
                      {item.completedStatus.complete ? (
                        <>
                          <span id={`date_${item.id}`}>
                            {currentDate(item.completedStatus.complete)}
                          </span>
                          <UncontrolledTooltip
                            delay={0}
                            trigger='hover focus'
                            target={`date_${item.id}`}>
                            {currentDateTime(item.completedStatus.complete)}
                          </UncontrolledTooltip>
                        </>
                      ) : (
                        <span></span>
                      )}
                    </th>
                    {/* 상태 */}
                    <th
                      className='status detail-btn'
                      onClick={(e) => handleClick(e, item.id)}>
                      <div className='progress-info'>
                        <div className='progress-label'>
                          <span className={`${item.isOver ? "over-text" : ""}`}>
                            {item.isOver
                              ? "기한초과"
                              : statusValue[item.status]}
                          </span>
                          {item.isOver ? (
                            <>
                              <i
                                id={`dueDate-${item.id}`}
                                className='fas fa-exclamation-triangle ml-2'></i>
                              {completedStatus.complete === "" ? (
                                ""
                              ) : (
                                <UncontrolledTooltip
                                  delay={0}
                                  placement='top'
                                  target={`dueDate-${item.id}`}>
                                  {currentDate(completedStatus.complete)}
                                </UncontrolledTooltip>
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className='progress-percentage'>
                          <span>
                            {(
                              (item.status * 100) /
                              (statusValue.length - 1)
                            ).toFixed(1)}
                            %
                          </span>
                        </div>
                      </div>
                      <Progress
                        max='100'
                        className='progress-color'
                        value={(item.status * 100) / (statusValue.length - 1)}
                      />
                    </th>
                    {/* Action */}
                    <td className='text-right'>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          className='btn-icon-only text-light'
                          role='button'
                          size='sm'
                          color=''
                          onClick={(e) => e.preventDefault()}>
                          <i className='fas fa-ellipsis-v' />
                        </DropdownToggle>
                        <DropdownMenu className='dropdown-menu-arrow' right>
                          <DropdownItem
                            className='d-flex align-items-center'
                            onClick={(e) => {
                              setIsUpdate(true);
                              setTargetOrder({ ...item });
                            }}>
                            <i className='fas fa-pencil-alt'></i>
                            Update
                          </DropdownItem>
                          <DropdownItem
                            className='d-flex align-items-center'
                            onClick={(e) => {
                              // setIsDelete(true);
                              // setTargetOrder({ ...item });
                              handleDelete(item);
                            }}>
                            <i
                              className='fas fa-trash-alt'
                              style={{ width: "16px" }}></i>
                            Delete
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Card>
      <nav aria-label='Page navigation example' className='mt-4'>
        <Pagination
          className='pagination justify-content-end'
          listClassName='justify-content-end'>
          <PaginationItem className={currentPage === 1 ? "disabled" : ""}>
            <PaginationLink
              href='#pablo'
              onClick={(e) => e.preventDefault()}
              tabIndex='-1'>
              <i className='fa fa-angle-left' />
              <span className='sr-only'>Previous</span>
            </PaginationLink>
          </PaginationItem>
          {new Array(page).fill(undefined).map((_, index) => (
            <PaginationItem
              key={index}
              className={currentPage === index + 1 ? "active " : ""}
              onClick={() => setCurrentPage(index + 1)}>
              <PaginationLink href='#pablo' onClick={(e) => e.preventDefault()}>
                {index + 1}
              </PaginationLink>
            </PaginationItem>
          ))}
          <PaginationItem className={currentPage === page ? "disabled" : ""}>
            <PaginationLink href='#pablo' onClick={(e) => e.preventDefault()}>
              <i className='fa fa-angle-right' />
              <span className='sr-only'>Next</span>
            </PaginationLink>
          </PaginationItem>
        </Pagination>
      </nav>
      <OrderUpdateModal
        modalOpen={isUpdate}
        toggleModal={handleModal}
        orderData={targetOrder}
      />
      {/* <OrderDeleteModal
        modalOpen={isDelete}
        toggleModal={() => setIsDelete(false)}
        orderData={targetOrder}
      /> */}
    </>
  );
};

export default OrderTable;
