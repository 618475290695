/** @format */

import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import gsap from "gsap";

import OrderUpdateForm from "./OrderUpdateForm";

const OrderUpdateModal = ({ modalOpen, toggleModal, orderData }) => {
  return (
    <>
      <Modal size='l' isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal} tag='h3'>
          계약 수정
        </ModalHeader>
        <ModalBody>
          <div className='orderform'>
            <OrderUpdateForm toggleModal={toggleModal} orderData={orderData} />
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default OrderUpdateModal;
