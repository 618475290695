import React, { useState, useEffect } from "react";
import Autocomplete from "react-autocomplete";
import { debounce } from "lodash";
const AutoCompleteInput = ({
  placeholder,
  handleErrorForm,
  setFormData,
  formData,
  itemList,
  inputType,
}) => {
  const [selected, setSelected] = useState(formData?.[inputType]);

  useEffect(() => {
    setFormData({
      ...formData,
      [inputType]: selected,
    });
    if (handleErrorForm) {
      handleErrorForm(inputType, selected);
    }
  }, [selected]);

  return (
    <div id='auto-complete'>
      <Autocomplete
        getItemValue={(item) => {
          return item;
        }}
        items={itemList
          .filter((item) => {
            if (inputType === "institution") {
              if (
                item.toUpperCase().includes(formData?.[inputType].toUpperCase())
              ) {
                return true;
              }
              return false;
            } else if (inputType === "manager") {
              const { manager } = item;
              if (
                manager
                  .toUpperCase()
                  .includes(formData?.[inputType].toUpperCase())
              ) {
                return true;
              }
              return false;
            }
            return true;
          })
          .map((item) => {
            if (inputType === "manager") {
              return item.manager;
            }
            return item;
          })}
        wrapperStyle={{
          position: "relative",
        }}
        menuStyle={{
          top: "calc(1.5em + 1.25rem + 2px)",
          left: 0,
          position: "absolute",
        }}
        renderItem={(item, isHighlighted) => (
          <option
            key={item}
            className='auto-item pl-2'
            style={{
              background: isHighlighted ? "lightgray" : "white",
              height: "calc(1.5em + 1.25rem + 2px)",
            }}
          >
            {item}
          </option>
        )}
        value={formData?.[inputType]}
        onChange={(e) => {
          setFormData({
            ...formData,
            [inputType]: e.target.value,
          });
        }}
        onSelect={(val) => {
          if (val) {
            setSelected(val);
          }
        }}
        inputProps={{
          placeholder,
          maxLength: 30,
        }}
      />
    </div>
  );
};

export default AutoCompleteInput;
