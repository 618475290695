/** @format */

import axios from "axios";

class UserAPI {
  constructor() {
    this.URL = "";
    this.get = async (url) => {
      const res = await axios.get(url);
      return res.data;
    };
    this.post = async (url, data) => {
      const res = await axios.post(url, data);
      return res.data;
    };
    this.put = async (url, data) => {
      const res = await axios.put(url, data);
      return res.data;
    };
    this.delete = async (url, data) => {
      const res = await axios.delete(url, { data });
      return res.data;
    };
  }

  // 레디 회원가입
  async requestJoin(data) {
    const url = "/api/auth/v1/user";
    return this.post(url, data);
  }

  // 로그인
  async requestLogin(data) {
    const url = "/api/auth/v1/login";
    return this.post(url, data);
  }

  // 이메일 전송
  async requestSendJoinCode(data) {
    const url = "/api/auth/v1/join/code";
    return this.post(url, data);
  }

  // 인증코드 인증
  async requestVerifyJoin(data) {
    const url = "/api/auth/v1/join/verification";
    return this.post(url, data);
  }

  // 유저 정보 가져오기
  async requestGetUser() {
    const url = "/api/auth/v1/user";
    return this.get(url);
  }

  // 유저 로그인할 때 자동 로그아웃
  async requestRemoveToken(data) {
    const url = `/api/auth/v1/logout-with-account`;
    return this.delete(url, data);
  }

  // 리프레쉬 토큰
  async requestUpdateToken(data) {
    const url = `/api/auth/v1/access-token`;
    const headers = {
      Authorization: data,
    };
    return axios.get(url, { headers });
  }

  // 내 자신 로그아웃
  async requestLogout(data) {
    const url = `/api/auth/v1/logout`;
    return this.post(url, data);
  }

  // 비밀번호 재설정 메일
  async requestSendRestCode(data) {
    const url = "/api/auth/v1/reset/code";
    return this.post(url, data);
  }

  // 비밀번호 재설정 메일
  async requestVerifyResetCode(data) {
    const url = "/api/auth/v1/reset/code/verification";
    return this.post(url, data);
  }

  // 비밀번호 재설정
  async requestResetPassword(data) {
    const url = "/api/auth/v1/reset";
    return this.put(url, data);
  }

  // 로그인 유저 정보
  async requestLoginInfo(data) {
    const url = `/api/auth/v1/logininfo`;
    return this.get(url);
  }

  // 회원정보 수정
  async requestUpdateUser(data) {
    const url = `/api/auth/v1/user`;
    return this.put(url, data);
  }

  // 회원 탈퇴
  async requestWithDraw(data) {
    const url = `/api/auth/v1/user`;
    return this.delete(url, data);
  }

  // 유저 공장 등록
  async requestRegisterFactory(data) {
    const url = `api/main/user`;
    return this.post(url, data);
  }

  // 유저 공장 번호 읽기
  async requestReadFactory(data) {
    const url = `api/main/user`;
    return this.get(url, data);
  }

  // 유저 공장 번호 수정
  async requestUpdateFactory(data) {
    const url = `api/main/user`;
    console.log(data);
    return this.put(url, data);
  }

  // 유저 공장 삭제 (탈퇴 했을 때 공장 DB 삭제)
  async requestDeleteFactory(data) {
    const url = `api/main/user`;
    return this.delete(url, data);
  }
}

export default new UserAPI();
