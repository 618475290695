/** @format */

import { action } from "store/reducers/modules/order";

export const {
  addOrder,
  deleteOrder,
  nextStepOder,
  setOrder,
  updateOrder,
  setTargetStatus,
  setActiveTab,
  setCompletedStaus,
} = action;
