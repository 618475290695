/** @format */

// - 계약 건명
// - 품명
// - 수요기관
// - 계약 번호
// - 납기

// projectName, 계약건명
// institution, 수요기관
// projectNumber, 계약번호
// orderNumber, 발주번호
// registerNumber, 등록번호
// orderDate, 주문일
// dueDate, 납기일
// status,
// manager, 담당자
// startDate,
// endDate,
// completedStatus,

import React, { useEffect } from "react";
import { useState } from "react";

import {
  Col,
  Row,
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Label,
  FormFeedback,
} from "reactstrap";
import ReactDatetime from "react-datetime";
import _ from "lodash";

import moment from "moment";
import "moment/locale/ko";
import { useDispatch, useSelector } from "react-redux";
import orderApi from "services/order";
import { updateOrder } from "store/actions/order";
import AutoCompleteInput from "./AutoCompleteInput";
import InstitutionAPI from "services/institution";

const OrderUpdateForm = ({ toggleModal, orderData }) => {
  const dispatch = useDispatch();

  const { orderList } = useSelector((state) => ({
    orderList: state.order.orderList,
  }));

  const [institutionList, setInstitutionList] = useState({});

  useEffect(() => {
    const newData = {};

    InstitutionAPI.requestRead().then((res) => {
      const newData = {};
      for (let item of res) {
        const { name, manager, id } = item;

        if (newData[name]) {
          newData[name].push({
            id,
            manager,
            isCreate: false,
            disabled: true,
          });
        } else {
          newData[name] = [
            {
              id,
              manager,
              isCreate: false,
              disabled: true,
            },
          ];
        }
      }

      setInstitutionList(newData);
    });
  }, [orderList]);

  const [originalProduct, setOriginalProduct] = useState([]);
  const [formData, setFormData] = useState({
    projectName: "",
    institution: "",
    projectNumber: "",
    orderNumber: "", // 계약번호
    registerNumber: "", // 발주번호
    orderDate: moment(new Date()),
    dueDate: "",
    status: "",
    manager: "",
    startDate: "",
    endDate: "",
    completedStatus: "",
  });

  const [errForm, setErrForm] = useState({
    projectName: false,
    institution: false,
    projectNumber: false,
    orderNumber: false,
    registerNumber: false,
    orderDate: false,
    dueDate: false,
    status: false,
    manager: false,
    startDate: false,
    endDate: false,
    completedStatus: false,
  });

  useEffect(() => {
    orderApi
      .requestReadProductList({
        params: {
          orderID: orderData.id,
        },
      })
      .then((res) => {
        setFormData({
          ...orderData,
          dueDate: moment(new Date(orderData.dueDate)),
          orderDate: moment(new Date(orderData.orderDate)),
        });

        const newData = res.map((item) => {
          let newConfirmData = {};
          if (typeof item.confirmData === "string") {
            return {
              ...item,
              confirmData: JSON.parse(item.confirmData),
            };
          } else {
            return item;
          }
        });

        setProductList([...newData]);
        setOriginalProduct([...newData]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [orderData]);

  // 테이블 데이터 수정하기

  const handleSubmit = async () => {
    const { orderDate, dueDate } = formData;

    let deleteProduct = [];

    for (let item of originalProduct) {
      if (_.isUndefined(_.find(productList, { id: item.id }))) {
        deleteProduct.push(item.id);
      }
    }
    console.log(productList);
    try {
      const res = await orderApi.requestUpdateOrder({
        ...formData,
        dueDate: dueDate._d.getTime(),
        orderDate: orderDate._d.getTime(),
        productList,
        deleteProduct,
      });
      dispatch(
        updateOrder({
          ...formData,
          dueDate: dueDate._d.getTime(),
          orderDate: orderDate._d.getTime(),
        })
      );
      toggleModal();
    } catch (err) {
      console.log(err);
    }
  };

  const [btn, setBtn] = useState(true);

  const [productList, setProductList] = useState([
    {
      id: 0,
      name: "",
      targetQuantity: "",
      err: "",
    },
  ]);

  const handleProductList = (id, column, value) => {
    const newData = _.cloneDeep(productList);
    let replaceNumber = value.replace(/[^0-9]/g, "");

    newData.forEach((item, index) => {
      if (item.id === id) {
        if (Number(replaceNumber) >= 9007199254740991) {
          item.err = "수량을 확인해 주세요.";
        } else {
          item.err = false;
        }

        if (column === "targetQuantity") {
          if (replaceNumber === "") {
            item[column] = replaceNumber;
          } else {
            replaceNumber = Number(replaceNumber);
            item[column] = replaceNumber;
          }
        } else {
          item[column] = value;
        }
        return;
      }
    });
    setProductList(newData);
  };

  // 납기일, 계약건명, 계약번호, 수요기관, 품명 최소 1개
  useEffect(() => {
    const {
      projectName,
      institution,
      projectNumber,
      dueDate,
      orderNumber,
      registerNumber,
      manager,
    } = formData;

    let isEmpty = true;

    for (let item of productList) {
      if (item.name) {
        if (item.targetQuantity) {
          isEmpty = false;
        }
      }
      if (item.targetQuantity >= 9007199254740991) {
        setBtn(true);
        return;
      }
    }

    if (projectName && projectNumber && dueDate && !isEmpty) {
      if (
        projectName.length <= 20 &&
        institution.length <= 20 &&
        projectNumber.length <= 20
      ) {
        if (orderNumber && registerNumber && manager) {
          if (
            !(
              orderNumber.length > 15 ||
              registerNumber.length > 15 ||
              manager.length > 10
            )
          ) {
            setBtn(false);
            return;
          }
        } else {
          setBtn(false);
          return;
        }
      }
    }

    setBtn(true);
  }, [formData, productList]);

  // 유효성 검사
  const handleErrorForm = (index, value) => {
    const error = {
      ...errForm,
    };

    switch (index) {
      case "projectName":
        if (value.length === 0) {
          error.projectName = "계약건명을 입력하세요.";
        } else if (value.length > 20) {
          error.projectName = "계약건명은 최대 20자입니다.";
        } else {
          error.projectName = false;
        }
        break;
      case "projectNumber":
        if (value.length === 0) {
          error.projectNumber = "계약번호을 입력하세요.";
        } else if (value.length > 20) {
          error.projectNumber = "계약번호는 최대 20자입니다.";
        } else {
          error.projectNumber = false;
        }
        break;
      case "institution":
        if (value.length > 20) {
          error.institution = "수요기관는 최대 20자입니다.";
        } else {
          error.institution = false;
        }
        break;
      case "orderNumber":
        if (value.length > 15) {
          error.orderNumber = "발주번호는 최대 15자입니다.";
        } else {
          error.orderNumber = false;
        }
        break;
      case "registerNumber":
        if (value.length > 15) {
          error.registerNumber = "등록번호는 최대 15자입니다.";
        } else {
          error.registerNumber = false;
        }
        break;
      case "manager":
        if (value.length > 10) {
          error.manager = "담당자는 최대 10자입니다.";
        } else {
          error.manager = false;
        }
        break;

      default:
        break;
    }

    setErrForm({
      ...error,
    });
  };

  return (
    <>
      <Row>
        <Col xs={12}>
          <Form role='form'>
            <FormGroup>
              <label className='form-control-label' htmlFor='input-username'>
                * 납기 예정일
              </label>
              <InputGroup className='input-group-alternative'>
                <InputGroupAddon addonType='prepend'>
                  <InputGroupText>
                    <i className='ni ni-calendar-grid-58' />
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  closeOnSelect={true}
                  inputProps={{
                    placeholder: "납기 일",
                    readOnly: true,
                  }}
                  className='add-date'
                  dateFormat={"YYYY-MM-DD"}
                  timeFormat={false}
                  value={formData.dueDate}
                  onChange={(e) => setFormData({ ...formData, dueDate: e })}
                />
              </InputGroup>
            </FormGroup>

            <Row>
              <Col xs={12} md={6}>
                {/* 계약 건명 */}
                <FormGroup className='mb-3'>
                  <label
                    className='form-control-label'
                    htmlFor='input-username'
                  >
                    * 계약건명
                  </label>
                  <FormGroup
                    className={`${errForm.projectName ? "has-danger" : ""}`}
                  >
                    <Input
                      className=''
                      invalid={errForm.projectName ? true : false}
                      value={formData.projectName}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          projectName: e.target.value,
                        });
                        handleErrorForm("projectName", e.target.value);
                      }}
                      placeholder='계약 건명'
                      type='text'
                    />
                    <FormFeedback className='feedbackform'>
                      {errForm.projectName}
                    </FormFeedback>
                  </FormGroup>
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <label className='form-control-label' htmlFor='input-username'>
                  * 계약번호
                </label>
                {/* 계약 번호 */}
                <FormGroup
                  className={`${errForm.projectNumber ? "has-danger" : ""}`}
                >
                  <Input
                    className=''
                    invalid={errForm.projectNumber ? true : false}
                    value={formData.projectNumber}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        projectNumber: e.target.value,
                      });
                      handleErrorForm("projectNumber", e.target.value);
                    }}
                    placeholder='계약 번호'
                    type='text'
                  />
                  <FormFeedback className='feedbackform'>
                    {errForm.projectNumber}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>

            {/* 수요기관, 발주번호 */}
            <Row>
              <Col xs={12} md={6}>
                <label className='form-control-label' htmlFor='input-username'>
                  수요기관
                </label>
                {/* 수요기관 */}
                <FormGroup
                  className={`${errForm.institution ? "has-danger" : ""}`}
                >
                  <AutoCompleteInput
                    placeholder={"수요기관"}
                    handleErrorForm={handleErrorForm}
                    setFormData={setFormData}
                    formData={formData}
                    errForm={errForm}
                    itemList={Object.keys(institutionList)}
                    inputType={"institution"}
                  />

                  <FormFeedback className='feedbackform'>
                    {errForm.institution}
                  </FormFeedback>
                </FormGroup>
              </Col>

              <Col xs={12} md={6}>
                <label className='form-control-label' htmlFor='input-username'>
                  담당자
                </label>
                {/* 담당자 */}
                <FormGroup className={`${errForm.manager ? "has-danger" : ""}`}>
                  <AutoCompleteInput
                    placeholder={"담당자"}
                    handleErrorForm={handleErrorForm}
                    setFormData={setFormData}
                    formData={formData}
                    errForm={errForm}
                    itemList={
                      institutionList[formData.institution]
                        ? institutionList[formData.institution]
                        : []
                    }
                    inputType={"manager"}
                  />
                  <FormFeedback className='feedbackform'>
                    {errForm.manager}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>

            {/* 등록번호, 담장자 */}
            <Row>
              <Col xs={12} md={6}>
                <label className='form-control-label' htmlFor='input-username'>
                  등록번호
                </label>
                {/* 등록번호 */}
                <FormGroup
                  className={`${errForm.registerNumber ? "has-danger" : ""}`}
                >
                  <Input
                    className=''
                    invalid={errForm.registerNumber ? true : false}
                    value={formData.registerNumber}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        registerNumber: e.target.value,
                      });
                      handleErrorForm("registerNumber", e.target.value);
                    }}
                    placeholder='등록번호'
                    type='text'
                  />
                  <FormFeedback className='feedbackform'>
                    {errForm.registerNumber}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <label className='form-control-label' htmlFor='input-username'>
                  발주번호
                </label>
                {/* 발주번호 */}
                <FormGroup
                  className={`${errForm.orderNumber ? "has-danger" : ""}`}
                >
                  <Input
                    className=''
                    invalid={errForm.orderNumber ? true : false}
                    value={formData.orderNumber}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        orderNumber: e.target.value,
                      });
                      handleErrorForm("orderNumber", e.target.value);
                    }}
                    placeholder='발주번호'
                    type='text'
                  />
                  <FormFeedback className='feedbackform'>
                    {errForm.orderNumber}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <hr />

            {/* 품명 */}
            <Row>
              <Col xs={5}>
                <FormGroup className='mb-3'>
                  <Label
                    className='form-control-label'
                    htmlFor='input-username'
                  >
                    * 품명
                  </Label>
                </FormGroup>
              </Col>
              <Col xs={5}>
                <FormGroup className='mb-3'>
                  <Label
                    className='form-control-label'
                    htmlFor='input-username'
                  >
                    * 수량
                  </Label>
                </FormGroup>
              </Col>
              {/* <Col xs={2} className='d-flex align-items-end'>
                <FormGroup className='mb-3'>
                  <Label
                    className='form-control-label'
                    htmlFor='input-username'></Label>
                  <i className='fas fa-trash-alt'></i>
                </FormGroup>
              </Col> */}
            </Row>

            {/* 품명 */}
            {productList.map((item, index) => {
              return (
                <Row key={index}>
                  <Col xs={5}>
                    <FormGroup className='mb-4'>
                      <Input
                        onChange={(e) =>
                          handleProductList(item.id, "name", e.target.value)
                        }
                        value={item.name}
                        placeholder='품명'
                        type='text'
                        maxLength={20}
                      />
                    </FormGroup>
                  </Col>
                  {/* 수량 */}
                  <Col xs={5}>
                    <FormGroup
                      className={`mb-3 ${item.err ? "has-danger" : ""}`}
                    >
                      <Input
                        invalid={item.err ? true : false}
                        onChange={(e) =>
                          handleProductList(
                            item.id,
                            "targetQuantity",
                            e.target.value
                          )
                        }
                        value={item.targetQuantity}
                        placeholder='수량'
                        type='text'
                      />
                      <FormFeedback className='feedbackform'>
                        {item.err}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col xs={2} className='d-flex align-items-center'>
                    <FormGroup className='mb-3'>
                      <Label
                        className='form-control-label'
                        htmlFor='input-username'
                      ></Label>
                      <i
                        className='fas fa-trash-alt'
                        onClick={(e) => {
                          const data = productList.filter(
                            (data) => data.id !== item.id
                          );
                          setProductList(data);
                        }}
                      ></i>
                    </FormGroup>
                  </Col>
                </Row>
              );
            })}

            {/* <div className='d-flex justify-content-center my-3'>
              <i
                className='far fa-plus-square'
                onClick={() => {
                  setProductList([
                    ...productList,
                    {
                      id: productList.length,
                      name: "",
                      targetQuantity: "",
                    },
                  ]);
                }}></i>
            </div> */}

            {/* <FormGroup>
              <label className='form-control-label' htmlFor='input-username'>
                * 접수 일
              </label>
              <Row>
                <Col>
                  <InputGroup className='input-group-alternative'>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='ni ni-calendar-grid-58' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <ReactDatetime
                      inputProps={{
                        placeholder: "접수 일",
                      }}
                      closeOnSelect={true}
                      dateFormat={"YYYY-MM-DD"}
                      isValidDate={(current, selectedDate) => {
                        if (formData.dueDate) {
                          return current._d <= formData.dueDate;
                        }
                        return true;
                      }}
                      timeFormat={false}
                      renderDay={(props, currentDate, selectedDate) => {
                        let classes = props.className;
                        if (
                          formData.orderDate &&
                          formData.dueDate &&
                          formData.orderDate._d + "" === currentDate._d + ""
                        ) {
                          classes += " start-date";
                        } else if (
                          formData.orderDate &&
                          formData.dueDate &&
                          new Date(formData.orderDate._d + "") <
                            new Date(currentDate._d + "") &&
                          new Date(formData.dueDate._d + "") >
                            new Date(currentDate._d + "")
                        ) {
                          classes += " middle-date";
                        } else if (
                          formData.dueDate &&
                          formData.dueDate._d + "" === currentDate._d + ""
                        ) {
                          classes += " end-date";
                        }
                        return (
                          <td {...props} className={classes}>
                            {currentDate.date()}
                          </td>
                        );
                      }}
                      onChange={(e) =>
                        setFormData({ ...formData, orderDate: e })
                      }
                      initialValue={moment(new Date())}
                    />
                  </InputGroup>
                </Col>
              </Row>
            </FormGroup> */}

            {/* <FormGroup className='mb-3'>
              <Input
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                value={formData.name}
                placeholder=''
                type='text'
              />
            </FormGroup>

            <FormGroup className='mb-3'>
              <Input
                onChange={(e) =>
                  setFormData({ ...formData, orderNumber: e.target.value })
                }
                value={formData.orderNumber}
                placeholder='발주 번호'
                type='text'
              />
            </FormGroup>

            <FormGroup className='mb-3'>
              <Input
                onChange={(e) =>
                  setFormData({ ...formData, status: e.target.value })
                }
                value={formData.status}
                placeholder='상태'
                type='text'
              />
            </FormGroup>

            <FormGroup className='mb-3'>
              <Input
                onChange={(e) =>
                  setFormData({ ...formData, memo: e.target.value })
                }
                value={formData.memo}
                placeholder='비고'
                type='textarea'
                rows='4'
              />
            </FormGroup> */}

            <div className='custom-control custom-control-alternative custom-checkbox'>
              <input
                className='custom-control-input'
                id=' customCheckLogin'
                type='checkbox'
              />
            </div>
            <div className='text-center'>
              <Button
                className='mb-4'
                color='primary'
                type='button'
                onClick={handleSubmit}
                disabled={btn}
              >
                수정
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default OrderUpdateForm;
