/** @format */

// interval에 따른 시간 합치기
function mergeDate(date, interval) {
  const [YYYY, TTTT] = date.split(" ");
  const [hour, minute, second] = TTTT.split(":");

  if (interval === 2400) {
    return `${YYYY} ${hour}:00:00`;
  } else if (interval === 1200) {
    return `${YYYY} ${hour}:${(
      "0" + (Number(minute) - (Number(minute) % 30)).toString()
    ).slice(-2)}:00`;
  } else if (interval === 300) {
    return `${YYYY} ${hour}:${(
      "0" + (Number(minute) - (Number(minute) % 10)).toString()
    ).slice(-2)}:00`;
  } else if (interval === 40) {
    return `${YYYY} ${hour}:${minute}:00`;
  } else if (interval === 20) {
    return `${YYYY} ${hour}:${minute}:${(
      "0" + (Number(second) - (Number(second) % 30)).toString()
    ).slice(-2)}`;
  } else if (interval === 10) {
    return `${YYYY} ${hour}:${minute}:${(
      "0" + (Number(second) - (Number(second) % 10)).toString()
    ).slice(-2)}`;
  } else {
    return date;
  }
}

function currentDate(data) {
  const resUtc = new Date(data);
  const year = resUtc.getFullYear();
  const month = ("0" + (resUtc.getMonth() + 1)).slice(-2);
  const date = ("0" + resUtc.getDate()).slice(-2);

  const hours = ("0" + resUtc.getHours()).slice(-2);
  const minutes = ("0" + resUtc.getMinutes()).slice(-2);
  const seconds = ("0" + resUtc.getSeconds()).slice(-2);

  return `${year}-${month}-${date}`;
}

function currentDatehangle(data) {
  const resUtc = new Date(data);
  const year = resUtc.getFullYear();
  const month = ("0" + (resUtc.getMonth() + 1)).slice(-2);
  const date = ("0" + resUtc.getDate()).slice(-2);

  const hours = ("0" + resUtc.getHours()).slice(-2);
  const minutes = ("0" + resUtc.getMinutes()).slice(-2);
  const seconds = ("0" + resUtc.getSeconds()).slice(-2);

  return `${year}년 ${month}월 ${date}일 ${hours}시 ${minutes}분`;
}

function currentDateTime(data) {
  const resUtc = new Date(data);
  const year = resUtc.getFullYear();
  const month = ("0" + (resUtc.getMonth() + 1)).slice(-2);
  const date = ("0" + resUtc.getDate()).slice(-2);

  const hours = ("0" + resUtc.getHours()).slice(-2);
  const minutes = ("0" + resUtc.getMinutes()).slice(-2);
  const seconds = ("0" + resUtc.getSeconds()).slice(-2);

  return `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`;
}

/** @format */

// 자동 하이푼 붙이기
function autoHypenPhone(value) {
  const str = value.replace(/[^0-9]/g, "");

  var tmp = "";

  if (str.length < 4) {
    return str;
  } else if (str.length < 7) {
    tmp += str.substr(0, 3);
    tmp += "-";
    tmp += str.substr(3);
    return tmp;
  } else if (str.length < 11) {
    tmp += str.substr(0, 3);
    tmp += "-";
    tmp += str.substr(3, 3);
    tmp += "-";
    tmp += str.substr(6);
    return tmp;
  } else {
    tmp += str.substr(0, 3);
    tmp += "-";
    tmp += str.substr(3, 4);
    tmp += "-";
    tmp += str.substr(7);

    return tmp;
  }
}

function excelCurrentDate(data) {
  const resUtc = new Date(data);
  const year = resUtc.getFullYear();
  const month = ("0" + (resUtc.getMonth() + 1)).slice(-2);
  const date = ("0" + resUtc.getDate()).slice(-2);

  const hours = ("0" + resUtc.getHours()).slice(-2);
  const minutes = ("0" + resUtc.getMinutes()).slice(-2);
  const seconds = ("0" + resUtc.getSeconds()).slice(-2);

  return `${year}.${month}.${date}`;
}

export {
  mergeDate,
  currentDate,
  autoHypenPhone,
  currentDateTime,
  currentDatehangle,
  excelCurrentDate,
};
