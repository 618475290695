/**
 * /*!
 *
 * =========================================================
 * Argon Dashboard React - v1.2.1
 * =========================================================
 *
 * Product Page: https://www.creative-tim.com/product/argon-dashboard-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)
 *
 * Coded by Creative Tim
 *
 * =========================================================
 *
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 *
 * @format
 */

/*eslint-disable*/

import LOGO from "assets/img/logo2.png";

// reactstrap components
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";
const Footer = () => {
  return (
    <>
      <footer className='footer mt-5'>
        <Container className=''>
          <Row className='align-items-center'>
            <Col lg='2'>
              <div className='text-lg-start'>
                <img src={LOGO} height='50' alt='ee' style={{ opacity: 0.7 }} />
              </div>
            </Col>
            <Col lg='4'>
              <div className='text-lg-start' style={{ fontSize: "0.8rem" }}>
                <span className='mb-0'>
                  Copyright © All rights reserved by (주)이비기술.
                </span>
              </div>
            </Col>
            <Col
              lg='6'
              className=' mt-sm-0 pt-2 pt-sm-0'
              style={{ fontSize: "0.8rem" }}
            >
              <div className='text-lg-end '>
                <span className='mb-0'>
                  Zip.18387 / 54-10, Gisanmal-gil, Hwaseong-si, Gyeonggi-do,
                  Republic of Korea
                </span>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
