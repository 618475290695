/** @format */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";

const initState = {
  isLogin: true,
  userInfo: {
    email: "admin@ijoon.net",
    name: "admin",
    phoneNumber: ",",
  },
  isLoading: false,
};

const user = createSlice({
  name: "userInfo",
  initialState: { ...initState },
  reducers: {
    login(state, action) {
      return { ...state, isLogin: true, userInfo: { ...action.payload } };
    },
    logout(state, action) {
      localStorage.removeItem("mes_user");
      return {
        ...state,
        isLogin: false,
        userInfo: { email: "", name: "", phoneNumber: "" },
      };
    },
    setIsLoading(state, action) {
      return { ...state, isLoading: action.payload };
    },
  },
});

export const action = user.actions;
export default user.reducer;
