/** @format */

import _ from "lodash";
import React from "react";
import { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  FormFeedback,
} from "reactstrap";
import purchaseApi from "services/purchase";
import AutoCompleteInput from "./AutoCompleteInput";
import useIsMount from "../../../hooks/useIsMount";
import InstitutionAutoComplete from "./InstitutionAutoComplete";

const AddPurchaseModal = ({ modalOpen, toggleModal, onSubmit, activeTab }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");

  const [isBtn, setIsBtn] = useState(false);

  const isMount = useIsMount();

  const [formData, setFormData] = useState({
    tab: activeTab,
    company: "",
    productName: "",
    spec: "",
    unit: "",
    orderQuantity: "",
    currentQuantity: 0,
    memo: "",
  });

  useEffect(() => {
    setFormData({
      tab: activeTab,
      company: "",
      productName: "",
      spec: "",
      unit: "",
      orderQuantity: "",
      currentQuantity: 0,
      memo: "",
    });
  }, [modalOpen]);

  // 상호명 고르는 리스트 가져오기
  const [companyOptions, setCompanyOptions] = useState([""]);
  const [productOptions, setProductOptions] = useState([""]);

  useEffect(() => {
    // purchaseApi
    //   .requestReadIndexList({
    //     params: {
    //       orderID: id,
    //       company: "total",
    //     },
    //   })
    //   .then((res) => {
    //     console.log(res);
    //     if (isMount.current) {
    //       setFormData({
    //         ...formData,
    //         company: res.companyList[0],
    //       });
    //       setCompanyOptions(res.companyList);
    //       setProductOptions(res.productList);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }, []);

  const handleSubmit = () => {
    onSubmit({ ...formData });
    setFormData({
      tab: activeTab,
      ...formData,
      productName: "",
      spec: "",
      unit: "",
      orderQuantity: "",
      currentQuantity: 0,
      memo: "",
    });
  };

  // 규격, 단위, 단가 자동입력
  useEffect(() => {
    if (!_.isEmpty(productOptions[formData.productName])) {
      const { spec, unit, unitPrice } = productOptions[formData.productName];

      setFormData({
        ...formData,
        spec,
        unit,
        unitPrice,
        money: formData.quantity * unitPrice,
      });
    }
  }, [formData.productName]);

  // 상호명 변경하기
  useEffect(() => {
    if (formData.company) {
      if (companyOptions.includes(formData.company)) {
        purchaseApi
          .requestReadIndexList({
            params: {
              orderID: id,
              company: formData.company,
            },
          })
          .then((res) => {
            console.log(res);
            setProductOptions(res.productList);
            setFormData({
              ...formData,
              productName: "",
              spec: "",
              unit: "",
              quantity: 0,
              unitPrice: 0,
              money: 0,
              currentQuantity: 0,
              memo: "",
            });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setProductOptions([]);
      }
    }
  }, [formData.company]);

  // 숫자만 입력
  const [numberErr, setNumberErr] = useState(false);
  const handleNumber = (e, target) => {
    let replaceNumber = Number(e.target.value.replace(/[^0-9]/g, ""));

    if (replaceNumber >= 9007199254740991) {
      setNumberErr("수량을 확인해주세요.");
    } else {
      setNumberErr(false);
    }

    setFormData({
      ...formData,
      [target]: replaceNumber === 0 ? "" : replaceNumber,
    });
  };

  // 버튼 활성화
  useEffect(() => {
    const { tab, company, productName, spec, unit, orderQuantity } = formData;
    if (orderQuantity >= 9007199254740991) {
      setIsBtn(true);
      return;
    }
    if (tab && company && productName && spec && unit && orderQuantity) {
      setIsBtn(false);
    } else {
      setIsBtn(true);
    }
  }, [formData]);

  return (
    <>
      <Modal size='l' isOpen={modalOpen} toggle={() => toggleModal(false)}>
        <ModalHeader toggle={() => toggleModal(false)} tag='h3'>
          {activeTab == 0 ? "원자재 등록" : "외주가공 등록"}
        </ModalHeader>
        <ModalBody>
          <div className='orderform'>
            <Row>
              <Col xs={12}>
                <Form role='form'>
                  {/* 상호명 */}
                  <FormGroup className='mb-3'>
                    <label
                      className='form-control-label'
                      htmlFor='input-username'
                    >
                      * 상호명
                    </label>
                    {/* <InstitutionAutoComplete
                      companyOptions={companyOptions}
                      setFormData={setFormData}
                      formData={formData}
                    /> */}
                    <Input
                      type='text'
                      value={formData.company}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          company: e.target.value,
                        });
                      }}
                      placeholder='상호명'
                    ></Input>
                  </FormGroup>

                  <Row>
                    <Col md={6}>
                      {/* 품명 */}
                      <FormGroup className='mb-3'>
                        <label
                          className='form-control-label'
                          htmlFor='input-username'
                        >
                          * 품명
                        </label>
                        {/* <Input
                      placeholder='품명'
                      type='text'
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          productName: e.target.value,
                        })
                      }
                      value={formData.productName}
                    /> */}
                        {/* <AutoCompleteInput
                          productOptions={productOptions}
                          setFormData={setFormData}
                          formData={formData}
                        /> */}
                        <Input
                          type='text'
                          value={formData.productName}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              productName: e.target.value,
                            });
                          }}
                          placeholder='품명'
                        ></Input>
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      {/* 규격 */}
                      <FormGroup className='mb-3'>
                        <label
                          className='form-control-label'
                          htmlFor='input-username'
                        >
                          * 규격
                        </label>
                        <Input
                          placeholder='규격'
                          type='text'
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              spec: e.target.value,
                            })
                          }
                          maxLength={20}
                          value={formData.spec}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      {/* 단위 */}
                      <FormGroup className='mb-3'>
                        <label
                          className='form-control-label'
                          htmlFor='input-username'
                        >
                          * 단위
                        </label>
                        <Input
                          placeholder='단위'
                          type='text'
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              unit: e.target.value,
                            })
                          }
                          maxLength={20}
                          value={formData.unit}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      {/* 수량 */}
                      <FormGroup
                        className={`mb-3 ${numberErr ? "has-danger" : ""}`}
                      >
                        <label
                          className='form-control-label'
                          htmlFor='input-username'
                        >
                          * 수량
                        </label>
                        <Input
                          invalid={numberErr ? true : false}
                          placeholder='수량'
                          type='text'
                          onChange={(e) => handleNumber(e, "orderQuantity")}
                          value={formData.orderQuantity}
                        />
                        <FormFeedback className='feedbackform'>
                          {numberErr}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>

                  <div className='custom-control custom-control-alternative custom-checkbox'>
                    <input
                      className='custom-control-input'
                      id=' customCheckLogin'
                      type='checkbox'
                    />
                  </div>
                  <div className='text-center'>
                    <Button
                      className='mb-4'
                      color='primary'
                      type='button'
                      disabled={isBtn}
                      onClick={() => handleSubmit()}
                    >
                      등록
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default AddPurchaseModal;
