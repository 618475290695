/** @format */
import Header from "components/Headers/Header.js";

import React, { useState } from "react";

import { Button, Card, Container, Row, Col, CardBody } from "reactstrap";
import OrderSearch from "./OrderSearch";
import OrderTable from "./OrderTable";
import OrderModal from "./Modal/OrderModal";
import { currentDate } from "pages/js/operator";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addOrder, deleteOrder, setOrder } from "store/actions/order";
import orderApi from "services/order";
import OrderProgress from "./OrderProgress";
import ModelModal from "./Modal/ModelModal";
import InstitutionModal from "./Modal/InstitutionModal";

const OrderPage = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { tableData } = useSelector((state) => ({
    tableData: state.order.orderList,
  }));
  const [progress, setProgress] = useState({
    waiting: 0,
    progressing: 0,
    completion: 0,
    overdue: 0,
    total: tableData.length,
  });

  useEffect(() => {
    const data = {
      waiting: 0,
      progressing: 0,
      completion: 0,
      overdue: 0,
      total: tableData.length,
    };

    const date = new Date().getTime() - 1000 * 60 * 60 * 24;
    console.log(tableData);
    for (let item of tableData) {
      const {
        completedStatus: { complete },
      } = item;

      if (complete) {
        if (complete > item.dueDate + 1000 * 60 * 60 * 24) {
          data.overdue += 1;
        } else {
          data.completion += 1;
        }
      } else {
        if (date > item.dueDate) {
          data.overdue += 1;
        } else {
          if (item.status === 0) {
            data.waiting += 1;
          } else if (item.status > 6) {
            data.completion += 1;
          } else {
            data.progressing += 1;
          }
        }
      }
    }

    setProgress({ ...data });
  }, [tableData]);

  const dispatch = useDispatch();

  // 모달 닫기
  const toggleMadal = () => {
    setModalOpen(false);
  };

  // 테이블 데이터 추가하기
  const onSumbit = async (data) => {
    setModalOpen(false);
  };

  // searchData
  const [searchData, setSearchData] = useState({
    user: "",
    modelName: "",
    orderDate: "",
    dueDate: "",
  });

  // checkBoxList
  const [checkBoxList, setcheckBoxList] = useState([]);

  // delete
  const handleDelete = (data) => {
    dispatch(deleteOrder(data));
  };

  // update
  const [isUpdate, setisUpdate] = useState(false);

  // 검색 부분
  const storedSearchType = localStorage.getItem("mes_search")
    ? JSON.parse(localStorage.getItem("mes_search"))
    : {
        inputType: "projectName",
        input: "",
      };

  const [searchType, setSearchType] = useState({
    ...storedSearchType,
  });

  // 페이지 목록 변경
  const storedPageIndex = localStorage.getItem("mes_page")
    ? Number(JSON.parse(localStorage.getItem("mes_page")))
    : 5;

  const [pageIndex, setPageIndex] = useState(storedPageIndex);

  useEffect(() => {
    localStorage.setItem("mes_page", JSON.stringify(pageIndex));
  }, [pageIndex]);

  useEffect(() => {
    localStorage.setItem("mes_search", JSON.stringify(searchType));
  }, [searchType]);

  const [modelModal, setModelModal] = useState(false);
  ////

  const [institutionModal, setInstitutionModal] = useState(false);

  return (
    <>
      <Header />
      <Container className='' fluid>
        {/* <h1 className='order-table-container mb-5'>
          Order Status ({tableData.length})
        </h1> */}
        {/* <Row>
          <Col className='d-flex justify-content-between'>
          <h1 className='mb-5'>Order Registration Status</h1>
          </Col>
        </Row> */}
        <div className='order-table-container '>
          {/* <h4 className='title mb-4'>주문현황</h4> */}
          {/* <div className='text-right'>
            <span>{tableData.length}</span>
          </div> */}

          {/* <Card className='mb-5'>
            <CardBody>
            </CardBody>
          </Card> */}
          <OrderProgress progress={progress} />

          <Card>
            <CardBody className='py-5'>
              <div className='d-flex justify-content-between'>
                <div>
                  <Button
                    color='primary'
                    style={{ boxShadow: "0 0 3px rgba(60, 72, 88, 0.5)" }}
                    onClick={(e) => setModelModal(true)}
                    size='sm'
                  >
                    모델
                  </Button>

                  <Button
                    style={{ boxShadow: "0 0 3px rgba(135, 143, 153, 0.5)" }}
                    onClick={(e) => setInstitutionModal(true)}
                    size='sm'
                  >
                    수요기관 & 담당자
                  </Button>
                </div>
                <Button
                  color='primary'
                  style={{ boxShadow: "0 0 3px rgba(60, 72, 88, 0.5)" }}
                  onClick={(e) => setModalOpen(true)}
                  size='sm'
                >
                  새 계약
                </Button>
                {/* <Button
                color='secondary'
                href='#pablo'
                onClick={(e) => e.preventDefault()}
                size='sm'>
                수정
              </Button>
              <Button
                color='secondary'
                href='#pablo'
                onClick={(e) => e.preventDefault()}
                size='sm'>
                저장
              </Button> */}
              </div>

              <OrderSearch
                setSearchData={setSearchData}
                searchData={searchData}
                handleSearch={(data) => setSearchType(data)}
                handlePageIndex={(data) => setPageIndex(data)}
                searchType={searchType}
                pageIndex={pageIndex}
              />
              <Row className='mt-4 '>
                <Col className=' mb-xl-0'>
                  <OrderTable
                    orderDataList={tableData}
                    searchData={searchData}
                    setcheckBoxList={setcheckBoxList}
                    checkBoxList={checkBoxList}
                    handleDelete={handleDelete}
                    isUpdate={isUpdate}
                    pageIndex={pageIndex}
                    searchType={searchType}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </Container>
      <OrderModal
        modalOpen={modalOpen}
        toggleMadal={toggleMadal}
        onSumbit={onSumbit}
      />

      <ModelModal
        modalOpen={modelModal}
        toggleMadal={() => setModelModal(false)}
        onSumbit={onSumbit}
      />

      <InstitutionModal
        modalOpen={institutionModal}
        toggleMadal={() => setInstitutionModal(false)}
        onSumbit={onSumbit}
      />
    </>
  );
};

export default OrderPage;
