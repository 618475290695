import React from "react";
import { Table } from "reactstrap";

const ProductInfoTable = ({ productList }) => {
  return (
    <>
      <div className='order-info'>
        <Table>
          <thead className=''>
            <tr>
              <th scope='col' className='pdf-table-header'>
                품목
              </th>
              <th scope='col' className='pdf-table-header'>
                목표수량
              </th>
              <th scope='col' className='pdf-table-header'>
                생산수량
              </th>
              <th scope='col' className='pdf-table-header'>
                생산율
              </th>
              <th scope='col' className='pdf-table-header'>
                불량수량
              </th>
              <th scope='col' className='pdf-table-header'>
                불량률
              </th>
              <th scope='col' className='pdf-table-header'>
                납기율
              </th>
            </tr>
          </thead>
          <tbody>
            {productList.map((item, index) => {
              const { name, targetQuantity, produceQuantity, errorQuantity } =
                item;

              const producePercent =
                targetQuantity === 0
                  ? (0).toFixed(2)
                  : ((produceQuantity / targetQuantity) * 100).toFixed(2);
              const errorPercent =
                produceQuantity === 0
                  ? (0).toFixed(2)
                  : ((errorQuantity / produceQuantity) * 100).toFixed(2);

              const total = (
                ((produceQuantity - errorQuantity) / targetQuantity) *
                100
              ).toFixed(2);

              return (
                <tr key={index}>
                  <td className='pdf-table-body'>{name}</td>
                  <td className='pdf-table-body'>{targetQuantity}</td>
                  <td className='pdf-table-body'>{produceQuantity}</td>
                  <td className='pdf-table-body'>{producePercent} %</td>
                  <td className='pdf-table-body'>{errorQuantity}</td>
                  <td className='pdf-table-body'>{errorPercent} %</td>
                  <td className='pdf-table-body'>{100} %</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default ProductInfoTable;
