/** @format */

import React from "react";
import orderApi from "services/order";
import { useDispatch } from "react-redux";
import { updateOrder } from "store/actions/order";
import { useEffect } from "react";
import { statusData } from "pages/js/orderData";
import _ from "lodash";

const stepList = statusData.map((item) => item.name);

const useNextStep = ({ isuseNext, order, handleSubmit, handleRequestEnd }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isuseNext) {
      const { clientStatus, status, dueDate } = order;

      if (clientStatus !== status) return;

      const { completedStatus } = order;

      const newCompletedStatus = { ...completedStatus };

      if (!newCompletedStatus[stepList[order.status]]) {
        newCompletedStatus[stepList[order.status]] = new Date().getTime();
      }

      // 납품시간
      if (status === 5) {
        newCompletedStatus["complete"] = new Date().getTime();
      }

      orderApi
        .requestUpdateOrder({
          ...order,
          status: order.status + 1,
          completedStatus: newCompletedStatus,
        })
        .then((res) => {
          handleSubmit(true);
          dispatch(
            updateOrder({
              ...order,
              status: order.status + 1,
              clientStatus: order.clientStatus + 1,
              completedStatus: newCompletedStatus,
            })
          );
          console.log(res);
          handleRequestEnd();
        })
        .catch((err) => {
          console.log(err);
          handleSubmit(false);
        });
    }
    // eslint-disable-next-line
  }, [isuseNext]);

  return <></>;
};

export default useNextStep;
