/** @format */

// - 계약 건명
// - 품명
// - 수요기관
// - 계약 번호
// - 납기

import React, { useCallback, useRef } from "react";
import {
  Card,
  CardBody,
  Input,
  Row,
  Form,
  FormGroup,
  Label,
  FormFeedback,
  Col,
  Alert,
  Button,
} from "reactstrap";
import ReactDatetime from "react-datetime";

import { useState } from "react";
import { useEffect } from "react";
import { currentDate } from "pages/js/operator";
import orderApi from "services/order";
import { useDispatch, useSelector } from "react-redux";
import { updateOrder } from "store/actions/order";
import moment from "moment";
import _, { isEmpty } from "lodash";
import ProductListTable from "./ProductListTable";
import ModelAPI from "services/model";

const DefaultInfoTab = ({
  order,
  isNext,
  handleSubmit,
  setIsNext,
  productList,
  userActive,
  setUserActive,
  userRequest,
  handleRequestEnd,

  handleConnectSerial,
  isSerialConnected,
  handleup,
  sevenPinValue,
}) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [errMessage, setErrMessage] = useState(false);

  useEffect(() => {
    console.log(order);
    setFormData({
      ...order,
      startDate: new Date().getTime(),
    });
  }, [order]);

  const inputEmpty = () => {
    const {
      projectNumber,
      institution,
      dueDate,
      startDate,
      orderNumber,
      registerNumber,
      manager,
    } = formData;

    if (projectNumber === undefined) return true;

    if (
      !projectNumber ||
      !institution ||
      !dueDate ||
      !startDate ||
      !orderNumber ||
      !registerNumber ||
      !manager
    ) {
      setErrMessage("입력폼을 완성해주세요.");
      handleRequestEnd();
      setIsNext(false);
      return true;
    }

    if (institution.length > 20 || projectNumber.length > 20) {
      setErrMessage("계약번호와 수요기관의 최대글자는 20자입니다.");
      handleRequestEnd();
      setIsNext(false);
      return true;
    }

    if (orderNumber.length > 15 || registerNumber.length > 15) {
      setErrMessage("발주번호와 등록번호의 최대글자는 15자입니다.");
      handleRequestEnd();
      setIsNext(false);
      return true;
    }

    if (manager.length > 10) {
      setErrMessage("담당자의 최대글자는 10자입니다.");
      handleRequestEnd();
      setIsNext(false);
      return true;
    }

    return false;
  };

  // 수정
  useEffect(() => {
    if (userRequest) {
      if (inputEmpty()) return;

      if (errMessage) {
        setErrMessage(false);
      }

      const { completedStatus } = order;
      const newCompletedStatus = { ...completedStatus };

      if (newCompletedStatus["default"].length === 0) {
        newCompletedStatus["default"] = new Date().getTime();
      }

      // 정보수정
      orderApi
        .requestUpdateOrder({
          ...formData,
          completedStatus: newCompletedStatus,
        })
        .then((res) => {
          // handleSubmit(true);
          dispatch(
            updateOrder({
              ...formData,
              completedStatus: newCompletedStatus,
            })
          );

          if (userRequest.name === "card") {
            handleRequestEnd();
          }
        })
        .catch((err) => {
          console.log(err);
          handleSubmit(false);
        });
    }
  }, [userRequest]);

  useEffect(() => {
    if (isNext) {
      if (inputEmpty()) return;

      if (errMessage) {
        setErrMessage(false);
      }

      const { clientStatus, status } = order;

      if (clientStatus !== status) return;

      const { completedStatus } = order;

      const newCompletedStatus = { ...completedStatus };

      newCompletedStatus["default"] = new Date().getTime();

      // 정보수정
      orderApi
        .requestUpdateOrder({
          ...formData,
          status: formData.status + 1,
          completedStatus: newCompletedStatus,
        })
        .then((res) => {
          handleRequestEnd();
          dispatch(
            updateOrder({
              ...formData,
              status: formData.status + 1,
              clientStatus: formData.clientStatus + 1,
              completedStatus: newCompletedStatus,
            })
          );
        })

        .catch((err) => {
          console.log(err);
          handleRequestEnd();
        });
    }
    // eslint-disable-next-line
  }, [isNext]);

  const [errForm, setErrForm] = useState({
    projectName: false,
    institution: false,
    projectNumber: false,
    orderNumber: false,
    registerNumber: false,
    orderDate: false,
    dueDate: false,
    status: false,
    manager: false,
    startDate: false,
    endDate: false,
    completedStatus: false,
  });

  // 유효성 검사
  const handleErrorForm = (index, value) => {
    const error = {
      ...errForm,
    };

    switch (index) {
      case "projectName":
        if (value.length === 0) {
          error.projectName = "계약건명을 입력하세요.";
        } else if (value.length > 20) {
          error.projectName = "계약건명은 최대 20자입니다.";
        } else {
          error.projectName = false;
        }
        break;
      case "projectNumber":
        if (value.length === 0) {
          error.projectNumber = "계약번호을 입력하세요.";
        } else if (value.length > 20) {
          error.projectNumber = "계약번호는 최대 20자입니다.";
        } else {
          error.projectNumber = false;
        }
        break;
      case "institution":
        if (value.length === 0) {
          error.institution = "수요기관을 입력하세요.";
        } else if (value.length > 20) {
          error.institution = "수요기관는 최대 20자입니다.";
        } else {
          error.institution = false;
        }
        break;
      case "orderNumber":
        if (value.length === 0) {
          error.orderNumber = "발주번호을 입력하세요.";
        } else if (value.length > 15) {
          error.orderNumber = "발주번호는 최대 15자입니다.";
        } else {
          error.orderNumber = false;
        }
        break;
      case "registerNumber":
        if (value.length === 0) {
          error.registerNumber = "등록번호을 입력하세요.";
        } else if (value.length > 15) {
          error.registerNumber = "등록번호는 최대 15자입니다.";
        } else {
          error.registerNumber = false;
        }
        break;
      case "manager":
        if (value.length === 0) {
          error.manager = "담당자를 입력하세요.";
        } else if (value.length > 10) {
          error.manager = "담당자는 최대 10자입니다.";
        } else {
          error.manager = false;
        }
        break;

      default:
        break;
    }

    setErrForm({
      ...error,
    });
  };

  ///
  const [modelList, setModelList] = useState([]);
  const [targetModel, setTargetModel] = useState("");

  useEffect(() => {
    ModelAPI.requestReadModelList().then((res) => {
      setModelList(res);
    });
  }, []);

  return (
    <React.Fragment>
      <Card className='mb-5'>
        <div className='d-flex justify-content-center'>
          <Alert
            toggle={() => setErrMessage(false)}
            isOpen={errMessage ? true : false}
            color='warning'
            style={{ position: "absolute" }}
          >
            <span>{errMessage}</span>
          </Alert>
        </div>
        <CardBody className='py-5'>
          <div className='default-container'>
            <Form inline className='d-flex justify-content-center'>
              <div>
                <Row className=''>
                  {/* 계약번호 */}
                  <Col sm={12} md={6} className=''>
                    <FormGroup
                      className={`${
                        errForm.projectNumber ? "has-danger" : ""
                      } `}
                    >
                      <Label for='projectNumber' className='mr-sm-2'>
                        계약번호 :
                      </Label>

                      <Input
                        type='text'
                        name='projectNumber'
                        id='projectNumber'
                        placeholder='계약번호'
                        className='default'
                        value={
                          _.isNull(formData?.projectNumber) ||
                          _.isUndefined(formData?.projectNumber)
                            ? ""
                            : formData?.projectNumber
                        }
                        onChange={(e) => {
                          setUserActive(true);
                          setFormData({
                            ...formData,
                            projectNumber: e.target.value,
                          });
                          handleErrorForm("projectNumber", e.target.value);
                        }}
                      />
                    </FormGroup>
                    <FormFeedback
                      style={{
                        display: `${errForm.projectNumber ? "block" : "none"}`,
                        position: `${
                          errForm.projectNumber ? "absolute" : "relative"
                        }`,
                      }}
                    >
                      {errForm.projectNumber}
                    </FormFeedback>
                  </Col>

                  {/* 수요기관 */}
                  <Col sm={12} md={6}>
                    <FormGroup
                      className={`${errForm.institution ? "has-danger" : ""} `}
                    >
                      <Label for='institution' className='mr-sm-2'>
                        수요기관 :
                      </Label>

                      <Input
                        type='text'
                        name='institution'
                        id='institution'
                        placeholder='수요기관'
                        className='default'
                        value={
                          _.isNull(formData?.institution) ||
                          _.isUndefined(formData?.institution)
                            ? ""
                            : formData?.institution
                        }
                        onChange={(e) => {
                          setUserActive(true);
                          setFormData({
                            ...formData,
                            institution: e.target.value,
                          });
                          handleErrorForm("institution", e.target.value);
                        }}
                      />
                    </FormGroup>
                    <FormFeedback
                      style={{
                        display: `${errForm.institution ? "block" : "none"}`,
                        position: `${
                          errForm.institution ? "absolute" : "relative"
                        }`,
                      }}
                    >
                      {errForm.institution}
                    </FormFeedback>
                  </Col>
                </Row>

                <Row className='tab-card-body d-flex justify-content-between mt-4'>
                  <Col sm={12} md={6} className=''>
                    <FormGroup className=''>
                      <Label for='dueDate' className='mr-sm-2'>
                        납기일 :
                      </Label>
                      <ReactDatetime
                        inputProps={{
                          placeholder: "납기일",
                          readOnly: true,
                        }}
                        className='default'
                        closeOnSelect={true}
                        dateFormat={"YYYY-MM-DD"}
                        initialValue={
                          _.isNull(order?.dueDate) ||
                          _.isUndefined(order?.dueDate)
                            ? moment(new Date())
                            : moment(new Date(order.dueDate))
                        }
                        timeFormat={false}
                        onChange={(e) => {
                          setUserActive(true);
                          setFormData({
                            ...formData,
                            dueDate: e._d.getTime(),
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6} className=''>
                    <FormGroup
                      className={`${errForm.manager ? "has-danger" : ""} `}
                    >
                      <Label for='manager' className='mr-sm-2'>
                        담당자 :
                      </Label>

                      <Input
                        type='text'
                        name='manager'
                        id='manager'
                        placeholder='담당자'
                        className='default'
                        value={
                          _.isNull(formData?.manager) ||
                          _.isUndefined(formData?.manager)
                            ? ""
                            : formData?.manager
                        }
                        onChange={(e) => {
                          setUserActive(true);
                          setFormData({
                            ...formData,
                            manager: e.target.value,
                          });
                          handleErrorForm("manager", e.target.value);
                        }}
                      />
                    </FormGroup>
                    <FormFeedback
                      style={{
                        display: `${errForm.manager ? "block" : "none"}`,
                        position: `${
                          errForm.manager ? "absolute" : "relative"
                        }`,
                      }}
                    >
                      {errForm.manager}
                    </FormFeedback>
                  </Col>
                </Row>

                {/* 발주번호, 등록번호 */}
                <Row className='tab-card-body d-flex justify-content-between mt-4'>
                  {/* 발주번호 */}
                  <Col sm={12} md={6} className=''>
                    <FormGroup
                      className={`${errForm.orderNumber ? "has-danger" : ""} `}
                    >
                      <Label for='orderNumber' className='mr-sm-2'>
                        발주번호 :
                      </Label>

                      <Input
                        type='text'
                        name='orderNumber'
                        id='orderNumber'
                        placeholder='발주번호'
                        className='default'
                        value={
                          _.isNull(formData?.orderNumber) ||
                          _.isUndefined(formData?.orderNumber)
                            ? ""
                            : formData?.orderNumber
                        }
                        onChange={(e) => {
                          setUserActive(true);
                          setFormData({
                            ...formData,
                            orderNumber: e.target.value,
                          });
                          handleErrorForm("orderNumber", e.target.value);
                        }}
                      />
                    </FormGroup>
                    <FormFeedback
                      style={{
                        display: `${errForm.orderNumber ? "block" : "none"}`,
                        position: `${
                          errForm.orderNumber ? "absolute" : "relative"
                        }`,
                      }}
                    >
                      {errForm.orderNumber}
                    </FormFeedback>
                  </Col>

                  {/* 등록번호 */}
                  <Col sm={12} md={6} className=''>
                    <FormGroup
                      className={`${
                        errForm.registerNumber ? "has-danger" : ""
                      } `}
                    >
                      <Label for='registerNumber' className='mr-sm-2'>
                        등록번호 :
                      </Label>

                      <Input
                        type='text'
                        name='registerNumber'
                        id='registerNumber'
                        placeholder='등록번호'
                        className='default'
                        value={
                          _.isNull(formData?.registerNumber) ||
                          _.isUndefined(formData?.registerNumber)
                            ? ""
                            : formData?.registerNumber
                        }
                        onChange={(e) => {
                          setUserActive(true);
                          setFormData({
                            ...formData,
                            registerNumber: e.target.value,
                          });
                          handleErrorForm("registerNumber", e.target.value);
                        }}
                      />
                    </FormGroup>
                    <FormFeedback
                      style={{
                        display: `${errForm.registerNumber ? "block" : "none"}`,
                        position: `${
                          errForm.registerNumber ? "absolute" : "relative"
                        }`,
                      }}
                    >
                      {errForm.registerNumber}
                    </FormFeedback>
                  </Col>
                </Row>

                {/* 담당자, 설계 시작일 */}
                <hr />

                <Row className='tab-card-body d-flex justify-content-between mt-4'>
                  <Col sm={12} md={6} className=''>
                    <FormGroup
                      className={`${
                        errForm.registerNumber ? "has-danger" : ""
                      } `}
                    >
                      <div className='ml-auto mr-0 mb-3'>
                        <Button
                          color='primary'
                          size='sm'
                          onClick={(e) => {
                            handleConnectSerial();
                          }}
                          disabled={isSerialConnected}
                        >
                          시리얼 연결
                        </Button>
                      </div>
                      <div className=' w-100'>
                        <Label for='registerNumber' className='mr-sm-2 mb-2'>
                          시리얼 이름(예시) :
                        </Label>
                        <Input
                          type='text'
                          name='registerNumber'
                          id=''
                          placeholder='Numato Lab 8 Channel USB GPIO Module'
                          className='model-input  m-0 '
                          style={{}}
                          disabled
                        ></Input>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6} className=''>
                    <FormGroup
                      className={`${
                        errForm.registerNumber ? "has-danger" : ""
                      } `}
                    >
                      <div className='d-flex justify-content-between mb-3 w-100'>
                        <span>
                          {sevenPinValue === 0 && isSerialConnected ? (
                            <span
                              className='text-sm'
                              style={{ color: "#525f7f" }}
                            >
                              <i className='fas fa-bell mr-2'></i>
                              가동중
                            </span>
                          ) : (
                            <span className='text-muted text-sm'>
                              <i className='fas fa-bell-slash mr-2'></i>
                              미가동중
                            </span>
                          )}
                        </span>
                        <Button
                          color='primary'
                          size='sm'
                          onClick={(e) => {
                            e.preventDefault();
                            handleup(targetModel);
                          }}
                          disabled={
                            !isSerialConnected ||
                            !targetModel ||
                            sevenPinValue === 1
                          }
                        >
                          출력
                        </Button>
                      </div>
                      <div className=' w-100'>
                        <Label for='registerNumber' className='mr-sm-2 mb-2'>
                          모델 선택 :
                        </Label>
                        <Input
                          type='select'
                          name='registerNumber'
                          id=''
                          placeholder=''
                          className='model-input  m-0 '
                          style={{
                            display: "block",
                          }}
                          value={targetModel}
                          disabled={!isSerialConnected}
                          onChange={(e) => {
                            setTargetModel(e.target.value);
                          }}
                        >
                          <option value=''>선택</option>
                          {modelList.map((item, index) => {
                            const { id, name } = item;
                            return (
                              <option key={id} value={index}>
                                {index}. {name}
                              </option>
                            );
                          })}
                        </Input>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <hr className='hr' />
              </div>
            </Form>
            <ProductListTable productList={productList} />
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default DefaultInfoTab;
