/**
 * /*!
 *
 * =========================================================
 * Argon Dashboard React - v1.2.1
 * =========================================================
 *
 * Product Page: https://www.creative-tim.com/product/argon-dashboard-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)
 *
 * Coded by Creative Tim
 *
 * =========================================================
 *
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 *
 * @format
 */

import Index from "views/Index.js";
import Profile from "views/examples/Profile.js";
import Maps from "views/examples/Maps.js";
import Register from "views/examples/Register.js";
import Login from "views/examples/Login.js";
import Tables from "views/examples/Tables.js";
import Icons from "views/examples/Icons.js";

// myPage
import OrderPage from "pages/Order/OrderPage";
import DetailPage from "pages/Detail/DetailPage";

// auth
import RegisterPage from "pages/user/RegisterPage";
import LoginPage from "pages/user/LoginPage";
import EmailAuthPage from "pages/user/EmailAuthPage";
import PasswordPage from "pages/user/PasswordPage";
import ProfilePage from "pages/user/ProfilePage";

var routes = [
  {
    path: "/",
    name: "주문 등록",
    icon: "ni ni-tv-2 text-primary",
    component: OrderPage,
    layout: "/admin",
  },
  {
    path: "/detail",
    name: "Detail",
    icon: "ni ni-tv-2 text-primary",
    component: DetailPage,
    layout: "/admin",
  },
  // {
  //   path: "/index",
  //   name: "Dashboard",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: Index,
  //   layout: "/admin",
  // },
  // {
  //   path: "/register",
  //   name: "Resister",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: RegisterPage,
  //   layout: "/admin",
  // },
  // {
  //   path: "/login",
  //   name: "Login",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: LoginPage,
  //   layout: "/admin",
  // },
  // {
  //   path: "/email",
  //   name: "EmailAuthPage",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: EmailAuthPage,
  //   layout: "/admin",
  // },
  // {
  //   path: "/password",
  //   name: "PasswordPage",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: PasswordPage,
  //   layout: "/admin",
  // },
  // {
  //   path: "/profile",
  //   name: "ProfilePage",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: ProfilePage,
  //   layout: "/admin",
  // },
  /////////////////////////////////////
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "ni ni-planet text-blue",
  //   component: Icons,
  //   layout: "/admin",
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "ni ni-pin-3 text-orange",
  //   component: Maps,
  //   layout: "/admin",
  // },
  // {
  //   path: "/user-profile",
  //   name: "User Profile",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: Profile,
  //   layout: "/admin",
  // },
  // {
  //   path: "/tables",
  //   name: "Tables",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: Tables,
  //   layout: "/admin",
  // },
  // {
  //   path: "/login",
  //   name: "Login",
  //   icon: "ni ni-key-25 text-info",
  //   component: Login,
  //   layout: "/auth",
  // },
  // {
  //   path: "/register",
  //   name: "Register",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: Register,
  //   layout: "/auth",
  // },
];
export default routes;
