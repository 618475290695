/** @format */

import axios from "axios";

class InstitutionAPI {
  constructor() {
    this.URL = "";
    this.get = async (url, config) => {
      const res = await axios.get(url, { ...config });
      return res.data;
    };
    this.post = async (url, data, config = {}) => {
      const res = await axios.post(url, data, { ...config });
      console.log(config);
      return res.data;
    };
    this.put = async (url, data, config = {}) => {
      const res = await axios.put(url, data, { ...config });
      return res.data;
    };
    this.delete = async (url, config) => {
      const res = await axios.delete(url, { ...config });
      return res.data;
    };
  }

  //  list 가져오기
  async requestRead(config) {
    const url = "/api/main/institution";
    return this.get(url, config);
  }

  //  만들기
  async requestCreate(data) {
    const url = "/api/main/institution";
    return this.post(url, data);
  }

  //  삭제
  async requestDelete(data) {
    const url = "/api/main/institution";
    return this.delete(url, { data });
  }

  //  수정
  async requestUpdate(data) {
    const url = "/api/main/institution";
    return this.put(url, data);
  }
}

export default new InstitutionAPI();
