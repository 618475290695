import React, { useState, useEffect } from "react";

import { DatePicker, TimePicker } from "antd";
import moment from "moment";
import { Input } from "reactstrap";
import { currentDate } from "pages/js/operator";
import orderApi from "services/order";
import { useDispatch } from "react-redux";
import { setCompletedStaus } from "store/actions/order";
import _ from "lodash";

const titles = {
  design: "설계 완료일",
  product_order: "발주 완료일",
  product_purchase: "입고 완료일",
  assemble: "제작(조립) 완료일",
  confirm: "검사(성능검사) 완료일",
  drive: "시운전(무부하운전) 완료일",
  complete: "납품 완료일",
};

const TimeUpdateComponent = ({ dateTime, title, order }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const [isEdit, setIsEdit] = useState(false);

  const [date, setDate] = useState(new Date().getTime());
  const [time, setTime] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    setDate(dateTime);
    setTime(dateTime);
  }, [dateTime]);

  const handleSubmit = async () => {
    const { completedStatus } = _.cloneDeep(order);

    if (title === "design") {
      completedStatus.design = date;
    } else if (title === "product_order") {
      completedStatus.product_order = date;
    } else if (title === "product_purchase") {
      completedStatus.product_purchase = date;
    } else if (title === "assemble") {
      completedStatus.assemble.three = date;
    } else if (title === "confirm") {
      completedStatus.confirm.six = date;
    } else if (title === "drive") {
      completedStatus.drive.two = date;
    } else if (title === "complete") {
      const myDate = new Date(date);
      const myTime = new Date(time);

      const year = myDate.getFullYear();
      const month = ("0" + (myDate.getMonth() + 1)).slice(-2);
      const datetime = ("0" + myDate.getDate()).slice(-2);

      const hours = ("0" + myTime.getHours()).slice(-2);
      const minutes = ("0" + myTime.getMinutes()).slice(-2);

      let newDate = `${year}/${month}/${datetime} ${hours}:${minutes}:00`;

      newDate = new Date(newDate).getTime();

      completedStatus.complete = newDate;
    }

    try {
      await orderApi.requestCompletedStaus(
        { completedStatus },
        {
          params: {
            orderID: id,
          },
        }
      );
      dispatch(setCompletedStaus({ id: order.id, completedStatus }));
    } catch (err) {
      setDate(dateTime);
      console.log(err);
    }

    setIsEdit((pre) => !pre);
  };

  return (
    <>
      <div className={`${title}`}>
        <label className='form-control-label d-flex justify-content-between'>
          {titles[title]}
          {isEdit ? (
            <span>
              <i
                className='fas fa-save mr-3'
                onClick={() => handleSubmit()}></i>
              <i
                className='fas fa-window-close '
                onClick={() => {
                  setIsEdit((pre) => !pre);
                  setDate(dateTime);
                }}></i>
            </span>
          ) : (
            <i
              className='fas fa-pen ml-3'
              onClick={() => setIsEdit((pre) => !pre)}></i>
          )}
        </label>
        {isEdit ? (
          <div className='d-flex'>
            <DatePicker
              inputReadOnly={true}
              value={moment(date)}
              onChange={(e) => {
                console.log(e);
                setDate(new Date(e._d).getTime());
              }}
            />
            {title === "complete" && (
              <TimePicker
                className='ml-3'
                inputReadOnly={true}
                format={"HH:mm"}
                value={moment(time)}
                onSelect={(value, dateString) => {
                  setTime(value._d.getTime());
                  // setUpdateForm({
                  //   ...updateForm,
                  //   five: {
                  //     ...updateForm.five,
                  //     time: value._d.getTime(),
                  //   },
                  // });
                }}
              />
            )}
          </div>
        ) : (
          <Input value={currentDate(date)} readOnly />
        )}
      </div>
    </>
  );
};
export default TimeUpdateComponent;
