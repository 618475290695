/** @format */

import Header from "components/Headers/Header.js";

import React, { useRef } from "react";

import { Container, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import StatusCard from "./StatusCard";
import StepTabIndex from "./StepTab/StepTabIndex";
import { useEffect } from "react";
import orderApi from "services/order";
import { useHistory } from "react-router-dom";
import { useState } from "react";

import _, { isEmpty } from "lodash";
import { setIsLoading } from "store/actions/user";
import { message } from "antd";
import ModelAPI from "services/model";

let isMount = true;

class TimeoutError extends Error {
  constructor(message) {
    super(message);
    this.name = "TimeoutError";
  }
}

function readWithTimeout(reader, timeout) {
  return Promise.race([
    reader.read(),
    new Promise((_, reject) =>
      setTimeout(() => reject(new TimeoutError("Operation timed out")), timeout)
    ),
  ]);
}

const DetailPageSub = ({ messageApi }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");

  const { order, isLogin } = useSelector((state) => ({
    order: state.order.orderList.filter((item) => item.id === Number(id))[0],
    isLogin: state.user.isLogin,
  }));

  const [productList, setProductList] = useState([]);

  useEffect(() => {
    if (isLogin) {
      orderApi
        .requestReadOrderList()
        .then(async (data) => {
          let isExist = false;
          for (let item of data) {
            if (item.id === Number(id)) {
              isExist = true;
              break;
            }
          }
          if (!isExist) {
            history.push("/");
          } else {
            const resData = await orderApi.requestReadProductList({
              params: {
                orderID: id,
              },
            });
            setProductList(resData);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [isLogin]);

  const { storeLoading } = useSelector((state) => ({
    storeLoading: state.user.isLoading,
  }));

  const [modelList, setModelList] = useState([]);

  useEffect(() => {
    ModelAPI.requestReadModelList().then((res) => {
      setModelList(res);
    });
  }, []);

  const [isSerialConnected, setIsSerialConnected] = useState(false);
  const keepReading = useRef();
  const reader = useRef();

  const sevenPinRead = useRef();
  const [sevenPinValue, setSevenPinValue] = useState(0);

  const writer = useRef();
  const serialPort = useRef();
  const weldingBeforeData = useRef(-1);
  const [weldingTime, setWeldingTime] = useState(0);

  const weldingStart = useRef(0);
  const [weldingStartState, setWeldingStartState] = useState(false);

  const [isReceive, setIsReceive] = useState(false);

  useEffect(() => {
    const product = productList[0];
    if (product) {
      if (typeof product.weldingTime === "number") {
        setWeldingTime(product.weldingTime.toFixed(1));
      }
    }
  }, [productList]);

  const checkBrowser = () => {
    const userAgent = navigator.userAgent;

    if (userAgent.indexOf("Chrome") > -1 || userAgent.indexOf("Edge") > -1) {
      return true;
    }
    return false;
  };
  const handleConnectSerial = async () => {
    const isBrowser = checkBrowser();

    if (!isBrowser) {
      messageApi.info({
        content: "크롬 또는 Edge 브라우저에서만 사용이 가능합니다.",
        maxCount: 1,
      });
      return;
    }

    try {
      const port = await navigator.serial.requestPort();
      console.log(port);

      const info = port.getInfo();
      console.log(info);

      if (isEmpty(info)) {
        messageApi.warning({
          content: "지원하지 않는 모듈입니다.",
          maxCount: 1,
        });
        return;
      } else {
        const { usbProductId, usbVendorId } = info;
        if (usbProductId !== 2048 || usbVendorId !== 10777) {
          messageApi.warning({
            content: "지원하지 않는 모듈입니다.",
            maxCount: 1,
          });
          return;
        }
      }

      try {
        await port.open({ baudRate: 9600, flowControl: "none" });
        messageApi.success({
          content: "연결되었습니다.",
          maxCount: 1,
        });
      } catch (e) {
        messageApi.info({
          content: "다른 사용자가 사용중입니다.",
          maxCount: 1,
        });
        return;
      }
      serialPort.current = port;
      keepReading.current = true;

      const textDecoder = new TextDecoderStream();
      const readableStreamClosed = port.readable.pipeTo(textDecoder.writable);

      writer.current = port.writable.getWriter();
      const text = `gpio iodir C0\r`;
      const encoder = new TextEncoder();
      const data = encoder.encode(text);
      await writer.current.write(data);

      sevenPinRead.current = setInterval(async () => {
        try {
          const text = `gpio read 7\r`;
          const encoder = new TextEncoder();
          const data = encoder.encode(text);
          await writer.current.write(data);
        } catch (e) {
          //
        }
      }, 5000);

      // 직렬 장치로부터 오는 데이터를 듣는다.
      setIsSerialConnected(true);

      while (keepReading.current) {
        try {
          // 데이터 스트림을 읽기 위한 리더 객체 생성
          // const reader = port.readable.getReader();
          reader.current = textDecoder.readable.getReader();

          try {
            // 데이터를 계속 읽음
            while (true) {
              const { value, done } = await readWithTimeout(
                reader.current,
                10000
              );
              console.log(value);
              if (done) {
                // 스트림이 종료되었다면, 루프를 빠져나감
                break;
              }

              if (!storeLoading && !done) {
                if (value.includes("readall")) {
                  let data = value.replaceAll("\n", "").split("\r");
                  data = parseInt(data[1], 16);
                  data = Number(data);
                  if (data >= 128) {
                    data -= 128;
                  }

                  if (data >= 64) {
                    data -= 64;
                  }

                  const binaryString = data.toString(2);

                  console.log(data);
                  if (data < modelList.length) {
                    console.log(`${modelList[data].name} 출력 성공`);
                    messageApi.info(`${modelList[data].name} 출력 성공`);
                  }
                  dispatch(setIsLoading(false));
                }
              }

              if (!value.includes("readall") && value.includes("read")) {
                const data = value.replaceAll("\n", "").split("\r");
                const decimalNumber = parseInt(data[1], 16);
                if (value.includes("7")) {
                  if (isMount) {
                    setSevenPinValue(Number(decimalNumber));
                  }
                }
              }

              if (!value.includes("readall") && value.includes("read")) {
                const data = value.replaceAll("\n", "").split("\r");

                if (value.includes("6")) {
                  setIsReceive(false);
                  if (value.includes("0")) {
                    if (weldingBeforeData.current === 1) {
                      // 시작
                      setWeldingStartState(true);
                      weldingStart.current = new Date().getTime();
                      weldingBeforeData.current = 0;
                    } else if (weldingBeforeData.current === 0) {
                      const time = new Date().getTime() - weldingStart.current;
                      const timeFormat = (time / 1000).toFixed(1);
                      if (Number(timeFormat) > 20) {
                        weldingBeforeData.current = -1;
                        messageApi.open({
                          key: "msgKey",
                          type: "warning",
                          content:
                            "USB GPIO 모듈을 확인해 주세요. (용접시간은 20초 이상 걸리지 않습니다.)",
                          duration: 0,
                          onClick: () => message.destroy("msgKey"),
                          className: "cursor-pointer",
                        });
                        const product = productList[0];
                        if (product) {
                          if (typeof product.weldingTime === "number") {
                            setWeldingTime(product.weldingTime.toFixed(1));
                          }
                        }
                      } else {
                        setWeldingTime(timeFormat);
                      }
                    }
                  } else if (value.includes("1")) {
                    if (weldingBeforeData.current === -1) {
                      weldingBeforeData.current = 1;
                    } else if (weldingBeforeData.current === 0) {
                      if (weldingStart.current) {
                        const time =
                          new Date().getTime() - weldingStart.current;
                        const timeFormat = (time / 1000).toFixed(1);
                        setWeldingTime(timeFormat);
                        await orderApi.requestUpdateWedingTime({
                          weldingTime: Number(timeFormat),
                          id: productList[0].id,
                        });
                      }
                      weldingBeforeData.current = 1;
                      console.log("끝");
                      setWeldingStartState(false);
                    }
                  }
                }
              }
              // console.log(value);
            }
          } catch (error) {
            if (error instanceof TimeoutError) {
              // 타임아웃 발생 시, 다시 시도하거나, 오류를 로그하고, 연결을 종료할 수 있습니다.
              console.error("Timeout Error: ", error.message);
              // 여기서 필요한 경우 재시도 로직을 구현할 수 있습니다.
            }

            console.error("Read error: " + error);
          } finally {
            // 리더를 해제하여 버퍼를 클리어
            reader.current.releaseLock();
          }
        } catch (error) {
          console.error("Serial reading error: " + error);
          // 오류가 발생한 경우 연결을 해제하거나 재연결 로직을 수행
          break;
        }
      }

      // 스트림 종료
      // if (reader.current) {
      //   reader.current.cancel()
      // }

      await readableStreamClosed.catch(() => {
        /* 에러를 무시한다 */
      });

      if (writer.current) {
        writer.current.releaseLock();
      }

      await port.close();
      if (sevenPinRead.current) {
        clearInterval(sevenPinRead.current);
        sevenPinRead.current = null;
      }
      messageApi.info({
        content: "시리얼 연결이 끊겼습니다.",
        maxCount: 1,
      });
      if (isMount) {
        setIsSerialConnected(false);
        setSevenPinValue(1);
      }
      // setPortList((pre) => [...pre, port]);
    } catch (e) {
      console.log(e);
      if (e.toString().includes("No port selected")) {
        return;
      }
      handleSerialClose(true);
    }
  };

  async function handleSerialClose(alarm) {
    try {
      setIsSerialConnected(false);
      setSevenPinValue(1);
      if (alarm) {
        messageApi.info({
          content: "시리얼 연결이 끊겼습니다.",
          maxCount: 1,
        });
      }
      keepReading.current = false;
      reader.current.cancel();
      if (sevenPinRead.current) {
        clearInterval(sevenPinRead.current);
        sevenPinRead.current = null;
      }
    } catch (e) {}
  }

  useEffect(() => {
    isMount = true;
    return () => {
      isMount = false;
      if (keepReading.current) {
        keepReading.current = false;
        reader.current.cancel();
        if (sevenPinRead.current) {
          clearInterval(sevenPinRead.current);
          sevenPinRead.current = null;
        }
      }
    };
  }, []);

  const handleup = async (targetModel) => {
    // 문자열을 Uint8Array로 변환

    const index = Number(targetModel);

    function decimalTo4DigitHex(decimal) {
      if (decimal >= 0 && decimal <= 65535) {
        // 범위 검사
        var hex = decimal.toString(16); // 10진수를 16진수로 변환
        while (hex.length < 2) {
          hex = "0" + hex; // 4자리로 만들기 위해 앞에 0 채우기
        }
        return hex;
      } else {
        return "범위를 벗어난 값";
      }
    }
    console.log(index);
    console.log(decimalTo4DigitHex(index));
    const text = `gpio writeall ${decimalTo4DigitHex(index)}\r`;

    const encoder = new TextEncoder();
    const data = encoder.encode(text);
    dispatch(setIsLoading(true));
    await writer.current.write(data);

    setTimeout(async () => {
      const text = `gpio readall\r`;

      const encoder = new TextEncoder();
      const data = encoder.encode(text);
      await writer.current.write(data);
    }, [1000]);
  };
  return (
    <>
      <Header />

      <Container className='bottom-container' fluid>
        <div className='d-flex align-items-center text-left'>
          {/* <i className='fas fa-unlink text-danger'></i> */}
          {/* <i className='fas fa-plug text-success'></i> */}
          <h1 className=' p-0 m-0'>
            &nbsp;&nbsp;{order?.projectName}
            <span className='text-muted'>/ {order?.projectNumber}</span>
          </h1>
          <span></span>
        </div>

        <div className=' mx-auto'>
          <StepTabIndex
            order={order}
            productList={productList}
            handleConnectSerial={handleConnectSerial}
            isSerialConnected={isSerialConnected}
            handleup={handleup}
            sevenPinValue={sevenPinValue}
            writer={writer}
            keepReading={keepReading}
            reader={reader}
            messageApi={messageApi}
            weldingTime={weldingTime}
            setIsReceive={setIsReceive}
            isReceive={isReceive}
            setWeldingTime={setWeldingTime}
            weldingStartState={weldingStartState}
          />
        </div>
      </Container>
    </>
  );
};

export default DetailPageSub;
