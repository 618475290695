/** @format */

import { createStore } from "@reduxjs/toolkit";
import reducer from "store/reducers";

const makeStore = () => {
  const store = createStore(reducer);
  return store;
};

export default makeStore();
