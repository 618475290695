import React, { useState, useEffect } from "react";

import { Table, PaginationItem, Pagination, PaginationLink } from "reactstrap";

const ProductListTable = ({ productList }) => {
  const [page, setPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageIndex, setPageIndex] = useState(5);

  useEffect(() => {
    if (productList.length % pageIndex === 0) {
      setPage(parseInt(productList.length / pageIndex));
    } else {
      setPage(parseInt(productList.length / pageIndex + 1));
    }
  }, [productList]);

  return (
    <>
      <div>
        <Table className='product-table border-bottom'>
          <thead className='thead-light'>
            <tr>
              <th className='' scope='col' style={{ width: "10px" }}>
                #
              </th>
              <th cope='col'>품명</th>
              <th cope='col'>수량</th>
            </tr>
          </thead>
          <tbody>
            {productList
              .filter(
                (_, idx) =>
                  idx <= currentPage * pageIndex - 1 &&
                  (currentPage - 1) * pageIndex <= idx
              )
              .map((item, index) => {
                return (
                  <tr key={index}>
                    <td className='text-center'>
                      {" "}
                      {(currentPage - 1) * pageIndex + index + 1}
                    </td>
                    <td className='text-center'>{item.name}</td>
                    <td className='text-center'>{item.targetQuantity}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        <nav
          aria-label='Page navigation example'
          className='mt-4 product-table d-flex justify-content-center'
        >
          <Pagination
            className='pagination justify-content-end'
            listClassName='justify-content-end'
          >
            <PaginationItem className={currentPage === 1 ? "disabled" : ""}>
              <PaginationLink onClick={(e) => e.preventDefault()} tabIndex='-1'>
                <i className='fa fa-angle-left' />
                <span className='sr-only'>Previous</span>
              </PaginationLink>
            </PaginationItem>
            {new Array(
              page % pageIndex === 0
                ? page - 1 === -1
                  ? page
                  : page - 1
                : page
            )
              .fill(undefined)
              .map((_, index) => (
                <PaginationItem
                  key={index}
                  className={currentPage === index + 1 ? "active " : ""}
                  onClick={() => setCurrentPage(index + 1)}
                >
                  <PaginationLink onClick={(e) => e.preventDefault()}>
                    {index + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}
            <PaginationItem className={currentPage === page ? "disabled" : ""}>
              <PaginationLink href='#pablo' onClick={(e) => e.preventDefault()}>
                <i className='fa fa-angle-right' />
                <span className='sr-only'>Next</span>
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </nav>
      </div>
    </>
  );
};

export default ProductListTable;
