/** @format */

import useNextStep from "pages/hooks/useNextStep";
import React from "react";
import {
  Card,
  CardBody,
  PaginationItem,
  Pagination,
  Table,
  InputGroup,
  PaginationLink,
} from "reactstrap";
import { Input } from "reactstrap";
import { useEffect, useState } from "react";
import purchaseAip from "services/purchase";
import _ from "lodash";
import SaveModal from "pages/Detail/components/SaveModal";

// 구매품 입고는 select도 확인해야함에 savemodal을 따로 둠

const PurchaseInTab = ({
  isNext,
  order,
  handleSubmit,
  setIsNext,
  handleRequestEnd,
  setUserActive,
  userRequest,
}) => {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");

  const [isuseNext, setIsuseNext] = useState(false);

  useNextStep({ isuseNext, order, handleSubmit, handleRequestEnd });

  // 셀렉트(원자재, 외주가공)를 위한 수정 확인 모달 나오기
  const [updateModal, setUpdateModal] = useState({
    isChange: false,
    value: false,
    target: 0,
  });

  const [targetTab, setTargetTab] = useState(0);

  const [tableData, setTableData] = useState([]);

  // 페이지 네이션
  const [page, setPage] = useState(0);
  const [currnetPage, setCurrentPage] = useState(1);
  useEffect(() => {
    if (tableData.length % 5) {
      setPage(parseInt(tableData.length / 5 + 1));
    } else {
      setPage(parseInt(tableData.length / 5));
    }
  }, [tableData]);

  // purchase get
  useEffect(() => {
    purchaseAip
      .requestReadPurchase({
        params: {
          orderID: id,
          tab: targetTab,
        },
      })
      .then((res) => {
        console.log(res);
        setTableData(res);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, [targetTab]);

  // 입고량 변경
  const handelTableData = (id, column, data) => {
    let replaceNumber;

    if (column === "memo") {
      replaceNumber = data;
    } else {
      replaceNumber = Number(data.replace(/[^0-9]/g, ""));
      replaceNumber = replaceNumber === 0 ? "" : replaceNumber;
    }

    const newData = _.cloneDeep(tableData);
    newData.forEach((item) => {
      if (item.id === id) {
        item[column] = replaceNumber;
      }
    });

    setTableData(newData);
  };

  // 입고량 완료
  const handleSuccess = (data) => {
    const newData = _.cloneDeep(tableData);
    newData.forEach((item) => {
      if (item.id === data.id) {
        item.currentQuantity = item.orderQuantity;
      }
    });

    setTableData(newData);
    // 저장 요청 보낼 리스트에 추가
    setFocusList([
      ...focusList,
      {
        ...data,
        currentQuantity: data.orderQuantity,
      },
    ]);
    setUpdateModal({
      ...updateModal,
      isChange: true,
    });
    setUserActive(true);
  };

  // 사용자가 수정 액션을 취한 것들 담아두기
  const [focusList, setFocusList] = useState([]);

  // 원자재/외주가공 selector 고르는 이벤트
  const handleSelector = (e) => {
    if (updateModal.isChange) {
      setUpdateModal({
        isChange: false,
        value: true,
        target: e.target.value,
      });
    } else {
      setUpdateModal({
        isChange: false,
        value: false,
        target: e.target.value,
      });
      setTargetTab(e.target.value);
    }
    setPage(0);
    setCurrentPage(1);
  };

  // 수정 모달의 액션
  const handleUpdateModal = async (status) => {
    const { target } = updateModal;

    if (status) {
      console.log("번경");
      try {
        await purchaseAip.requestUpdatePurchase(
          { focusList: [...focusList] },
          {
            params: {
              orderID: id,
            },
          }
        );
      } catch (err) {
        console.log(err);
      }
    } else {
      console.log("유지");
    }

    setTargetTab(target);
    setUpdateModal({
      isChange: false,
      value: false,
      target: target,
    });
  };

  const handleUpdateRequest = async () => {
    setIsNext(false);
    if (_.isEmpty(focusList)) {
      setIsuseNext(true);
      return;
    }

    try {
      await purchaseAip.requestUpdatePurchase(
        { focusList: [...focusList] },
        {
          params: {
            orderID: id,
          },
        }
      );
      setIsuseNext(true);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (isNext) {
      handleUpdateRequest();
    }
  }, [isNext]);

  const handleUpdateRequestSaved = async () => {
    // if (_.isEmpty(focusList)) {
    //   setIsuseNext(true);
    //   return;
    // }
    try {
      await purchaseAip.requestUpdatePurchase(
        { focusList: [...focusList] },
        {
          params: {
            orderID: id,
          },
        }
      );
      // setIsuseNext(true);
      handleRequestEnd();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (userRequest) {
      handleUpdateRequestSaved();
    }
  }, [userRequest]);

  return (
    <>
      <Card className='mb-5' style={{ minHeight: "350px" }}>
        <CardBody>
          <div className='d-flex justify-content-between'>
            <InputGroup className='input-group-alternative search-select mb-3'>
              <Input
                type='select'
                value={targetTab}
                onChange={(e) => {
                  // setTargetTab(e.target.value);
                  handleSelector(e);
                }}
              >
                <option value={0}>원자재 발주</option>
                <option value={1}>외주가공</option>
              </Input>
            </InputGroup>
          </div>
          <Table
            className='align-items-center table-flush border-bottom'
            responsive
          >
            <thead className='thead-light'>
              <tr>
                <th scope='col-1' className='col-1 mobile'>
                  #
                </th>
                <th scope='col-1' className='col-1'>
                  상호명
                </th>
                <th scope='col-1' className='col-1'>
                  품명
                </th>
                <th scope='col-1' className='col-1'>
                  입고량/발주량
                </th>
                <th scope='col-1' className='col-1 mobile'>
                  입고여부
                </th>
                <th scope='col-2' className='col-2 '>
                  비고
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData
                .filter(
                  (_, idx) =>
                    idx <= currnetPage * 5 - 1 && (currnetPage - 1) * 5 <= idx
                )
                .map((item, index) => {
                  return (
                    <tr
                      key={index}
                      onBlur={() => {
                        setUpdateModal({
                          ...updateModal,
                          isChange: true,
                        });
                        setUserActive(true);
                        setFocusList([
                          ...focusList,
                          {
                            ...item,
                          },
                        ]);
                      }}
                    >
                      <th className='mobile'>
                        <span className='purchase-th'>
                          {(currnetPage - 1) * 5 + index + 1}
                        </span>
                      </th>
                      <th>
                        <span className='purchase-th'>{item.company}</span>
                      </th>
                      <th>
                        <span className='purchase-th'>{item.productName}</span>
                      </th>
                      <th>
                        <Input
                          className='input-total input-th'
                          value={item.currentQuantity}
                          onChange={(e) =>
                            handelTableData(
                              item.id,
                              "currentQuantity",
                              e.target.value
                            )
                          }
                          maxLength={8}
                        />
                        &nbsp;&nbsp; / &nbsp;&nbsp;
                        <Input
                          className='input-total'
                          disabled
                          value={item.orderQuantity}
                        />
                      </th>
                      <th className='mobile'>
                        {item.orderQuantity <= item.currentQuantity ? (
                          <div className='purchase-th'>
                            <i className='far fa-check-circle  '></i>
                          </div>
                        ) : (
                          <div className=' purchase-th'>
                            <i
                              className='far fa-times-circle'
                              onClick={() => handleSuccess(item)}
                            ></i>
                          </div>
                        )}
                        {/* <input
                        id={item.projectName}
                        type='checkbox'
                        className='check-box'
                        checked={item.complete}
                      />
                      <label htmlFor={item.projectName}></label> */}
                      </th>
                      <th className='' style={{ minWidth: "300px" }}>
                        <Input
                          className='input-th'
                          value={item.memo}
                          onChange={(e) =>
                            handelTableData(item.id, "memo", e.target.value)
                          }
                          maxLength={255}
                        />
                      </th>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          <nav aria-label='Page navigation example' className='mt-4'>
            <Pagination
              className='pagination justify-content-end'
              listClassName='justify-content-end'
            >
              <PaginationItem className={currnetPage === 1 ? "disabled" : ""}>
                <PaginationLink
                  onClick={(e) => e.preventDefault()}
                  tabIndex='-1'
                >
                  <i className='fa fa-angle-left' />
                  <span className='sr-only'>Previous</span>
                </PaginationLink>
              </PaginationItem>
              {new Array(page).fill(undefined).map((_, index) => (
                <PaginationItem
                  key={index}
                  className={currnetPage === index + 1 ? "active " : ""}
                  onClick={() => setCurrentPage(index + 1)}
                >
                  <PaginationLink onClick={(e) => e.preventDefault()}>
                    {index + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}
              <PaginationItem
                className={currnetPage === page ? "disabled" : ""}
              >
                <PaginationLink onClick={(e) => e.preventDefault()}>
                  <i className='fa fa-angle-right' />
                  <span className='sr-only'>Next</span>
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          </nav>
        </CardBody>
      </Card>
      <SaveModal
        ismodal={updateModal}
        toggleModal={setUpdateModal}
        handleUpdateModal={handleUpdateModal}
      />
    </>
  );
};

export default PurchaseInTab;
