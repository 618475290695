/** @format */

import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import gsap from "gsap";

import OrderForm from "./OrderForm";
import { useState } from "react";

const OrderModal = ({ modalOpen, toggleMadal, onSumbit }) => {
  const [createData, setCreateData] = useState({
    projectName: "",
    user: "",
    modelName: "",
    orderDate: "",
    dueDate: "",
  });

  return (
    <>
      <Modal size='l' isOpen={modalOpen} toggle={toggleMadal}>
        <ModalHeader toggle={toggleMadal} tag='h3'>
          계약 등록
        </ModalHeader>
        <ModalBody>
          <div className='orderform'>
            <OrderForm createData={createData} onSumbit={onSumbit} />
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default OrderModal;
