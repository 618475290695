/** @format */

import { useState, useEffect, useCallback, useRef } from "react";
import useNextStep from "pages/hooks/useNextStep";
import {
  Card,
  CardBody,
  Button,
  Alert,
  UncontrolledPopover,
  PopoverBody,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { currentDatehangle } from "pages/js/operator";
import ProduceTable from "../../components/ProduceTable";
import _ from "lodash";
import { setCompletedStaus } from "store/actions/order";

import orderApi from "services/order";
import { DatePicker, TimePicker } from "antd";
import moment from "moment";
import { useDispatch } from "react-redux";

import { message } from "antd";

// import worker from "assets/worker";

const workerInstance = new Worker(process.env.PUBLIC_URL + "/worker.js");

const assembleValue = "produceQuantity";
const initStep = {
  one: "본딩 & 클리닝",
  two: "조립",
  three: "용접",
};
const initValue = {
  one: {
    isUpdate: false,
    value: "",
    date: "",
    time: "",
  },
  two: {
    isUpdate: false,
    value: "",
    date: "",
    time: "",
  },
  three: {
    isUpdate: false,
    value: "",
    date: "",
    time: "",
  },
};

let startDate = 0;

const AssembleTab = ({
  isNext,
  order,
  handleSubmit,
  handleRequestEnd,
  setUserActive,
  userRequest,
  setStepListConfirm,
  isStepConfirm,
  setIsStepConfirm,
  stepListConfirm,
  handleConnectSerial,
  isSerialConnected,
  handleup,
  sevenPinValue,
  keepReading,
  writer,
  setIsReceive,
  weldingTime,
  isReceive,
  setWeldingTime,
  weldingStartState,
  messageApi,
}) => {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");

  const [isuseNext, setIsuseNext] = useState(false);

  const dispatch = useDispatch();

  const otherOneTouch = useCallback((event) => {
    document.activeElement.blur(); // 현재 활성화된 element의 blur 이벤트 호출
  }, []);

  useNextStep({ isuseNext, order, handleSubmit, handleRequestEnd });

  // 수정 확인 모달 나오기
  const [updateModal, setUpdateModal] = useState({
    isChange: false,
    value: false,
  });

  const [currentStep, setCurrentStep] = useState(0);

  const [updateForm, setUpdateForm] = useState(initValue);
  const [stepList, setStepList] = useState(initValue);

  // 클릭 단계 수정
  const handleClick = async (item, value) => {
    const { completedStatus } = _.cloneDeep(order);
    if (!value) {
      completedStatus.assemble[item] = new Date().getTime();

      otherOneTouch();
    } else {
      completedStatus.assemble[item] = "";
    }

    try {
      await orderApi.requestCompletedStaus(
        { completedStatus },
        {
          params: {
            orderID: id,
          },
        }
      );
      setStepList({
        ...stepList,
        [item]: {
          value: completedStatus.assemble[item],
          isUpdate: false,
        },
      });
      dispatch(setCompletedStaus({ id: order.id, completedStatus }));
      setCurrentStep(currentStep + 1);
    } catch (err) {
      console.log(err);
    }
  };
  // 가져오기
  useEffect(() => {
    const {
      completedStatus: { assemble },
    } = order;
    for (let [index, value] of Object.values(assemble).entries()) {
      if (!value) {
        setCurrentStep(index);
        break;
      }
    }

    const newValue = { ...initValue };

    for (let [key, value] of Object.entries(assemble)) {
      newValue[key] = {
        isUpdate: false,
        value: value,
        date: value,
        time: value,
      };
    }

    setStepList({
      ...newValue,
    });

    setUpdateForm({
      ...newValue,
    });
  }, [order, order.clientStatus]);

  const startUpdate = (index) => {
    setStepList({
      ...stepList,
      [index]: {
        ...stepList[index],
        isUpdate: true,
      },
    });
  };

  const closeUpdate = (index) => {
    setStepList({
      ...stepList,
      [index]: {
        ...stepList[index],
        isUpdate: false,
      },
    });
    setUpdateForm({
      ...updateForm,
      [index]: {
        ...stepList[index],
      },
    });
  };

  const saveUpdate = async (index) => {
    let { date, time } = updateForm[index];

    date = new Date(date);
    time = new Date(time);

    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const datetime = ("0" + date.getDate()).slice(-2);

    const hours = ("0" + time.getHours()).slice(-2);
    const minutes = ("0" + time.getMinutes()).slice(-2);

    let newDate = `${year}/${month}/${datetime} ${hours}:${minutes}:00`;

    newDate = new Date(newDate).getTime();
    const { completedStatus } = _.cloneDeep(order);
    completedStatus.assemble[index] = newDate;

    try {
      await orderApi.requestCompletedStaus(
        { completedStatus },
        {
          params: {
            orderID: id,
          },
        }
      );
      dispatch(setCompletedStaus({ id: order.id, completedStatus }));
    } catch (err) {
      console.log(err);
    }

    setStepList({
      ...stepList,
      [index]: {
        isUpdate: false,
        value: newDate,
        time,
        date,
      },
    });
  };

  //// table
  const [errMessage, setErrMessage] = useState(false);
  const [productOrder, setProductOrder] = useState("asc");
  const [productList, setProductList] = useState([]);

  useEffect(() => {
    if (isStepConfirm) {
      setIsStepConfirm(false);
      setErrMessage(`${stepListConfirm}단계를 완료해주세요.`);
    }
  }, [isStepConfirm]);

  useEffect(() => {
    orderApi
      .requestReadProductList({
        params: {
          orderID: id,
        },
      })
      .then((res) => {
        setProductList([...res]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleChange = (e, item, key) => {
    const newData = _.cloneDeep(productList);
    const number = e.target.value.replace(/[^0-9]/g, "");
    for (let i = 0; i < newData.length; i++) {
      const newItem = newData[i];
      if (newItem.id === item.id) {
        newItem[key] = number === "" ? "" : Number(number);
        break;
      }
    }

    setProductList(newData);
    setUserActive(true);
  };

  useEffect(() => {
    if (order.status !== 4) {
      for (let [key, value] of Object.entries(stepList)) {
        if (!value["date"] || !value["time"] || !value["value"]) {
          // setErrMessage(`${initStep[key]} 단계를 완료해 주세요.`);
          // handleRequestEnd();
          setStepListConfirm(initStep[key]);
          return;
        }
      }
      setStepListConfirm(false);
    }
  }, [stepList]);

  // 수정 요청 보내기 (생산 수량)
  const handleRequestApi = async () => {
    const { one, two, three } = stepList;
    console.log(productList);

    for (let i = 0; i < productList.length; i++) {
      const item = productList[i];
      if (item[assembleValue] === "") {
        setErrMessage(`${item.name}의 수량을 입력해 주세요.`);
        return;
      }
    }

    try {
      await orderApi.requestUpdateProductList(
        { productList },
        {
          params: {
            orderID: id,
          },
        }
      );
    } catch (err) {
      console.log(err);
    }

    setErrMessage(false);

    if (!isNext) {
      handleRequestEnd();
    } else {
      for (let [key, value] of Object.entries(stepList)) {
        if (!value["date"] || !value["time"] || !value["value"]) {
          setErrMessage(`${initStep[key]} 단계를 완료해 주세요.`);
          handleRequestEnd();
          return;
        }
      }
      setIsuseNext(true);
    }
  };

  const orderProduceTableData = () => {
    if (productOrder === "asc") {
      const newData = _.cloneDeep(productList);
      for (let i = 0; i < newData.length; i++) {
        for (let j = 0; j < newData.length; j++) {
          if (newData[i].name < newData[j].name) {
            const temp = {
              ...newData[i],
            };
            newData[i] = newData[j];
            newData[j] = temp;
          }
        }
      }
      setProductList([...newData]);
    } else {
      const newData = _.cloneDeep(productList);
      for (let i = 0; i < newData.length; i++) {
        for (let j = 0; j < newData.length; j++) {
          if (newData[i].name > newData[j].name) {
            const temp = {
              ...newData[i],
            };
            newData[i] = newData[j];
            newData[j] = temp;
          }
        }
      }

      setProductList([...newData]);
    }
  };

  const sevenPin6Read = useRef();
  // const [weldingTime, setWeldingTime] = useState(0);
  const weldingStart = useRef(0);
  // const weldingBeforeData = useRef(1);

  const getSixPin = async () => {
    // while (keepReading.current) {
    //   try {
    //     const { value, done } = await reader.current.read();
    //     console.log(value);
    //     if (done) {
    //       console.log("error 에러남");
    //       // reader.current.releaseLock();
    //       break;
    //     }
    //     if (!value.includes("readall") && value.includes("read")) {
    //       const data = value.replaceAll("\n", "").split("\r");
    //       const decimalNumber = parseInt(data[1], 16);
    //       if (value.includes("6")) {
    //         if (value.includes("0")) {
    //           console.log(weldingBeforeData.current);
    //           if (weldingBeforeData.current === 1) {
    //             weldingStart.current = new Date().getTime();
    //             weldingBeforeData.current = 0;
    //           } else if (weldingBeforeData.current === 0) {
    //             const time = new Date().getTime() - weldingStart.current;
    //             const timeFormat = (time / 1000).toFixed(1);
    //             if (Number(timeFormat) > 20) {
    //               weldingBeforeData.current = -1;
    //               messageApi.open({
    //                 key: "msgKey",
    //                 type: "warning",
    //                 content:
    //                   "USB GPIO 모듈을 확인해 주세요. (용접시간은 20초 이상 걸리지 않습니다.)",
    //                 duration: 0,
    //                 onClick: () => message.destroy("msgKey"),
    //                 className: "cursor-pointer",
    //               });
    //               // window.location.reload();
    //               const product = productList[0];
    //               if (product) {
    //                 if (typeof product.weldingTime === "number") {
    //                   setWeldingTime(product.weldingTime.toFixed(1));
    //                 }
    //               }
    //             } else {
    //               setWeldingTime(timeFormat);
    //             }
    //           }
    //         } else if (value.includes("1")) {
    //           if (weldingBeforeData.current === -1) {
    //             weldingBeforeData.current = 1;
    //           } else if (weldingBeforeData.current === 0) {
    //             if (weldingStart.current) {
    //               const time = new Date().getTime() - weldingStart.current;
    //               const timeFormat = (time / 1000).toFixed(1);
    //               setWeldingTime(timeFormat);
    //               await orderApi.requestUpdateWedingTime({
    //                 weldingTime: Number(timeFormat),
    //                 id: productList[0].id,
    //               });
    //             }
    //             weldingBeforeData.current = 1;
    //             console.log("끝");
    //           }
    //         }
    //       }
    //     }
    //     // value는 문자열이다.
    //     // console.log(value);
    //   } catch (e) {
    //     console.log(e);
    //     break;
    //   }
    // }
  };

  useEffect(() => {
    if (writer.current) {
      getSixPin();

      // sevenPin6Read.current = setInterval(async () => {
      //   try {
      //     const text = `gpio read 6\r`;
      //     const encoder = new TextEncoder();
      //     const data = encoder.encode(text);
      //     startDate = new Date().getTime();
      //     await writer.current.write(data);
      //   } catch (e) {
      //     //
      //   }
      // }, 10);
    }
    return () => {
      if (sevenPin6Read.current) {
        clearInterval(sevenPin6Read.current);
        sevenPin6Read.current = null;
        weldingStart.current = 0;
        workerInstance.postMessage("end");
      }
    };
  }, [writer.current]);

  useEffect(() => {
    // 웹 워커 인스턴스를 생성합니다.

    workerInstance.postMessage("start");

    // // 워커로부터 메시지를 받았을 때의 이벤트 핸들러를 설정합니다.
    workerInstance.onmessage = async function (e) {
      if (e.data === "poling") {
        if (writer.current) {
          try {
            if (!isReceive) {
              setIsReceive(true);

              const text = `gpio read 6\r`;
              const encoder = new TextEncoder();
              const data = encoder.encode(text);
              startDate = new Date().getTime();
              await writer.current.write(data);
            }
          } catch (e) {
            //
          }
        }
      }
    };

    return () => {
      workerInstance.postMessage("end");
    };
  }, []);

  return (
    <>
      <Card className='mb-5'>
        <div className='d-flex justify-content-center'>
          <Alert
            toggle={() => setErrMessage(false)}
            isOpen={errMessage ? true : false}
            color='warning'
            style={{ position: "absolute", zIndex: 1000 }}
          >
            <span>{errMessage}</span>
          </Alert>
        </div>
        <CardBody style={{ minHeight: "350px" }}>
          <div className='assemble-status mt-4'>
            {/* 본딩 & 클리닝 */}
            <Card className='assemble-status-card'>
              <CardBody>
                {stepList?.one.isUpdate ? (
                  <div className='picker-container'>
                    <div className='d-flex justify-content-end mb-3'>
                      <i
                        className='fas fa-save'
                        onClick={() => saveUpdate("one")}
                      ></i>
                      <i
                        className='fas fa-window-close'
                        onClick={() => closeUpdate("one")}
                      ></i>
                    </div>
                    <div className='picker'>
                      <DatePicker
                        inputReadOnly={true}
                        value={moment(updateForm?.one.date)}
                        className='mr-2'
                        onChange={(e) => {
                          if (e) {
                            console.log(e);
                            console.log(e._d);
                            setUpdateForm({
                              ...updateForm,
                              one: {
                                ...updateForm.one,
                                date: e._d.getTime(),
                              },
                            });
                          }
                        }}
                        clearIcon={false}
                      />
                      <TimePicker
                        inputReadOnly={true}
                        format={"HH:mm"}
                        clearIcon={false}
                        value={moment(updateForm?.one.time)}
                        onSelect={(value, dateString) => {
                          setUpdateForm({
                            ...updateForm,
                            one: {
                              ...updateForm.one,
                              time: value._d.getTime(),
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div className='step-conteiner'>
                    <Button
                      // color='primary'
                      style={{
                        backgroundColor: "rgb(80, 150, 255)",
                        color: "white",
                        borderRadius: "15px",
                      }}
                      // disabled={stepList?.one.value ? true : false}
                      id={`one`}
                      onClick={(e) => {
                        if (!stepList?.one.value) {
                          handleClick("one", stepList?.one.value, e);
                        }
                      }}
                      size='lg'
                    >
                      {stepList?.one.value ? (
                        <i className='fas fa-check'></i>
                      ) : (
                        <i className='fas fa-circle'></i>
                      )}
                    </Button>
                    {stepList?.one.value && (
                      <UncontrolledPopover
                        trigger='focus'
                        placement='top'
                        target={`one`}
                      >
                        <PopoverBody>
                          <span>취소하시겠습니까?</span> <br />
                          <div className='d-flex justify-content-end mt-2 confirm-step-btn'>
                            <span
                              className='mr-3'
                              onClick={() =>
                                handleClick("one", stepList?.one.value)
                              }
                            >
                              네
                            </span>
                            <span>아니오</span>
                          </div>
                        </PopoverBody>
                      </UncontrolledPopover>
                    )}
                    <div className='ml-3'>
                      <h3 className='d-flex justify-content-between align-items-center'>
                        본딩 & 클리닝
                        {stepList?.one.value && (
                          <span>
                            <i
                              onClick={() => startUpdate("one")}
                              className='fas fa-edit'
                            ></i>
                          </span>
                        )}
                      </h3>
                      <span>
                        {stepList?.one.value
                          ? currentDatehangle(stepList?.one.value) + " 완료"
                          : ""}
                      </span>
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>

            {/* 조립 */}
            <Card className='assemble-status-card'>
              <CardBody>
                {stepList?.two.isUpdate ? (
                  <div className='picker-container'>
                    <div className='d-flex justify-content-end mb-3'>
                      <i
                        className='fas fa-save'
                        onClick={() => saveUpdate("two")}
                      ></i>
                      <i
                        className='fas fa-window-close'
                        onClick={() => closeUpdate("two")}
                      ></i>
                    </div>
                    <div className='picker'>
                      <DatePicker
                        inputReadOnly={true}
                        value={moment(updateForm?.two.date)}
                        className='mr-2'
                        onChange={(e) => {
                          if (e) {
                            console.log(e._d.getTime());
                            setUpdateForm({
                              ...updateForm,
                              two: {
                                ...updateForm.two,
                                date: e._d.getTime(),
                              },
                            });
                          }
                        }}
                        clearIcon={false}
                      />
                      <TimePicker
                        inputReadOnly={true}
                        format={"HH:mm"}
                        clearIcon={false}
                        value={moment(updateForm?.two.time)}
                        onSelect={(value, dateString) => {
                          setUpdateForm({
                            ...updateForm,
                            two: {
                              ...updateForm.two,
                              time: value._d.getTime(),
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div className='step-conteiner'>
                    <Button
                      // color='primary'
                      style={{
                        backgroundColor: "rgb(80, 150, 255)",
                        color: "white",
                        borderRadius: "15px",
                      }}
                      // disabled={stepList?.two.value ? true : false}
                      id={`two`}
                      onClick={(e) => {
                        if (!stepList?.two.value) {
                          handleClick("two", stepList?.two.value, e);
                        }
                      }}
                      size='lg'
                    >
                      {stepList?.two.value ? (
                        <i className='fas fa-check'></i>
                      ) : (
                        <i className='fas fa-circle'></i>
                      )}
                    </Button>
                    {stepList?.two.value && (
                      <UncontrolledPopover
                        trigger='focus'
                        placement='top'
                        target={`two`}
                      >
                        <PopoverBody>
                          <span>취소하시겠습니까?</span> <br />
                          <div className='d-flex justify-content-end mt-2 confirm-step-btn'>
                            <span
                              className='mr-3'
                              onClick={() =>
                                handleClick("two", stepList?.two.value)
                              }
                            >
                              네
                            </span>
                            <span>아니오</span>
                          </div>
                        </PopoverBody>
                      </UncontrolledPopover>
                    )}
                    <div className='ml-3'>
                      <h3 className='d-flex justify-content-between align-items-center'>
                        조립
                        {stepList?.two.value && (
                          <span>
                            <i
                              onClick={() => startUpdate("two")}
                              className='fas fa-edit'
                            ></i>
                          </span>
                        )}
                      </h3>
                      <span>
                        {stepList?.two.value
                          ? currentDatehangle(stepList?.two.value) + " 완료"
                          : ""}
                      </span>
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>

            {/* 용접 */}
            <Card className='assemble-status-card'>
              <CardBody>
                {stepList?.three.isUpdate ? (
                  <div className='picker-container'>
                    <div className='d-flex justify-content-end mb-3'>
                      <i
                        className='fas fa-save'
                        onClick={() => saveUpdate("three")}
                      ></i>
                      <i
                        className='fas fa-window-close'
                        onClick={() => closeUpdate("three")}
                      ></i>
                    </div>
                    <div className='picker'>
                      <DatePicker
                        clearIcon={false}
                        inputReadOnly={true}
                        value={moment(updateForm?.three.date)}
                        className='mr-2'
                        onChange={(e) => {
                          console.log(e._d);
                          setUpdateForm({
                            ...updateForm,
                            three: {
                              ...updateForm.three,
                              date: e._d.getTime(),
                            },
                          });
                        }}
                      />
                      <TimePicker
                        clearIcon={false}
                        inputReadOnly={true}
                        format={"HH:mm"}
                        value={moment(updateForm?.three.time)}
                        onSelect={(value, dateString) => {
                          setUpdateForm({
                            ...updateForm,
                            three: {
                              ...updateForm.three,
                              time: value._d.getTime(),
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div className='step-conteiner'>
                    <Button
                      // color='primary'
                      style={{
                        backgroundColor: "rgb(80, 150, 255)",
                        color: "white",
                        borderRadius: "15px",
                      }}
                      // disabled={stepList?.three.value ? true : false}
                      id={`three`}
                      onClick={(e) => {
                        if (!stepList?.three.value) {
                          handleClick("three", stepList?.three.value, e);
                        }
                      }}
                      size='lg'
                    >
                      {stepList?.three.value ? (
                        <i className='fas fa-check'></i>
                      ) : (
                        <i className='fas fa-circle'></i>
                      )}
                    </Button>
                    {stepList?.three.value && (
                      <UncontrolledPopover
                        trigger='focus'
                        placement='top'
                        target={`three`}
                      >
                        <PopoverBody>
                          <span>취소하시겠습니까?</span> <br />
                          <div className='d-flex justify-content-end mt-2 confirm-step-btn'>
                            <span
                              className='mr-3'
                              onClick={() =>
                                handleClick("three", stepList?.three.value)
                              }
                            >
                              네
                            </span>
                            <span>아니오</span>
                          </div>
                        </PopoverBody>
                      </UncontrolledPopover>
                    )}
                    <div className='ml-3'>
                      <h3 className='d-flex justify-content-between align-items-center'>
                        용접
                        {stepList?.three.value && (
                          <span>
                            <i
                              onClick={() => startUpdate("three")}
                              className='fas fa-edit'
                            ></i>
                          </span>
                        )}
                      </h3>
                      <span>
                        {stepList?.three.value
                          ? currentDatehangle(stepList?.three.value) + " 완료"
                          : ""}
                      </span>
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>
          </div>
          <hr />
          <Row className='tab-card-body d-flex justify-content-between mt-4 align-items-center'>
            <Col sm={12} md={6} className=''>
              <FormGroup className={""}>
                <div className='ml-auto mr-0 mb-3'>
                  <Button
                    color='primary'
                    size='sm'
                    onClick={(e) => {
                      handleConnectSerial();
                    }}
                    disabled={isSerialConnected}
                  >
                    시리얼 연결
                  </Button>
                </div>
                <div className=' w-100'>
                  <Label for='registerNumber' className='mr-sm-2 mb-2'>
                    시리얼 이름(예시) :
                  </Label>
                  <Input
                    type='text'
                    name='registerNumber'
                    id=''
                    placeholder='Numato Lab 8 Channel USB GPIO Module'
                    className='model-input  m-0 '
                    style={{}}
                    disabled
                  ></Input>
                </div>
              </FormGroup>
            </Col>
            <Col sm={12} md={6} className=''>
              <FormGroup className={``}>
                <div className='d-flex justify-content-between mb-3 w-100 '>
                  <span>
                    {sevenPinValue === 0 && isSerialConnected ? (
                      <span className='text-sm' style={{ color: "#525f7f" }}>
                        <i className='fas fa-bell mr-2'></i>
                        가동중
                      </span>
                    ) : (
                      <span className='text-muted text-sm'>
                        <i className='fas fa-bell-slash mr-2'></i>
                        미가동중
                      </span>
                    )}
                  </span>
                  <Button
                    color='success'
                    size='sm'
                    disabled={
                      !(sevenPinValue === 0 && isSerialConnected) ||
                      weldingStartState
                    }
                    onClick={async (e) => {
                      // handleConnectSerial();
                      try {
                        await orderApi.requestUpdateWedingTime({
                          weldingTime: Number(weldingTime),
                          id: productList[0].id,
                        });
                        messageApi.success("저장되었습니다.");
                      } catch (e) {
                        console.log(e);
                        messageApi.warning("관리자에게 문의해주세요.");
                      }
                    }}
                  >
                    <i className='fas fa-save'></i>
                  </Button>
                </div>
                <div className=' w-100'>
                  <Label for='registerNumber' className='mr-sm-2 mb-2'>
                    용접 시간측정 (초):
                  </Label>
                  <Input
                    type='number'
                    name=''
                    id=''
                    placeholder='0 sec'
                    className='model-input  m-0 '
                    style={{}}
                    value={weldingTime}
                    onChange={(e) => {
                      console.log(e.target.value);
                      setWeldingTime(e.target.value);
                    }}
                    disabled={
                      !(sevenPinValue === 0 && isSerialConnected) ||
                      weldingStartState
                    }
                  ></Input>
                </div>
              </FormGroup>
            </Col>
          </Row>
          <ProduceTable
            isNext={isNext}
            userRequest={userRequest}
            handleChange={handleChange}
            handleRequestApi={handleRequestApi}
            orderProduceTableData={orderProduceTableData}
            productOrder={productOrder}
            productList={productList}
            setErrMessage={setErrMessage}
            errMessage={errMessage}
            setProductOrder={setProductOrder}
            targetKey={assembleValue}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default AssembleTab;
