/**
 * /*!
 *
 * =========================================================
 * Argon Dashboard React - v1.2.1
 * =========================================================
 *
 * Product Page: https://www.creative-tim.com/product/argon-dashboard-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)
 *
 * Coded by Creative Tim
 *
 * =========================================================
 *
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 *
 * @format
 */

import { Link } from "react-router-dom";
// reactstrap components
import { useSelector } from "react-redux";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import { memo } from "react";
import { useDispatch } from "react-redux";
import userApi from "services/user";
import { useHistory } from "react-router-dom";
import { logout } from "store/actions/user";

import LOGO from "assets/img/logo2.png";

const AdminNavbar = memo((props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handlelogout = () => {
    const { loginKey } = JSON.parse(localStorage.getItem("mes_user"));
    // userApi
    //   .requestLogout({
    //     login_key: loginKey,
    //   })
    //   .then((res) => {
    //     history.push("/login");
    //     dispatch(logout());
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  return (
    <>
      <Navbar
        className='navbar-top navbar-dark nav-bar'
        expand='md'
        id='navbar-main'
      >
        <Container fluid style={{ width: "80%", margin: "auto" }}>
          <Link
            className='h4 mb-0 text-white text-uppercase d-none d-lg-inline-block d-flex align-items-center'
            to='/'
          >
            {/* <span>HANA Mes</span> */}
            <img src={LOGO} height='50' alt='ee' style={{ opacity: 0.7 }} />
          </Link>
          {/* <Form className='navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto'>
            <FormGroup className='mb-0'>
              <InputGroup className='input-group-alternative'>
                <InputGroupAddon addonType='prepend'>
                  <InputGroupText>
                    <i className='fas fa-search' />
                  </InputGroupText>
                </InputGroupAddon>
                <Input placeholder='Search' type='text' />
              </InputGroup>
            </FormGroup>
          </Form> */}
          {/* <Nav className='align-items-center  d-md-flex' navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className='pr-0' nav>
                <Media className='align-items-center'>
                  <span className='avatar avatar-sm rounded-circle'>
                    <img
                      alt='...'
                      src={require("../../assets/img/theme/user.png").default}
                    />
                  </span>
                </Media>
              </DropdownToggle>
              <DropdownMenu className='dropdown-menu-arrow' right>
                <DropdownItem className='noti-title' header tag='div'>
                  <h6 className='text-overflow m-0'>Welcome!</h6>
                </DropdownItem>
                <DropdownItem to='/profile'  tag={Link}>
                  <i className='ni ni-single-02' />
                  <span>My profile</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                  href='#'
                  disabled
                  onClick={(e) => {
                    e.preventDefault();
                    handlelogout();
                  }}
                >
                  <i className='ni ni-user-run' />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav> */}
        </Container>
      </Navbar>
    </>
  );
});

export default AdminNavbar;
