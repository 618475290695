/**
 * /*!
 *
 * =========================================================
 * Argon Dashboard React - v1.2.1
 * =========================================================
 *
 * Product Page: https://www.creative-tim.com/product/argon-dashboard-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)
 *
 * Coded by Creative Tim
 *
 * =========================================================
 *
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 *
 * @format
 */

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import { Provider } from "react-redux";
import store from "store";
import "antd/dist/antd.css";
import moment from "moment";
import locale from "antd/lib/locale/ko_KR"; // 우린 한국인이니까 ko_KR를 불러옵시다
import { ConfigProvider } from "antd";

// font

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={locale}>
      <BrowserRouter>
        <Switch>
          <Route render={(props) => <AdminLayout {...props} />} />
          <Route render={(props) => <AuthLayout {...props} />} />
          {/* <Redirect from='/' to='/admin/index' /> */}
        </Switch>
      </BrowserRouter>
    </ConfigProvider>
  </Provider>,
  document.getElementById("root")
);
