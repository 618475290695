/** @format */

import React, { useEffect } from "react";

import { useState } from "react";
import {
  Col,
  Row,
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Label,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
} from "reactstrap";

import { find, findIndex, unionBy } from "lodash";
import AutoCompleteInput from "./AutoCompleteInput";
import { useSelector } from "react-redux";
import AutoCompleteInstitution from "./AutoCompleteInstitution";
import InstitutionAPI from "services/institution";

import { message } from "antd";

const InstitutionModal = ({ modalOpen, toggleMadal, onSumbit }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const { orderList } = useSelector((state) => ({
    orderList: state.order.orderList,
  }));

  const [institutionList, setInstitutionList] = useState({});

  const [form, setForm] = useState({
    institution: "",
    manager: [
      {
        id: new Date().getTime(),
        manager: "",
        isCreate: true,
      },
    ],
  });

  useEffect(() => {
    setForm({
      institution: "",
      manager: [
        {
          id: new Date().getTime(),
          manager: "",
          isCreate: true,
        },
      ],
    });
  }, [modalOpen]);

  useEffect(() => {
    if (!modalOpen) return;
    InstitutionAPI.requestRead()
      .then((res) => {
        const newData = {};
        for (let item of res) {
          const { name, manager, id } = item;

          if (newData[name]) {
            newData[name].push({
              id,
              manager,
              isCreate: false,
              disabled: true,
            });
          } else {
            newData[name] = [
              {
                id,
                manager,
                isCreate: false,
                disabled: true,
              },
            ];
          }
        }

        setInstitutionList(newData);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [modalOpen]);

  const handleChange = (value) => {
    const manager = institutionList[value]
      ? [
          ...institutionList[value],
          {
            id: new Date().getTime(),
            manager: "",
            isCreate: true,
          },
        ]
      : [
          {
            id: new Date().getTime(),
            manager: "",
            isCreate: true,
          },
        ];
    setForm({
      ...form,
      institution: value,
      manager,
    });
  };

  // useEffect(() => {
  //   const newData = {};

  //   for (let item of orderList) {
  //     const { institution, manager } = item;

  //     if (!institution || !manager) continue;

  //     if (newData[institution]) {
  //       if (!newData[institution].includes(manager)) {
  //         newData[institution].push(manager);
  //       }
  //     } else {
  //       newData[institution] = [manager];
  //     }
  //   }

  //   setInstitutionList(newData);
  // }, [orderList]);

  const handleSubmit = async () => {
    if (!form.institution) {
      return;
    }

    const newManager = form.manager.filter((item) => item.manager);
    const dataList = newManager.map((item) => ({
      name: form.institution,
      manager: item.manager,
    }));

    console.log(institutionList[form.institution], dataList);
    const newData = unionBy(dataList, dataList, "manager").filter((item) => {
      const isFind = findIndex(institutionList[form.institution], {
        manager: item.manager,
      });
      return isFind === -1;
    });

    console.log(newData);

    InstitutionAPI.requestCreate({ dataList: newData })
      .then((res) => {
        console.log(res);

        const newInst = {
          ...institutionList,
        };

        if (newInst[form.institution]) {
          newInst[form.institution] = [
            ...newInst[form.institution],
            ...res.map((item) => {
              return {
                ...item,
                isCreate: false,
                disabled: true,
              };
            }),
          ];
        } else {
          newInst[form.institution] = [
            ...res.map((item) => {
              return {
                ...item,
                isCreate: false,
                disabled: true,
              };
            }),
          ];
        }

        setInstitutionList(newInst);
        const newData = {
          ...form,
          manager: [
            {
              id: new Date().getTime(),
              manager: "",
              isCreate: true,
            },

            ...res.map((item) => ({
              ...item,
              isCreate: false,
              disabled: true,
            })),
          ],
        };

        if (institutionList[form.institution]) {
          console.log(institutionList[form.institution]);
          newData.manager.push(...institutionList[form.institution]);
        }

        setForm(newData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUpdate = async (data) => {
    const managerList = institutionList[form.institution];

    const isFind = findIndex(managerList, { manager: data.manager });
    console.log(isFind);

    if (isFind !== -1) {
      messageApi.warning({
        content: "같은 이름의 담당자가 있습니다.",
      });

      return;
    }

    InstitutionAPI.requestUpdate(data)
      .then((res) => {
        console.log(res);
        setInstitutionList((pre) => ({
          ...pre,
          [form.institution]: pre[form.institution].map((item) => {
            if (item.id === data.id) {
              return {
                ...data,
                disabled: true,
              };
            }
            return item;
          }),
        }));

        setForm((pre) => ({
          ...pre,
          manager: pre.manager.map((item) => {
            if (item.id === data.id) {
              return {
                ...data,
                disabled: true,
              };
            }
            return item;
          }),
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = async (data) => {
    InstitutionAPI.requestDelete({ id: data.id })
      .then((res) => {
        console.log(res);

        setInstitutionList((pre) => ({
          ...pre,
          [form.institution]: pre[form.institution].filter(
            (item) => item.id !== data.id
          ),
        }));

        setForm((pre) => ({
          ...pre,
          manager: pre.manager.filter((item) => item.id !== data.id),
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {contextHolder}
      <Modal size='l' isOpen={modalOpen} toggle={toggleMadal}>
        <ModalHeader toggle={toggleMadal} tag='h3'>
          수요기관 & 담당자 등록
        </ModalHeader>
        <ModalBody>
          <div
            className='orderform'
            style={{
              height: "70vh",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <Row>
              <Col xs={12}>
                <Form role='form'>
                  <Label
                    className='form-control-label'
                    htmlFor='input-username'
                  >
                    * 수요기관
                  </Label>
                  <AutoCompleteInstitution
                    placeholder={"수요기관을 입력해 주세요."}
                    handleChange={handleChange}
                    formData={form}
                    itemList={Object.keys(institutionList)}
                    inputType={"institution"}
                  />
                  <hr />
                  <Label
                    className='form-control-label'
                    htmlFor='input-username'
                  >
                    담당자
                  </Label>
                  {form.manager
                    .filter((item) => item.isCreate)
                    .map((item, index) => {
                      const { manager, id } = item;
                      return (
                        <Input
                          key={id}
                          className='mb-4'
                          placeholder='담당자 이름'
                          type='text'
                          value={manager}
                          onChange={(e) => {
                            setForm((pre) => ({
                              ...pre,
                              manager: pre.manager.map((m) => {
                                if (m.id === id) {
                                  return {
                                    ...m,
                                    manager: e.target.value,
                                  };
                                }
                                return m;
                              }),
                            }));
                          }}
                        />
                      );
                    })}
                  <div className='d-flex justify-content-center my-3'>
                    <i
                      className='far fa-plus-square'
                      onClick={() => {
                        setForm((pre) => ({
                          ...pre,
                          manager: [
                            ...pre.manager,
                            {
                              id: new Date().getTime(),
                              manager: "",
                              isCreate: true,
                            },
                          ],
                        }));
                      }}
                    ></i>
                  </div>

                  <hr />
                  {form.manager
                    .filter((item) => !item.isCreate)
                    .map((item, index) => {
                      const { manager, id, disabled } = item;
                      console.log(disabled);
                      return (
                        <div
                          key={id}
                          className='d-flex mb-4 justify-content-between align-items-center'
                        >
                          <Input
                            className=''
                            placeholder='담당자 이름'
                            type='text'
                            value={manager}
                            disabled={disabled}
                            style={{
                              width: "75%",
                            }}
                            onChange={(e) => {
                              setForm((pre) => ({
                                ...pre,
                                manager: pre.manager.map((m) => {
                                  if (m.id === id) {
                                    return {
                                      ...m,
                                      manager: e.target.value,
                                    };
                                  }
                                  return m;
                                }),
                              }));
                            }}
                          />
                          <div>
                            {disabled ? (
                              <>
                                <Button
                                  className=''
                                  color='primary'
                                  type='button'
                                  onClick={() => {
                                    setForm((pre) => ({
                                      ...pre,
                                      manager: pre.manager.map((m) => {
                                        if (m.id === id) {
                                          return {
                                            ...m,
                                            disabled: false,
                                          };
                                        }
                                        return m;
                                      }),
                                    }));
                                  }}
                                  size='sm'
                                >
                                  수정
                                </Button>
                                <Button
                                  className=''
                                  color='danger'
                                  type='button'
                                  size='sm'
                                  onClick={() => {
                                    handleDelete(item);
                                  }}
                                >
                                  삭제
                                </Button>
                              </>
                            ) : (
                              <>
                                <Button
                                  className=''
                                  color='success'
                                  type='button'
                                  onClick={() => {
                                    handleUpdate(item);
                                  }}
                                  size='sm'
                                  disabled={!item.manager}
                                >
                                  저장
                                </Button>
                                <Button
                                  className=''
                                  color='danger'
                                  type='button'
                                  size='sm'
                                  onClick={() => {
                                    setForm((pre) => ({
                                      ...pre,
                                      manager: pre.manager.map((m) => {
                                        const data = find(
                                          institutionList[form.institution],
                                          { id }
                                        );
                                        if (m.id === id) {
                                          return {
                                            ...data,
                                            disabled: true,
                                            isCreate: false,
                                          };
                                        }
                                        return m;
                                      }),
                                    }));
                                  }}
                                >
                                  취소
                                </Button>
                              </>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </Form>
              </Col>
            </Row>
          </div>
          <div className='text-center'>
            <Button
              className='mb-4'
              color='primary'
              type='button'
              onClick={handleSubmit}
            >
              등록
            </Button>
            <Button
              className='mb-4'
              color='danger'
              type='button'
              onClick={toggleMadal}
            >
              취소
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default InstitutionModal;
