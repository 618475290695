/**
 * /*!
 *
 * =========================================================
 * Argon Dashboard React - v1.2.1
 * =========================================================
 *
 * Product Page: https://www.creative-tim.com/product/argon-dashboard-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)
 *
 * Coded by Creative Tim
 *
 * =========================================================
 *
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 *
 * @format
 */

import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import { ThreeDots } from "react-loader-spinner";

import routes from "routes.js";
import axios from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import userApi from "services/user";
import { useDispatch } from "react-redux";
import { login, logout } from "store/actions/user";
import { addOrder, deleteOrder, setOrder } from "store/actions/order";
import orderApi from "services/order";
import NotFound from "./NotFound";
import { setIsLoading as storeSetIsLoading } from "store/actions/user";

/// 리프레시 토큰
let isAlreadyFetchingAccessToken = false;
let subscribers = [];

const authPage = (path) => {
  if (
    path === "/register" ||
    path === "/email" ||
    path === "/login" ||
    path === "/password"
  ) {
    return true;
  }
  return false;
};

const Admin = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { isLogin, storeLoading } = useSelector((state) => ({
    isLogin: state.user.isLogin,
    storeLoading: state.user.isLoading,
  }));

  useEffect(() => {
    if (storeLoading) {
      setTimeout(() => {
        if (storeLoading) {
          dispatch(storeSetIsLoading(false));
        }
      }, 5000);
    }
  }, [storeLoading]);

  // const onSubmit = () => {
  //   const form = {
  //     // email: "airwalk741@naver.com",
  //     email: "admin@ijoon.net",
  //     // password: "qwer1234!",
  //     password: "qwer1234!",
  //   };

  //   userApi
  //     .requestLogin({ ...form })
  //     .then(async (res) => {
  //       localStorage.setItem("mes_user", JSON.stringify(res));
  //       axios.defaults.headers.common["Authorization"] = res.accessToken;

  //       try {
  //         const res = await userApi.requestGetUser();
  //         dispatch(login({ ...res.users }));
  //         if (authPage(location.pathname)) {
  //           history.push("/");
  //         }
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     })
  //     .catch(async (err) => {
  //       const status = err.response.status;
  //       if (status === 401) {
  //         localStorage.setItem("email", form.email);
  //       } else if (status === 429) {
  //         //
  //         const message = JSON.parse(err.response.data.message);
  //         const { login_infos } = message;

  //         const login_key = Object.keys(login_infos)[0];

  //         try {
  //           const res = await userApi.requestRemoveToken({
  //             ...form,
  //             login_key,
  //           });
  //           onSubmit();
  //         } catch (error) {
  //           console.log(error);
  //         }
  //       } else if (status === 404 || status === 401 || status === 400) {
  //       } else if (status === 409) {
  //         let { message } = err.response.data;
  //         message = JSON.parse(message);
  //         const { allowed_after } = message;
  //       }
  //     });
  // };

  useEffect(() => {
    if (isLogin) {
      orderApi
        .requestReadOrderList()
        .then((res) => {
          const curreDate = new Date().getTime() - 1000 * 60 * 60 * 24;
          // 기한 끝났는지 확인
          dispatch(
            setOrder(
              res.map((item) => {
                // console.log(item.completedStatus);
                let completedStatus;

                try {
                  completedStatus = JSON.parse(item.completedStatus);
                } catch {
                  completedStatus = item.completedStatus;
                }

                if (completedStatus.complete) {
                  if (
                    item.dueDate <
                    completedStatus.complete - 1000 * 60 * 60 * 24
                  ) {
                    return {
                      ...item,
                      isOver: true,
                      clientStatus: item.status,
                      completedStatus: completedStatus,
                    };
                  } else {
                    return {
                      ...item,
                      isOver: false,
                      clientStatus: item.status,
                      completedStatus: completedStatus,
                    };
                  }
                } else {
                  if (curreDate > item.dueDate) {
                    return {
                      ...item,
                      isOver: true,
                      clientStatus: item.status,
                      completedStatus: completedStatus,
                    };
                  } else {
                    return {
                      ...item,
                      isOver: false,
                      clientStatus: item.status,
                      completedStatus: completedStatus,
                    };
                  }
                }
              })
            )
          );
        })
        .catch((err) => {
          console.log(err);
          const { status } = err.response;
          if (status === 404) {
            signOut();
          }
        });
    } else {
      // onSubmit();
    }

    // eslint-disable-next-line
  }, [isLogin]);

  useEffect(() => {
    // if (localStorage.getItem("mes_user") && !isLogin) {
    //   const { accessToken } = JSON.parse(localStorage.getItem("mes_user"));
    //   axios.defaults.headers.common["Authorization"] = accessToken;
    //   userApi
    //     .requestGetUser()
    //     .then((res) => {
    //       console.log(res);
    //       dispatch(login({ ...res.users }));
    //       if (authPage(location.pathname)) {
    //         history.push("/");
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       history.push("/login");
    //       localStorage.removeItem("mes_user"); // 권한없으면 로그아웃해버리기
    //     });
    // } else {
    //   if (!authPage(location.pathname) && !isLogin) {
    //     history.push("/login");
    //   }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route path={prop.path} component={prop.component} key={key} exact />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (props.location.pathname === routes[i].path) {
        return routes[i].name;
      }
    }

    return "Brand";
  };

  const [isLoading, setIsLoading] = useState(false);

  // 요청 인터셉터 추가하기
  axios.interceptors.request.use(
    (config) => {
      // 요청이 전달되기 전에 작업 수행
      setIsLoading(true);
      return config;
    },
    (error) => {
      // 요청 오류가 있는 작업 수행
      setIsLoading(false);
      return Promise.reject(error);
    }
  );

  // 응답 인터셉터 추가하기
  axios.interceptors.response.use(
    (response) => {
      // 2xx 범위에 있는 상태 코드는 이 함수를 트리거 합니다.
      // 응답 데이터가 있는 작업 수행
      setIsLoading(false);

      // const { url, method } = response.config;

      // if (url === "/api/auth/v1/login" && method === "post") {
      //   isAlreadyFetchingAccessToken = false;
      // }

      return response;
    },
    async (err) => {
      // 2xx 외의 범위에 있는 상태 코드는 이 함수를 트리거 합니다.
      // 응답 오류가 있는 작업 수행
      setIsLoading(false);
      // const {
      //   config,
      //   response: { status },
      // } = err;

      // const reqURL = config.url;
      // const reqMethod = config.method;

      // let refreshToken = null;
      // let loginKey = null;
      // if (localStorage.getItem("mes_user")) {
      //   refreshToken = JSON.parse(
      //     localStorage.getItem("mes_user")
      //   ).refreshToken;
      //   loginKey = JSON.parse(localStorage.getItem("mes_user")).loginKey;
      // }

      // if (
      //   status === 401 &&
      //   refreshToken &&
      //   !(reqURL === "/api/auth/v1/access-token" && reqMethod === "get")
      //   // && !(reqURL === '/api/auth/v1/logout' && reqMethod === 'post')
      // ) {
      //   try {
      //     const { response: errorResponse } = err;

      //     // subscribers에 access token을 받은 이후 재요청할 함수 추가 (401로 실패했던)
      //     // retryOriginalRequest는 pending 상태로 있다가
      //     // access token을 받은 이후 onAccessTokenFetched가 호출될 때
      //     // access token을 넘겨 다시 axios로 요청하고
      //     // 결과값을 처음 요청했던 promise의 resolve로 settle시킨다.
      //     const retryOriginalRequest = new Promise((resolve, reject) => {
      //       addSubscriber(async (token) => {
      //         axios.defaults.headers.common["Authorization"] = token;
      //         config.headers.Authorization = token;
      //         try {
      //           resolve(axios(errorResponse.config));
      //         } catch (err) {
      //           reject(err);
      //         }
      //       });
      //     });
      //     // refresh token을 이용해서 access token 요청
      //     if (!isAlreadyFetchingAccessToken) {
      //       isAlreadyFetchingAccessToken = true; // 문닫기 (한 번만 요청)
      //       const res = await userApi.requestUpdateToken(refreshToken);
      //       console.log("토큰 갱신");
      //       const newToken = res.data.accessToken;
      //       const newLocalData = {
      //         accessToken: newToken,
      //         loginKey: loginKey,
      //         refreshToken: refreshToken,
      //       };
      //       localStorage.setItem("mes_user", JSON.stringify(newLocalData));
      //       axios.defaults.headers.common["Authorization"] = newToken;
      //       config.headers.Authorization = newToken;
      //       isAlreadyFetchingAccessToken = false; // 문열기 (초기화)
      //       onAccessTokenFetched(newToken);
      //     }
      //     return retryOriginalRequest; // pending 됐다가 onAccessTokenFetched가 호출될 때 resolve
      //   } catch (error) {
      //     return Promise.reject(error);
      //   }
      // } else if (
      //   status === 401 &&
      //   reqURL === "/api/auth/v1/access-token" &&
      //   reqMethod === "get"
      // ) {
      //   if (localStorage.getItem("mes_user")) {
      //     console.log("refresh 만료");
      //     signOut();
      //     history.push("/login");
      //     localStorage.removeItem("mes_user");
      //     dispatch(logout());
      //   }
      // }
      return Promise.reject(err);
    }
  );

  function addSubscriber(callback) {
    subscribers.push(callback);
  }

  function onAccessTokenFetched(token) {
    subscribers.forEach((callback) => callback(token));
    subscribers = [];
  }

  const signOut = () => {
    // userApi
    //   .requestUpdateToken({
    //     login_key: JSON.parse(localStorage.getItem("mes_user")).loginKey,
    //   })
    //   .then((res) => {})
    //   .catch((err) => {});
  };

  return (
    <>
      {/* <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/",
          imgSrc: require("../assets/img/brand/argon-react.png").default,
          imgAlt: "...",
        }}
      /> */}
      {(isLoading || storeLoading) && (
        <div className='loading'>
          <div className='loading-child'>
            <ThreeDots
              height='80'
              width='80'
              radius='9'
              color='#4fa94d'
              ariaLabel='three-dots-loading'
              wrapperStyle={{}}
              wrapperClassName=''
              visible={true}
            />
          </div>
        </div>
      )}
      <div className='main-content' ref={mainContent}>
        {!authPage(location.pathname) && (
          <AdminNavbar
            {...props}
            brandText={getBrandText(props.location.pathname)}
          />
        )}
        <Switch>
          {getRoutes(routes)}
          <Route path={"*"} component={NotFound} />
          {/* <Redirect from='*' to='/' /> */}
        </Switch>
      </div>
      {!authPage(location.pathname) && <AdminFooter />}
    </>
  );
};

export default Admin;
