/** @format */

import axios from "axios";

class OrderAPI {
  constructor() {
    this.URL = "";
    this.get = async (url, config) => {
      const res = await axios.get(url, { ...config });
      return res.data;
    };
    this.post = async (url, data, config = {}) => {
      const res = await axios.post(url, data, { ...config });
      console.log(config);
      return res.data;
    };
    this.put = async (url, data, config = {}) => {
      const res = await axios.put(url, data, { ...config });
      return res.data;
    };
    this.delete = async (url, config) => {
      const res = await axios.delete(url, { ...config });
      return res.data;
    };
  }

  // order list 가져오기
  async requestReadOrderList(config) {
    const url = "/api/main/order";
    return this.get(url, config);
  }

  // order 만들기
  async requestCreateOrder(data) {
    const url = "/api/main/order";
    return this.post(url, data);
  }

  // order 삭제
  async requestDeleteOrder(data) {
    const url = "/api/main/order";
    return this.delete(url, data);
  }

  // order 수정
  async requestUpdateOrder(data) {
    const url = "/api/main/order";
    return this.put(url, data);
  }

  // 품목 리스트 가져오기
  async requestReadProductList(config) {
    const url = "/api/main/order/product";
    return this.get(url, config);
  }

  // 품목 생산, 불량 수정
  async requestUpdateProductList(data, config) {
    const url = "/api/main/order/product";
    return this.put(url, data, config);
  }

  // 제작, 검사, 시운전 단계 수정
  async requestCompletedStaus(data, config) {
    const url = "/api/main/order/status";
    return this.put(url, data, config);
  }

  // 용접시간 수정
  async requestUpdateWedingTime(data, config) {
    const url = "/api/main/order/welding";
    return this.put(url, data, config);
  }
}

export default new OrderAPI();
