/** @format */

import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const makePdf = (order) => {
  const converToImg = async (pdfPage) => {
    // html to imageFile
    const papers = document.querySelectorAll(".div_container > .div_paper");

    // const companyInfo = document.querySelector(".companyInfo");
    // const memo = document.querySelector(".memo");
    // const orderInfo = document.querySelector(".order-info");
    // const orderImgs = document.querySelectorAll(".pdf-confirm-odd");

    const canvasList = [];
    const imgList = [];
    for (let paper of papers) {
      const canvas = await html2canvas(paper, { scale: 4 });
      const imageFile = canvas.toDataURL("image/png", 1.0);
      canvasList.push(canvas);
      imgList.push(imageFile);
    }
    return [imgList, canvasList, order];
  };

  const converToPdf = (imgList, canvasList, order) => {
    // imageFile to pdf

    const doc = new jsPDF("p", "mm", "a4");

    // const companyInfo = document.querySelector(".companyInfo");
    // const memo = document.querySelector(".memo");
    // const orderInfo = document.querySelector(".order-info");
    // const orderImgs = document.querySelectorAll(".pdf-confirm-odd");

    // let beforeImgHeight =
    //   companyInfo.clientHeight + memo.clientHeight + orderInfo.clientHeight;

    // let imgNumber = 0;

    // const pageWidth = doc.internal.pageSize.getWidth();
    // const pageHeight = doc.internal.pageSize.getHeight();

    for (let i = 0; i < canvasList.length; i++) {
      const canvas = canvasList[i];
      const imageFile = imgList[i];
      var pageWidth = 210; //가로 길이 a4 기준
      var pageHeight = pageWidth * 1.414; //출력 페이지 세로길이
      var imgWidth = pageWidth - 20;

      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;

      var position = 0;
      doc.addImage(imageFile, "JPEG", 10, position, imgWidth, imgHeight);

      if (i !== canvasList.length - 1) {
        doc.addPage();
      }
    }

    // doc.addImage(imageFile, "JPEG", 0, 0, pageWidth, pageHeight);

    // doc.save("test.pdf")

    window.open(doc.output("bloburl"));

    const pdf = new File([doc.output("blob")], `${order.projectName}.pdf`, {
      type: "application/pdf",
    });
    return pdf;
  };

  return {
    viewWithPdf: async (pdfPage) => {
      // html to imageFile
      const [imageFile, canvas, order] = await converToImg(pdfPage);
      // imageFile to Pdf
      const pdf = converToPdf(imageFile, canvas, order);
    },
  };
};

export default makePdf;
