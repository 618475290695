/** @format */

import axios from "axios";

class Purchase {
  constructor() {
    this.URL = "";
    this.get = async (url, config) => {
      const res = await axios.get(url, { ...config });
      return res.data;
    };
    this.post = async (url, data, config = {}) => {
      const res = await axios.post(url, data, { ...config });
      return res.data;
    };
    this.put = async (url, data, config = {}) => {
      const res = await axios.put(url, data, { ...config });
      return res.data;
    };
    this.delete = async (url, config) => {
      const res = await axios.delete(url, { ...config });
      return res.data;
    };
  }

  // Purchase 전체 가져오기
  async requestReadAllPurchase(config) {
    const url = "/api/main/purchase/all";
    return this.get(url, config);
  }

  // Purchase 가져오기
  async requestReadPurchase(config) {
    const url = "/api/main/purchase";
    return this.get(url, config);
  }

  // Purchase 만들기
  async requestCreatePurchase(data, config) {
    const url = "/api/main/purchase";
    return this.post(url, data, config);
  }

  // 상호명, 품목 목록 가져오기
  async requestReadIndexList(config) {
    const url = "/api/main/purchase/list";
    return this.get(url, config);
  }

  // purchase 삭제
  async requestDeletePurchase(data) {
    const url = "/api/main/purchase";
    return this.delete(url, data);
  }

  // purchase 수정
  async requestUpdatePurchase(data, config) {
    const url = "/api/main/purchase";
    return this.put(url, data, config);
  }
}

export default new Purchase();
