/** @format */

import { useEffect, useState } from "react";
import { Alert } from "reactstrap";
import ExcelJS from "exceljs";

import OrderStepTable from "./OrderStepTable";
import ProductInfoTable from "./ProductInfoTable";

import { currentDate, excelCurrentDate } from "../../../js/operator";
import makePdf from "pages/js/make_pdf";
import _ from "lodash";
import saveAs from "file-saver";
import JSZip from "jszip";
import { handlerClickDownloadButton } from "pages/js/make_excel";

import purchaseApi from "services/purchase";
import orderApi from "services/order";
import designApi from "services/design";
import { useDispatch } from "react-redux";
import { setIsLoading } from "store/actions/user";
import ExcelText from "./ExcelText";

import axios from "axios";
import { useSelector } from "react-redux";
import BigOrderTable from "./BigOrderTable";
import TimeUpdateComponent from "./TimeUpdateComponent";

const OrderComplete = ({ order, isDownload, setIsDownLoad }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const [errMessage, setErrMessage] = useState(false);
  const dispatch = useDispatch();
  const { orderList } = useSelector((state) => ({
    orderList: state.order.orderList,
  }));

  const returnKPIValue = () => {
    if (_.isEmpty(orderList)) return;

    let totalCount = 0;
    let dueCount = 0;

    const targetComplete = order.completedStatus.complete;

    const targetDate = new Date(targetComplete);
    const year = targetDate.getFullYear();
    const month = ("0" + (targetDate.getMonth() + 1)).slice(-2);
    const date = ("0" + Number(targetDate.getDate() + 1).toString()).slice(-2);

    const targetMonth = new Date(`${year}-${month}-01 00:00:00`).getTime();
    const targetDay = new Date(`${year}-${month}-${date} 00:00:00`).getTime();

    for (let item of orderList) {
      const { dueDate } = item;

      const {
        completedStatus: { complete },
      } = item;

      if (item.id === order.id) {
        totalCount += 1;
        if (dueDate < complete - 1000 * 60 * 60 * 24) {
          dueCount += 1;
        }
        continue;
      }

      // 일자 기준으로 납품 완료했는데 지금 보고 있는 주문건 납품일보다 이후꺼임
      if (complete) {
        // 해당 월 찾기
        if (complete < targetMonth) continue;
        if (complete > targetDay) {
          continue;
        }
      } else {
        // 납품까지 완료를 하지 않았어도 현재 시간 체크해서 해당 주문일보다 크면 패스함
        if (new Date().getTime() > targetComplete) {
          continue;
        }
      }

      // 같은 날 다른 시간 구분
      if (targetComplete < complete) {
        continue;
      }

      // 전체 담기
      // if (dueDate >= targetComplete - 1000 * 60 * 60 * 24) {
      totalCount += 1;
      // }

      if (!complete) {
        dueCount += 1;
      } else if (dueDate < complete - 1000 * 60 * 60 * 24) {
        dueCount += 1;
      }
    }

    return { dueCount, totalCount };
  };

  // useEffect(() => {
  //   test();
  // }, [orderList]);

  // 품목 정보
  const [productList, setProductList] = useState([]);

  // 구매품 정보 받아오기
  const [purchaseList, setPurchaseList] = useState([]);

  const { completedStatus } = order;

  const pdf = makePdf(order);

  // const { orderInfo, confirm, completedDate?, partlist } = orderData;
  const orderInfo = order;
  const completedDate =
    typeof order.completedStatus === "string"
      ? JSON.parse(order.completedStatus)
      : order.completedStatus;

  const downloadPDF = async (e) => {
    dispatch(setIsLoading(true));
    await pdf.viewWithPdf(pdfPage);
    dispatch(setIsLoading(false));
  };

  // 설계 자료 압축
  const zipDesignFile = (designList, tab) => {
    const zip = new JSZip();
    dispatch(setIsLoading(true));
    const remoteZips = designList.map(async (file) => {
      // const fetchedFile = await fetch(`/${file.filePath}`)
      //   .then((res) => {
      //     if (res.status === 200) return res.blob();
      //   })
      //   .catch((err) => console.log(err));

      const fetchedFile = await axios({
        url: `/${file.filePath}`,
        responseType: "arraybuffer",
      })
        .then((res) => {
          if (res.status === 200) return new Blob([res.data]);
        })
        .catch((err) => console.log(err));

      if (fetchedFile) {
        if (file.tab === 0) {
          zip.folder("승인도서").file(`${file.name}`, fetchedFile, {
            binary: true,
          });
        } else {
          zip.folder("설계").file(`${file.name}`, fetchedFile, {
            binary: true,
          });
        }
      }
    });

    Promise.all(remoteZips).then(() => {
      zip.generateAsync({ type: "blob" }).then((blob) => {
        saveAs(blob, `${order.projectName}.zip`);
        dispatch(setIsLoading(false));
      });
    });
  };

  // 설계 자료 다운로드
  const saveFilesAsZip = async (files) => {
    let tab1Data;
    let tab2Data;
    try {
      tab1Data = await designApi.requestReadDesign({
        params: {
          orderID: id,
          tab: 0,
        },
      });
      tab2Data = await designApi.requestReadDesign({
        params: {
          orderID: id,
          tab: 1,
        },
      });
    } catch (err) {
      console.log(err);
    }

    if (_.isEmpty(tab1Data) && _.isEmpty(tab2Data)) {
      setErrMessage("설계 데이터가 없습니다.");
      return;
    }

    zipDesignFile([...tab1Data, ...tab2Data]);
  };

  // kpi download
  const excelTest = async () => {
    let errorQuantity = 0;
    let produceQuantity = 0;
    let errorTotal = 0;
    for (let item of productList) {
      errorQuantity += item.errorQuantity;
      produceQuantity += item.produceQuantity;
      errorTotal += item.errorTotal;
    }

    const {
      completedStatus: { complete },
      dueDate,
    } = order;

    // const { errorQuantity, produceQuantity } = item;

    const firstData = errorTotal
      ? Number(((errorQuantity / errorTotal) * 100).toFixed(2))
      : 0;

    const { dueCount, totalCount } = returnKPIValue();

    // if (1) return;

    const secondData = totalCount
      ? Number((((totalCount - dueCount) / totalCount) * 100).toFixed(2))
      : 0;
    // const downDate = excelCurrentDate(complete);

    const downDate = excelCurrentDate(complete);

    axios({
      url: "/api/main/order/kpi",
      responseType: "arraybuffer",
      method: "post",
      data: {
        firstData,
        secondData,
        downDate,
      },
    })
      .then((res) => {
        let url = URL.createObjectURL(new Blob([res.data]));
        const aTag = document.createElement("a");
        aTag.href = url;
        aTag.download = `${downDate}.xlsx`;
        document.body.appendChild(aTag);
        aTag.click();
        document.body.removeChild(aTag);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log(order);
  const downloadExcel = async () => {
    const {
      completedStatus: { complete },
      dueDate,
    } = order;

    const product = productList[0];
    if (product === undefined) return;

    const { errorTotal, errorQuantity, weldingTime } = product;
    console.log(errorTotal, errorQuantity, weldingTime);
    let percent = 0;
    if (Number(errorTotal)) {
      percent = (errorQuantity / errorTotal) * 100;
      percent = percent.toFixed(2);
      percent = Number(percent);
    }

    const res = await axios({
      url: "/KPI_Data_Master(ebi).xlsx",
      responseType: "arraybuffer",
    });

    let workbook = new ExcelJS.Workbook();
    workbook = await workbook.xlsx.load(res.data);

    let worksheet = workbook.getWorksheet("KPI 데이터");
    console.log(worksheet);
    worksheet.getRow(1).getCell("B").value = excelCurrentDate(complete);
    worksheet.getRow(7).getCell("G").value = Number(weldingTime.toFixed(1));
    worksheet.getRow(8).getCell("G").value = percent;

    const uint8Array = await workbook.xlsx.writeBuffer();
    const blob = new Blob([uint8Array], { type: "application/octet-binary" });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${excelCurrentDate(complete)}.xlsx`;
    a.click();
    a.remove();
  };

  useEffect(() => {
    if (isDownload === "pdf") {
      downloadPDF();
    } else if (isDownload === "design") {
      saveFilesAsZip();
    } else if (isDownload === "excel") {
      downloadExcel();
      // excelTest();
    }
    setIsDownLoad(false);
  }, [isDownload]);

  useEffect(() => {
    purchaseApi
      .requestReadAllPurchase({
        params: {
          orderID: id,
        },
      })
      .then((res) => {
        setPurchaseList(res);
        console.log(res);
        // setOrderData(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    orderApi
      .requestReadProductList({
        params: {
          orderID: id,
        },
      })
      .then((res) => {
        setProductList(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [pdfPage, setPdfPage] = useState(1);

  useEffect(() => {
    let page = 1;
    let purchaseListLength = purchaseList.length;

    if (purchaseListLength > 30) {
      page += 1;
      purchaseListLength -= 30;
    }

    if (purchaseListLength !== 0) {
      page += parseInt(purchaseListLength / 35);
    }

    setPdfPage(page);
  }, [purchaseList]);
  return (
    <div className='mb-5 justify-content-center complete'>
      <div className='time-container'>
        <TimeUpdateComponent
          title={"design"}
          dateTime={order.completedStatus.design}
          order={order}
        />
        <TimeUpdateComponent
          title={"product_order"}
          dateTime={order.completedStatus.product_order}
          order={order}
        />
        <TimeUpdateComponent
          title={"product_purchase"}
          dateTime={order.completedStatus.product_purchase}
          order={order}
        />
        <TimeUpdateComponent
          title={"assemble"}
          dateTime={order.completedStatus.assemble.three}
          order={order}
        />
        <TimeUpdateComponent
          title={"confirm"}
          dateTime={order.completedStatus.confirm.four}
          order={order}
        />
        {/* <TimeUpdateComponent
          title={"drive"}
          dateTime={order.completedStatus.drive.two}
          order={order}
        /> */}
        <TimeUpdateComponent
          title={"complete"}
          dateTime={order.completedStatus.complete}
          order={order}
        />
      </div>
      <div className='div_container '>
        <div className='d-flex justify-content-center'>
          <Alert
            toggle={() => setErrMessage(false)}
            isOpen={errMessage ? true : false}
            color='warning'
            style={{ position: "absolute" }}
          >
            <span>{errMessage}</span>
          </Alert>
        </div>
        {/* <div className='d-flex justify-content-between ttt'>
            <div className='logo-container'>
              <img src='/logo2.png' alt='logo-dark' className='logo' />
            </div>
            <div className='info'>
              <p className='fw-bold'> {orderInfo.projectName} </p>
              <p>계약번호: {orderInfo.projectNumber} </p>
              <p>수요기관: {orderInfo.institution} </p>
              <p>발주번호: {orderInfo.orderNumber} </p>
              <p>등록번호: {orderInfo.registerNumber} </p>
              <p>담당자: {orderInfo.manager} </p>
              <p>납기: {currentDate(orderInfo.dueDate)} </p>
            </div>
          </div> */}
        {/* <div className='memo mt-3'>
            <div className='py-4'>
              
              <p>메모 : </p>
            </div>
          </div> */}

        {/* <hr />

          <ProductInfoTable productList={productList} /> */}

        {pdfPage === 1 && (
          <OrderStepTable
            purchaseList={purchaseList}
            completedStatus={completedStatus}
            orderInfo={orderInfo}
          />
        )}

        {/* <div className='d-flex justify-content-center flex-wrap'>
          {confirm.map((item, index) => {
            return (
              <>
                <div
                  className={`pdf-confirm ${
                    (index + 1) % 2 ? "pdf-confirm-odd" : "pdf-confirm-even"
                  } confirmImg`}>
                  <img
                    className='pdf-confirm-img'
                    src={item.filepath}
                    alt={item.name}
                  />
                </div>
              </>
            );
          })}

          {confirm.length % 2 ? (
            <div className='pdf-confirm none pdf-confirm-even'>
              <img className='pdf-confirm-img' src={"/tt.png"} alt={""} />
            </div>
          ) : (
            ""
          )}
        </div> */}
        {pdfPage !== 1 && (
          <BigOrderTable
            purchaseList={purchaseList}
            completedStatus={completedStatus}
            pdfPage={pdfPage}
            orderInfo={orderInfo}
          />
        )}
      </div>
      {/* <button onClick={onClick}>pdf로 보기</button> */}
      {/* <ExcelText /> */}
    </div>
  );
};

export default OrderComplete;
