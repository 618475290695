/** @format */

import React from "react";
import { Card, CardBody, Col, Row, CardTitle } from "reactstrap";

const OrderProgress = ({ progress }) => {
  const { waiting, progressing, completion, overdue, total } = progress;

  return (
    <>
      {/* <h1 className='mb-5'> Progress</h1>
      <Row className='mb-5'>
        <Col xs={12} md={4}>
          <Card>
            <CardBody>
              <span>대기중</span>
              <OrderChart percent={10} />
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} md={4}>
          <Card>
            <CardBody>
              <span>진행중</span>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} md={4}>
          <Card>
            <CardBody>
              <span>완료</span>
            </CardBody>
          </Card>
        </Col>
      </Row> */}
      <Row className='d-flex justify-content-center mb-5'>
        <Col lg='6' xl='3'>
          <Card className='card-stats mb-4 mb-xl-0'>
            <CardBody>
              <Row>
                <div className='col'>
                  <CardTitle
                    tag='h5'
                    className='text-uppercase  text-muted mb-2'>
                    대기중
                  </CardTitle>
                  <span className='h2 font-weight-bold mb-0'>{waiting} 건</span>
                </div>
                <Col className='col-auto'>
                  <div className='icon icon-shape progress-pending text-white rounded-circle shadow'>
                    {/* <i className='fas fa-chart-bar' /> */}
                    <i className='fas fa-male'></i>
                  </div>
                </Col>
              </Row>
              <p className='mt-3 mb-0 text-muted text-sm'>
                <span className=' mr-2'>
                  {/* <i className='fa fa-arrow-up' /> */}
                  {total ? ((waiting / total) * 100).toFixed(2) : "0"} %
                </span>{" "}
                <span className='text-nowrap'>Waiting</span>
              </p>
            </CardBody>
          </Card>
        </Col>
        <Col lg='6' xl='3'>
          <Card className='card-stats mb-4 mb-xl-0'>
            <CardBody>
              <Row>
                <div className='col'>
                  <CardTitle
                    tag='h5'
                    className='text-uppercase  text-muted mb-2'>
                    진행중
                  </CardTitle>
                  <span className='h2 font-weight-bold mb-0'>
                    {progressing} 건
                  </span>
                </div>
                <Col className='col-auto'>
                  <div className='icon icon-shape progress-ing text-white rounded-circle shadow'>
                    {/* <i className='fas fa-chart-pie' /> */}
                    <i className='fas fa-running'></i>
                  </div>
                </Col>
              </Row>
              <p className='mt-3 mb-0 text-muted text-sm'>
                <span className=' mr-2'>
                  {/* <i className='fas fa-arrow-down' /> */}
                  {total ? ((progressing / total) * 100).toFixed(2) : "0"} %
                </span>{" "}
                <span className='text-nowrap'>Proceeding</span>
              </p>
            </CardBody>
          </Card>
        </Col>
        <Col lg='6' xl='3'>
          <Card className='card-stats mb-4 mb-xl-0'>
            <CardBody>
              <Row>
                <div className='col'>
                  <CardTitle
                    tag='h5'
                    className='text-uppercase  text-muted mb-2'>
                    완료
                  </CardTitle>
                  <span className='h2 font-weight-bold mb-0'>
                    {completion} 건
                  </span>
                </div>
                <Col className='col-auto'>
                  <div className='icon icon-shape progress-success text-white rounded-circle shadow'>
                    {/* <i className='fas fa-users' /> */}
                    <i className='fas fa-child'></i>
                  </div>
                </Col>
              </Row>
              <p className='mt-3 mb-0 text-muted text-sm'>
                <span className=' mr-2'>
                  {/* <i className='fas fa-arrow-down' /> */}
                  {total ? ((completion / total) * 100).toFixed(2) : "0"} %
                </span>{" "}
                <span className='text-nowrap'>Completion</span>
              </p>
            </CardBody>
          </Card>
        </Col>
        <Col lg='6' xl='3'>
          <Card className='card-stats mb-4 mb-xl-0'>
            <CardBody>
              <Row>
                <div className='col'>
                  <CardTitle
                    tag='h5'
                    className='text-uppercase  text-muted mb-2'>
                    기한초과
                  </CardTitle>
                  <span className='h2 font-weight-bold mb-0'>{overdue} 건</span>
                </div>
                <Col className='col-auto'>
                  <div className='icon icon-shape progress-danger text-white rounded-circle shadow'>
                    {/* <i className='fas fa-users' /> */}
                    <i className='far fa-frown'></i>
                  </div>
                </Col>
              </Row>
              <p className='mt-3 mb-0 text-muted text-sm'>
                <span className=' mr-2'>
                  {/* <i className='fas fa-arrow-down' /> */}
                  {total ? ((overdue / total) * 100).toFixed(2) : "0"} %
                </span>{" "}
                <span className='text-nowrap'>Overdue</span>
              </p>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default OrderProgress;
