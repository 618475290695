/** @format */

const statusData = [
  {
    title: "기본정보",
    name: "default",
    navTab: ["기본정보"],
    id: 0,
  },
  {
    title: "설계",
    name: "design",
    navTab: [
      "승인도서 작성",
      // "보완 후 재승인",
      "설계",
      // "승인 요청",
      // "보완 후 재승인",
    ],

    id: 1,
  },
  {
    title: "구매품 발주",
    name: "product_order",
    navTab: ["원자재 발주", "외주가공 발주"],
    id: 2,
  },
  {
    title: "구매품 입고",
    name: "product_purchase",
    navTab: ["구매품 입고"],
    id: 3,
  },
  {
    title: "제작",
    name: "assemble",
    navTab: ["제작"],
    id: 4,
  },
  {
    title: "검사",
    name: "confirm",
    navTab: ["검사"],
    id: 5,
  },
  // {
  //   title: "시운전",
  //   name: "drive",
  //   navTab: ["시운전"],
  //   id: 6,
  // },

  {
    title: "납품",
    name: "complete",
    navTab: ["납품"],
    id: 6,
  },
];

const orderData = {
  orderInfo: {
    projectName: "Project A",
    user: "유재석",
    model: "Model A",
    orderDate: 1662303600000,
    dueData: 1663772400000,
    manager: "마이클",
    startDate: 1662345359127,
    endDate: 1663772400000,
  },
  partlist: [
    {
      id: 1,
      produceName: "test1",
      spec: "123",
      total: 123,
    },
    {
      id: 2,
      produceName: "test2",
      spec: "123",
      total: 123,
    },
    {
      id: 3,
      produceName: "test3",
      spec: "123",
      total: 123,
    },
  ],
  confirm: [
    {
      id: 1,
      name: "programmer.png",
      filepath: "./programmer.png",
    },
    {
      id: 2,
      name: "users.png",
      filepath: "./users.png",
    },
    {
      id: 3,
      name: "user.png",
      filepath: "./user.png",
    },
  ],
  totalStatus: {
    default: 1662303600000,
    design: 1662303600000,
    processing: 1662303600000,
    wiring: 1662303600000,
    assemble: 1662303600000,
    confirm: 1662303600000,
  },
};

export { statusData, orderData };
