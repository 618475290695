/** @format */

// 발주일자, 발주번호,

// 번호
// 품명
// 규격
// 단위
// 수량
// 단가
// 금액

import React from "react";
import { useState } from "react";
import {
  Table,
  Card,
  CardBody,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Pagination,
  PaginationItem,
  PaginationLink,
  Form,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import AddPurchaseModal from "./AddPurchaseModal";
import { useEffect } from "react";
import purchaseAip from "services/purchase";
import UpdatePurchaseModal from "./UpdatePurchaseModal";
import useNextStep from "../../../hooks/useNextStep";
import useIsMount from "../../../hooks/useIsMount";

const PurchaseTab = ({
  order,
  isNext,
  handleSubmit,
  setIsNext,
  activeTab,
  handleRequestEnd,
}) => {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");

  const [features, setFeatures] = useState([]);
  const [modalOpen, toggleModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [targetPL, setTargetPL] = useState({});
  const isMount = useIsMount();

  useNextStep({
    order,
    isuseNext: isNext,
    handleSubmit,
    setIsNext,
    handleRequestEnd,
  });

  // purchase get
  useEffect(() => {
    purchaseAip
      .requestReadPurchase({
        params: {
          orderID: id,
          tab: activeTab,
        },
      })
      .then((res) => {
        console.log(res);
        if (isMount.current) {
          setFeatures(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, [activeTab]);

  // 페이지 네이션
  const [page, setPage] = useState(0);
  const [currnetPage, setCurrentPage] = useState(1);
  useEffect(() => {
    if (features.length % 5) {
      setPage(parseInt(features.length / 5 + 1));
    } else {
      setPage(parseInt(features.length / 5));
    }
  }, [features]);

  // purchase 추가
  const onSubmit = async (data) => {
    console.log(data);
    try {
      const res = await purchaseAip.requestCreatePurchase(data, {
        params: {
          orderID: id,
        },
      });
      console.log(res);
      setFeatures([res, ...features]);
    } catch (err) {
      console.log(err);
    }
    toggleModal(false);
  };

  // purchase 삭제
  const handleDelete = async (data) => {
    try {
      const res = await purchaseAip.requestDeletePurchase({
        data: {
          ...data,
        },
        params: {
          orderID: id,
        },
      });
      console.log(res);
      setFeatures([...features.filter((item) => item.id !== data.id)]);
    } catch (err) {
      console.log(err);
    }
  };

  // purchase 수정
  const handleUpdate = async (data) => {
    try {
      const res = await purchaseAip.requestUpdatePurchase(
        { focusList: [{ ...data }] },
        {
          params: {
            orderID: id,
          },
        }
      );
      console.log(res);
      setUpdateModal(false);
      setFeatures([
        ...features.map((item) => (item.id !== data.id ? item : data)),
      ]);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Card className='mb-5' style={{ minHeight: "350px" }}>
        <CardBody>
          <Form inline className='d-flex align-items-center'></Form>
          <div className='d-flex justify-content-end mb-4'>
            <Button color='primary' size='sm' onClick={() => toggleModal(true)}>
              추가
            </Button>
          </div>
          <Table
            className='align-items-center table-flush border-bottom'
            responsive>
            <thead className='thead-light'>
              <tr>
                <th scope='col' style={{ width: "10px" }}>
                  #
                </th>
                <th scope='col'>상호명</th>
                <th scope='col'>품명</th>
                <th scope='col'>규격</th>
                <th scope='col'>단위</th>
                <th scope='col'>수량</th>
                <th scope='col'></th>
              </tr>
            </thead>
            <tbody>
              {features
                .filter(
                  (_, idx) =>
                    idx <= currnetPage * 5 - 1 && (currnetPage - 1) * 5 <= idx
                )
                .map((item, index) => (
                  <tr key={item.id}>
                    <th>{(currnetPage - 1) * 5 + index + 1}</th>
                    <th scope='col'>{item.company}</th>
                    <th> {item.productName} </th>
                    <th> {item.spec} </th>
                    <th> {item.unit} </th>
                    <th> {item.orderQuantity} </th>
                    <td className='text-right' style={{ width: "100px" }}>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          className='btn-icon-only text-light'
                          role='button'
                          size='sm'
                          color=''
                          onClick={(e) => e.preventDefault()}>
                          <i className='fas fa-ellipsis-v' />
                        </DropdownToggle>
                        <DropdownMenu className='dropdown-menu-arrow' right>
                          <DropdownItem
                            className='d-flex align-items-center'
                            onClick={(e) => {
                              setUpdateModal(true);
                              setTargetPL(item);
                            }}>
                            <i className='fas fa-pencil-alt'></i>
                            Update
                          </DropdownItem>
                          <DropdownItem
                            className='d-flex align-items-center'
                            onClick={(e) => handleDelete(item)}>
                            <i
                              className='fas fa-trash-alt'
                              style={{ width: "16px" }}></i>
                            Delete
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <nav aria-label='Page navigation example' className='mt-4'>
            <Pagination
              className='pagination justify-content-end'
              listClassName='justify-content-end'>
              <PaginationItem className={currnetPage === 1 ? "disabled" : ""}>
                <PaginationLink
                  onClick={(e) => e.preventDefault()}
                  tabIndex='-1'>
                  <i className='fa fa-angle-left' />
                  <span className='sr-only'>Previous</span>
                </PaginationLink>
              </PaginationItem>
              {new Array(page).fill(undefined).map((_, index) => (
                <PaginationItem
                  key={index}
                  className={currnetPage === index + 1 ? "active " : ""}
                  onClick={() => setCurrentPage(index + 1)}>
                  <PaginationLink onClick={(e) => e.preventDefault()}>
                    {index + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}
              <PaginationItem
                className={currnetPage === page ? "disabled" : ""}>
                <PaginationLink onClick={(e) => e.preventDefault()}>
                  <i className='fa fa-angle-right' />
                  <span className='sr-only'>Next</span>
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          </nav>
        </CardBody>
      </Card>
      <AddPurchaseModal
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        onSubmit={onSubmit}
        activeTab={activeTab}
      />
      <UpdatePurchaseModal
        modalOpen={updateModal}
        toggleModal={setUpdateModal}
        onSubmit={handleUpdate}
        targetPL={targetPL}
        activeTab={activeTab}
      />
    </>
  );
};

export default PurchaseTab;
