/** @format */

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  Alert,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import Header from "components/Headers/Header";
import { useSelector } from "react-redux";
import { memo } from "react";
import { useState } from "react";
import PasswordChage from "./components/PasswordChage";
import DrawUser from "./components/DrawUser";
import classnames from "classnames";
import UserInfoChange from "./components/UserInfoChange";
import { useEffect } from "react";

const Profile = memo(() => {
  const [tabs, setTabs] = useState(
    localStorage.getItem("profile")
      ? Number(localStorage.getItem("profile"))
      : 1
  );
  const toggleNavs = (e, state, index) => {
    e.preventDefault();
    setTabs(index);
    localStorage.setItem("profile", index);
  };

  const { userInfo } = useSelector((state) => ({
    userInfo: state.user.userInfo,
  }));

  const [errMessage, setErrMessage] = useState(false);

  return (
    <>
      {/* Page content */}
      <Header />
      <Container className='' fluid>
        <Row>
          <Col className='order-xl-1 mx-auto' xl='8'>
            <Card className='bg-secondary shadow'>
              <CardHeader className='bg-white border-0'>
                <div className='d-flex justify-content-center'>
                  <Alert
                    toggle={() => setErrMessage(false)}
                    isOpen={errMessage ? true : false}
                    color={
                      errMessage === "변경을 완료했습니다."
                        ? "success"
                        : "warning"
                    }
                    style={{ position: "absolute", zIndex: 100000 }}>
                    {errMessage}
                  </Alert>
                </div>
                <Row className='align-items-center'>
                  <Col lg='8'>
                    <Nav
                      className='nav-fill flex-column flex-md-row'
                      id='tabs-icons-text'
                      pills
                      role='tablist'>
                      <NavItem>
                        <NavLink
                          aria-selected={tabs === 1}
                          className={classnames("mb-sm-3 mb-md-0", {
                            active: tabs === 1,
                            tabBtn: tabs !== 1,
                          })}
                          disabled={tabs === 1}
                          onClick={(e) => toggleNavs(e, "tabs", 1)}
                          role='tab'>
                          회원정보
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          aria-selected={tabs === 2}
                          className={classnames("mb-sm-3 mb-md-0", {
                            active: tabs === 2,
                            tabBtn: tabs !== 2,
                          })}
                          disabled={tabs === 2}
                          onClick={(e) => toggleNavs(e, "tabs", 2)}
                          role='tab'>
                          비밀번호 변경
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          aria-selected={tabs === 3}
                          className={classnames("mb-sm-3 mb-md-0", {
                            active: tabs === 3,
                            tabBtn: tabs !== 3,
                          })}
                          disabled={tabs === 3}
                          onClick={(e) => toggleNavs(e, "tabs", 3)}
                          role='tab'>
                          회원탈퇴
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  {/* 유저 정보 */}
                  <UserInfoChange
                    tabs={tabs}
                    userInfo={userInfo}
                    setErrMessage={setErrMessage}
                  />

                  {/* 비밀번호 변경 */}
                  <PasswordChage
                    tabs={tabs}
                    userInfo={userInfo}
                    setErrMessage={setErrMessage}
                  />

                  {/* 회원탈퇴  */}
                  <DrawUser tabs={tabs} setErrMessage={setErrMessage} />
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
});

export default Profile;
