import React from "react";

import { Table } from "reactstrap";
import { currentDate } from "../../../js/operator";
import LogoImage from "assets/img/logo2.png";

const BigOrderTable = ({
  purchaseList,
  completedStatus,
  pdfPage,
  orderInfo,
}) => {
  let tab = {
    first: false,
    second: false,
  };

  return (
    <>
      <div className='div_paper'>
        <div className='d-flex justify-content-between ttt'>
          <div className='logo-container'>
            <img src={LogoImage} alt='logo-dark' className='logo' />
          </div>
          <div className='info'>
            <p className='fw-bold'> {orderInfo.projectName} </p>
            <p>계약번호: {orderInfo.projectNumber} </p>
            <p>수요기관: {orderInfo.institution} </p>
            <p>발주번호: {orderInfo.orderNumber} </p>
            <p>등록번호: {orderInfo.registerNumber} </p>
            <p>담당자: {orderInfo.manager} </p>
            <p>납기: {currentDate(orderInfo.completedStatus.complete)} </p>
          </div>
        </div>

        <hr />

        <div className='order-info'>
          <Table>
            <thead className=''>
              <tr>
                <th scope='col' className='pdf-table-header'>
                  단계
                </th>
                <th scope='col' className='pdf-table-header'>
                  상호명
                </th>
                <th scope='col' className='pdf-table-header'>
                  품명
                </th>
                <th scope='col' className='pdf-table-header'>
                  규격
                </th>
                <th scope='col' className='pdf-table-header'>
                  단위
                </th>
                <th scope='col' className='pdf-table-header'>
                  입고량
                </th>
                <th scope='col' className='pdf-table-header'>
                  주문량
                </th>
                <th scope='col' className='pdf-table-header'>
                  완료일
                </th>
              </tr>
            </thead>
            <tbody>
              {/* 설계 */}
              <tr>
                <td className='pdf-table-body'>설계</td>
                <td className='pdf-table-body'></td>
                <td className='pdf-table-body'></td>
                <td className='pdf-table-body'></td>
                <td className='pdf-table-body'></td>
                <td className='pdf-table-body'></td>
                <td className='pdf-table-body'></td>
                <td className='pdf-table-body'>
                  {currentDate(completedStatus.design)}
                </td>
              </tr>

              {/* 원자재 발주 */}
              {purchaseList
                .filter((a, i) => i <= 30)
                .map((item, index) => {
                  if (item.tab === 0) {
                    if (!tab.first) {
                      tab.first = true;
                      return (
                        <tr key={index}>
                          <td className='pdf-table-body'>원자재 발주</td>
                          <td className='pdf-table-body'>{item.company}</td>
                          <td className='pdf-table-body'>{item.productName}</td>
                          <td className='pdf-table-body'>{item.spec}</td>
                          <td className='pdf-table-body'>{item.unit}</td>
                          <td className='pdf-table-body'>
                            {item.currentQuantity}
                          </td>
                          <td className='pdf-table-body'>
                            {item.orderQuantity}
                          </td>
                          <td className='pdf-table-body'>
                            {currentDate(completedStatus.product_order)}
                          </td>
                        </tr>
                      );
                    } else {
                      return (
                        <tr key={index}>
                          <td className='pdf-table-body'></td>
                          <td className='pdf-table-body'>{item.company}</td>
                          <td className='pdf-table-body'>{item.productName}</td>
                          <td className='pdf-table-body'>{item.spec}</td>
                          <td className='pdf-table-body'>{item.unit}</td>
                          <td className='pdf-table-body'>
                            {item.currentQuantity}
                          </td>
                          <td className='pdf-table-body'>
                            {item.orderQuantity}
                          </td>
                          <td className='pdf-table-body'></td>
                        </tr>
                      );
                    }
                  } else {
                    if (!tab.second) {
                      tab.second = true;
                      return (
                        <tr key={index}>
                          <td className='pdf-table-body'>외주가공 발주</td>
                          <td className='pdf-table-body'>{item.company}</td>
                          <td className='pdf-table-body'>{item.productName}</td>
                          <td className='pdf-table-body'>{item.spec}</td>
                          <td className='pdf-table-body'>{item.unit}</td>
                          <td className='pdf-table-body'>
                            {item.currentQuantity}
                          </td>
                          <td className='pdf-table-body'>
                            {item.orderQuantity}
                          </td>
                          <td className='pdf-table-body'>
                            {currentDate(completedStatus.product_order)}
                          </td>
                        </tr>
                      );
                    } else {
                      return (
                        <tr key={index}>
                          <td className='pdf-table-body'></td>
                          <td className='pdf-table-body'>{item.company}</td>
                          <td className='pdf-table-body'>{item.productName}</td>
                          <td className='pdf-table-body'>{item.spec}</td>
                          <td className='pdf-table-body'>{item.unit}</td>
                          <td className='pdf-table-body'>
                            {item.currentQuantity}
                          </td>
                          <td className='pdf-table-body'>
                            {item.orderQuantity}
                          </td>
                          <td className='pdf-table-body'></td>
                        </tr>
                      );
                    }
                  }
                })}
            </tbody>
          </Table>
        </div>
      </div>
      {new Array(pdfPage - 1).fill(undefined).map((t, index) => {
        return (
          <div className={`div_paper mt-3`} key={index}>
            <div className={`order-info${index + 1}`}>
              <Table>
                <thead className=''>
                  <tr>
                    <th scope='col' className='pdf-table-header'>
                      단계
                    </th>
                    <th scope='col' className='pdf-table-header'>
                      상호명
                    </th>
                    <th scope='col' className='pdf-table-header'>
                      품명
                    </th>
                    <th scope='col' className='pdf-table-header'>
                      규격
                    </th>
                    <th scope='col' className='pdf-table-header'>
                      단위
                    </th>
                    <th scope='col' className='pdf-table-header'>
                      입고량
                    </th>
                    <th scope='col' className='pdf-table-header'>
                      주문량
                    </th>
                    <th scope='col' className='pdf-table-header'>
                      완료일
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* 원자재 발주 */}
                  {purchaseList
                    .filter((a, i) => i > 30)
                    .map((item, index) => {
                      if (item.tab === 0) {
                        if (!tab.first) {
                          tab.first = true;
                          return (
                            <tr key={index}>
                              <td className='pdf-table-body'>원자재 발주</td>
                              <td className='pdf-table-body'>{item.company}</td>
                              <td className='pdf-table-body'>
                                {item.productName}
                              </td>
                              <td className='pdf-table-body'>{item.spec}</td>
                              <td className='pdf-table-body'>{item.unit}</td>
                              <td className='pdf-table-body'>
                                {item.currentQuantity}
                              </td>
                              <td className='pdf-table-body'>
                                {item.orderQuantity}
                              </td>
                              <td className='pdf-table-body'>
                                {currentDate(completedStatus.product_order)}
                              </td>
                            </tr>
                          );
                        } else {
                          return (
                            <tr key={index}>
                              <td className='pdf-table-body'></td>
                              <td className='pdf-table-body'>{item.company}</td>
                              <td className='pdf-table-body'>
                                {item.productName}
                              </td>
                              <td className='pdf-table-body'>{item.spec}</td>
                              <td className='pdf-table-body'>{item.unit}</td>
                              <td className='pdf-table-body'>
                                {item.currentQuantity}
                              </td>
                              <td className='pdf-table-body'>
                                {item.orderQuantity}
                              </td>
                              <td className='pdf-table-body'></td>
                            </tr>
                          );
                        }
                      } else {
                        if (!tab.second) {
                          tab.second = true;
                          return (
                            <tr key={index}>
                              <td className='pdf-table-body'>외주가공 발주</td>
                              <td className='pdf-table-body'>{item.company}</td>
                              <td className='pdf-table-body'>
                                {item.productName}
                              </td>
                              <td className='pdf-table-body'>{item.spec}</td>
                              <td className='pdf-table-body'>{item.unit}</td>
                              <td className='pdf-table-body'>
                                {item.currentQuantity}
                              </td>
                              <td className='pdf-table-body'>
                                {item.orderQuantity}
                              </td>
                              <td className='pdf-table-body'>
                                {currentDate(completedStatus.product_order)}
                              </td>
                            </tr>
                          );
                        } else {
                          return (
                            <tr key={index}>
                              <td className='pdf-table-body'></td>
                              <td className='pdf-table-body'>{item.company}</td>
                              <td className='pdf-table-body'>
                                {item.productName}
                              </td>
                              <td className='pdf-table-body'>{item.spec}</td>
                              <td className='pdf-table-body'>{item.unit}</td>
                              <td className='pdf-table-body'>
                                {item.currentQuantity}
                              </td>
                              <td className='pdf-table-body'>
                                {item.orderQuantity}
                              </td>
                              <td className='pdf-table-body'></td>
                            </tr>
                          );
                        }
                      }
                    })}

                  {/* 입고 */}
                  <tr>
                    <td className='pdf-table-body'>입고</td>
                    <td className='pdf-table-body'></td>
                    <td className='pdf-table-body'></td>
                    <td className='pdf-table-body'></td>
                    <td className='pdf-table-body'></td>
                    <td className='pdf-table-body'></td>
                    <td className='pdf-table-body'></td>
                    <td className='pdf-table-body'>
                      {currentDate(completedStatus.product_purchase)}
                    </td>
                  </tr>

                  {/* 제작 */}
                  <tr>
                    <td className='pdf-table-body'>제작</td>
                    <td className='pdf-table-body'></td>
                    <td className='pdf-table-body'></td>
                    <td className='pdf-table-body'></td>
                    <td className='pdf-table-body'></td>
                    <td className='pdf-table-body'></td>
                    <td className='pdf-table-body'></td>
                    <td className='pdf-table-body'>
                      {currentDate(completedStatus.assemble.three)}
                    </td>
                  </tr>

                  {/* 검사 */}
                  <tr>
                    <td className='pdf-table-body'>검사</td>
                    <td className='pdf-table-body'></td>
                    <td className='pdf-table-body'></td>
                    <td className='pdf-table-body'></td>
                    <td className='pdf-table-body'></td>
                    <td className='pdf-table-body'></td>
                    <td className='pdf-table-body'></td>
                    <td className='pdf-table-body'>
                      {currentDate(completedStatus.confirm.four)}
                    </td>
                  </tr>

                  {/* 시운전 */}
                  {/* <tr>
                    <td className='pdf-table-body'>시운전</td>
                    <td className='pdf-table-body'></td>
                    <td className='pdf-table-body'></td>
                    <td className='pdf-table-body'></td>
                    <td className='pdf-table-body'></td>
                    <td className='pdf-table-body'></td>
                    <td className='pdf-table-body'></td>
                    <td className='pdf-table-body'>
                      {currentDate(completedStatus.drive.two)}
                    </td> */}
                  {/* </tr> */}
                </tbody>
              </Table>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default BigOrderTable;
