/** @format */

import React from "react";

import { Button, Modal } from "reactstrap";

const NextStep = ({ toggleModal, ismodal, order, nextstepHanddler }) => {
  return (
    <>
      {/* Modal */}
      <Modal
        className='modal-dialog-centered'
        isOpen={ismodal}
        toggle={() => toggleModal(false)}>
        <div className='modal-header'>
          <h5 className='modal-title' id='exampleModalLabel'>
            다음단계
          </h5>
          <button
            aria-label='Close'
            className='close'
            data-dismiss='modal'
            type='button'
            onClick={() => toggleModal(false)}>
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className='modal-body text-center'>
          현재 단계를 완료하고 다음 단계로 이동하시겠습니까?
        </div>
        <div className='modal-footer d-flex justify-content-center'>
          <Button
            color='primary'
            type='button'
            onClick={() => {
              nextstepHanddler(order.id);
            }}>
            예
          </Button>
          <Button
            color='secondary'
            data-dismiss='modal'
            type='button'
            onClick={() => toggleModal(false)}>
            아니오
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default NextStep;
