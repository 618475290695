/** @format */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";

// 기본정보 0
// 설계 1
// 가공 2
// wiring 3
// 조림 4
// 검사 5
// 출고 6

// activeTab : 각 단계마다 탭이 있는데 그것의 index

const initState = {
  orderList: [],
  activeTab: localStorage.getItem("activeTab")
    ? localStorage.getItem("activeTab")
    : "0",
};

const order = createSlice({
  name: "orderInfo",
  initialState: { ...initState },
  reducers: {
    // 추가
    addOrder(state, action) {
      const currenDate = new Date().getTime() - 1000 * 60 * 60 * 24;

      // 기한 초과 확인
      if (currenDate > action.payload.dueDate) {
        return {
          ...state,
          orderList: [
            { ...action.payload, isOver: true, clientStatus: 0 },
            ...state.orderList,
          ],
        };
      } else {
        return {
          ...state,
          orderList: [
            { ...action.payload, isOver: false, clientStatus: 0 },
            ...state.orderList,
          ],
        };
      }
    },
    // 수정
    updateOrder(state, action) {
      const currenDate = new Date().getTime() - 1000 * 60 * 60 * 24;
      console.log(action.payload);
      const newTable = state.orderList.map((item) => {
        if (item.id !== action.payload.id) {
          return item;
        }

        const { completedStatus, dueDate } = action.payload;

        if (completedStatus.complete) {
          if (dueDate < completedStatus.complete - 1000 * 60 * 60 * 24) {
            return { ...action.payload, isOver: true };
          } else {
            return { ...action.payload, isOver: false };
          }
        } else {
          if (currenDate > dueDate) {
            return { ...action.payload, isOver: true };
          } else {
            return { ...action.payload, isOver: false };
          }
        }
        // 기한 초과 확인
        // if (currenDate > action.payload.dueDate) {
        //   return { ...action.payload, isOver: true };
        // } else {
        //   return { ...action.payload, isOver: false };
        // }
      });

      return { ...state, orderList: [...newTable] };
    },
    // 삭제
    deleteOrder(state, action) {
      return {
        ...state,
        orderList: state.orderList.filter(
          (item) => item.id !== action.payload.id
        ),
      };
    },

    // 주문 리스트 저장
    setOrder(state, action) {
      return { ...state, orderList: action.payload };
    },

    // 사용자가 원하는 카드 볼 수 있도록 변경
    setTargetStatus(state, action) {
      const data = [...state.orderList].map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            clientStatus: action.payload.clientStatus,
          };
        }
        return item;
      });

      return { ...state, orderList: [...data] };
    },
    // active tab
    setActiveTab(state, action) {
      return { ...state, activeTab: action.payload };
    },
    // 완료 수정 (버튼 눌렀을 때 (assemble, confirm, drive))
    setCompletedStaus(state, action) {
      const newData = _.cloneDeep(state.orderList);

      for (let i = 0; i < newData.length; i++) {
        const item = newData[i];
        if (item.id === action.payload.id) {
          item.completedStatus = action.payload.completedStatus;
          break;
        }
      }
      return { ...state, orderList: [...newData] };
    },
  },
});

export const action = order.actions;
export default order.reducer;
