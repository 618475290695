/** @format */

import React from "react";

import { useState } from "react";

// reactstrap
import { Input, InputGroup, Button } from "reactstrap";
import { useEffect } from "react";
import { statusData } from "pages/js/orderData";

const OrderSearch = ({
  handleSearch,
  handlePageIndex,
  searchType,
  pageIndex,
}) => {
  // const [searchType, handleSearch] = useState({
  //   inputType: "projectName",
  //   input: "",
  // });

  // const storedPageIndex = localStorage.getItem("mes_page")
  //   ? Number(JSON.parse(localStorage.getItem("mes_page")))
  //   : 5;

  // const [pageIndex, setPageIndex] = useState(storedPageIndex);

  // useEffect(() => {
  //   handleSearch({ ...searchType });
  // }, [searchType]);

  return (
    <>
      <div className='d-flex justify-content-between mt-3 '>
        <div className='d-flex'>
          {/* selector */}
          <InputGroup className='input-group-alternative search-select'>
            <Input
              type='select'
              value={searchType.inputType}
              onChange={(e) => {
                if (e.target.value === "status") {
                  // 전체 보기
                  handleSearch({ input: -1, inputType: e.target.value });
                } else {
                  handleSearch({ input: "", inputType: e.target.value });
                  // if (searchType.input == -1 || searchType.input == 7) {
                  // } else {
                  //   handleSearch({ ...searchType, inputType: e.target.value });
                  // }
                }
              }}>
              <option value={"projectName"}>계약건명</option>
              <option value={"institution"}>수요기관</option>
              <option value={"projectNumber"}>계약번호</option>
              <option value={"status"}>상태</option>
            </Input>
          </InputGroup>

          {/* input or selector */}
          <InputGroup className='input-group-alternative search-input mx-4'>
            {searchType.inputType !== "status" ? (
              <Input
                placeholder='input'
                type='text'
                value={searchType.input}
                onChange={(e) =>
                  handleSearch({ ...searchType, input: e.target.value })
                }
              />
            ) : (
              <Input
                type='select'
                value={searchType.input}
                onChange={(e) =>
                  handleSearch({ ...searchType, input: e.target.value })
                }>
                <option value={-1}>전체</option>
                {statusData.map((item, index) => {
                  return (
                    <option key={index} value={index}>
                      {item.title}
                    </option>
                  );
                })}
              </Input>
            )}
          </InputGroup>

          {/* 버튼 */}
          <Button
            className='default-color'
            onClick={() => handleSearch({ ...searchType })}>
            <i className='fas fa-search' />
          </Button>
        </div>
        <div className=' page-index'>
          <span>페이지당 목록</span>
          <InputGroup className='input-group-alternative search-index ml-3'>
            <Input
              type='select'
              value={pageIndex}
              onChange={(e) => handlePageIndex(e.target.value)}>
              <option value='5'>5</option>
              <option value='10'>10</option>
              <option value='20'>20</option>
              <option value='50'>50</option>
              <option value='100'>100</option>
            </Input>
          </InputGroup>
          {/* <Button
            className='default-color'
            onClick={() => handlePageIndex(pageIndex)}>
            변경
          </Button> */}
        </div>
      </div>
    </>
  );
};

export default OrderSearch;
