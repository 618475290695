import React, { useState, useEffect } from "react";
import Autocomplete from "react-autocomplete";

const AutoCompleteInput = ({ productOptions, setFormData, formData }) => {
  const [selected, setSelected] = useState(formData?.productName);

  useEffect(() => {
    setFormData({
      ...formData,
      productName: selected,
    });
  }, [selected]);

  return (
    <div id='auto-complete'>
      <Autocomplete
        getItemValue={(item) => item}
        items={Object.keys(productOptions).filter((item) => {
          if (
            item.toUpperCase().includes(formData?.productName.toUpperCase())
          ) {
            return true;
          }
          return false;
        })}
        wrapperStyle={{
          position: "relative",
        }}
        menuStyle={{
          top: "calc(1.5em + 1.25rem + 2px)",
          left: 0,
          position: "absolute",
        }}
        renderItem={(item, isHighlighted) => (
          <option
            key={item}
            className='auto-item pl-2'
            style={{
              background: isHighlighted ? "lightgray" : "white",
              height: "calc(1.5em + 1.25rem + 2px)",
            }}>
            {item}
          </option>
        )}
        value={formData?.productName}
        onChange={(e) => setSelected(e.target.value)}
        onSelect={(val) => setSelected(val)}
        inputProps={{
          placeholder: "품명",
          maxLength: 30,
        }}
      />
    </div>
  );
};

export default AutoCompleteInput;
