/** @format */

import axios from "axios";

class DesignAPI {
  constructor() {
    this.URL = "";
    this.get = async (url, config) => {
      const res = await axios.get(url, { ...config });
      return res.data;
    };
    this.post = async (url, data, config = {}) => {
      const res = await axios.post(url, data, { ...config });
      return res.data;
    };
    this.put = async (url, data, config = {}) => {
      const res = await axios.put(url, data, { ...config });
      return res.data;
    };
    this.delete = async (url, config) => {
      const res = await axios.delete(url, { ...config });
      return res.data;
    };
  }

  // Design 가져오기
  async requestReadDesign(config) {
    const url = "/api/main/design";
    return this.get(url, config);
  }

  // Design 만들기
  async requestCreateDesign(data, config) {
    const url = "/api/main/design";
    return this.post(url, data, config);
  }

  // // partlist 삭제
  // async requestDeletePartList(data) {
  //   const url = "/api/main/partlist";
  //   console.log(data);
  //   return this.delete(url, data);
  // }

  // // partlist 수정
  // async requestUpdatePartList(data, config) {
  //   const url = "/api/main/partlist";
  //   console.log(data);
  //   return this.put(url, data, config);
  // }
}

export default new DesignAPI();
