/** @format */

import _ from "lodash";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";

// reactstrap
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  FormFeedback,
} from "reactstrap";
import purchaseApi from "services/purchase";
import AutoCompleteInput from "./AutoCompleteInput";
import useIsMount from "../../../hooks/useIsMount";

import InstitutionAutoComplete from "./InstitutionAutoComplete";

const UpdatePurchaseModal = ({
  modalOpen,
  toggleModal,
  onSubmit,
  targetPL,
  activeTab,
}) => {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const isMount = useIsMount();

  const [isBtn, setIsBtn] = useState(false);

  const [formData, setFormData] = useState({
    tab: activeTab,
    company: "",
    productName: "",
    spec: "",
    unit: "",
    orderQuantity: "",
    currentQuantity: "",
    memo: "",
  });

  useEffect(() => {
    if (!_.isEmpty(targetPL)) {
      setFormData({
        ...targetPL,
      });
    }
  }, [targetPL]);

  const handleSubmit = () => {
    onSubmit({ ...formData });
    setFormData({
      tab: activeTab,
      company: "",
      productName: "",
      spec: "",
      unit: "",
      orderQuantity: "",
      currentQuantity: "",
      memo: "",
    });
  };

  const [numberErr, setNumberErr] = useState(false);
  const handleNumber = (e, target) => {
    let replaceNumber = Number(e.target.value.replace(/[^0-9]/g, ""));

    if (replaceNumber >= 9007199254740991) {
      setNumberErr("수량을 확인해주세요.");
    } else {
      setNumberErr(false);
    }

    setFormData({
      ...formData,
      [target]: replaceNumber === 0 ? "" : replaceNumber,
    });
  };

  // 상호명 고르는 리스트 가져오기
  const [companyOptions, setCompanyOptions] = useState([""]);
  const [productOptions, setProductOptions] = useState([""]);

  useEffect(() => {
    purchaseApi
      .requestReadIndexList({
        params: {
          orderID: id,
          company: "total",
        },
      })
      .then((res) => {
        if (isMount.current) {
          setFormData({
            ...formData,
            company: res.companyList[0],
          });
          setCompanyOptions(res.companyList);
          setProductOptions(res.productList);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // 규격, 단위, 단가 자동입력
  useEffect(() => {
    if (!_.isEmpty(productOptions[formData.productName])) {
      const { spec, unit, unitPrice } = productOptions[formData.productName];

      setFormData({
        ...formData,
        spec,
        unit,
        unitPrice,
        money: formData.quantity * unitPrice,
      });
    }
  }, [formData.productName]);

  // 버튼 활성화
  useEffect(() => {
    const { tab, company, productName, spec, unit, orderQuantity } = formData;
    if (orderQuantity >= 9007199254740991) {
      setIsBtn(true);
      return;
    }

    console.log(tab, company, productName, spec, unit, orderQuantity);

    if (company && productName && spec && unit && orderQuantity) {
      setIsBtn(false);
    } else {
      setIsBtn(true);
    }
  }, [formData]);

  return (
    <>
      <Modal size='l' isOpen={modalOpen} toggle={() => toggleModal(false)}>
        <ModalHeader toggle={() => toggleModal(false)} tag='h3'>
          {targetPL.productName}
        </ModalHeader>
        <ModalBody>
          <div className='orderform'>
            <Row>
              <Col xs={12}>
                <Form role='form'>
                  {/* 상호명 */}
                  <FormGroup className='mb-3'>
                    <label
                      className='form-control-label'
                      htmlFor='input-username'>
                      * 상호명
                    </label>
                    <InstitutionAutoComplete
                      companyOptions={companyOptions}
                      setFormData={setFormData}
                      formData={formData}
                    />

                    {/* <Input
                      type='select'
                      value={formData.company}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          company: e.target.value,
                        });
                      }}>
                      {companyOptions.map((item, index) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </Input> */}
                  </FormGroup>

                  <Row>
                    <Col md={6}>
                      {/* 품명 */}
                      <FormGroup className='mb-3'>
                        <label
                          className='form-control-label'
                          htmlFor='input-username'>
                          * 품명
                        </label>
                        {/* <Input
                      placeholder='품명'
                      type='text'
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          productName: e.target.value,
                        })
                      }
                      value={formData.productName}
                    /> */}
                        <AutoCompleteInput
                          productOptions={productOptions}
                          setFormData={setFormData}
                          formData={formData}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      {/* 규격 */}
                      <FormGroup className='mb-3'>
                        <label
                          className='form-control-label'
                          htmlFor='input-username'>
                          * 규격
                        </label>
                        <Input
                          placeholder='규격'
                          type='text'
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              spec: e.target.value,
                            })
                          }
                          value={formData.spec}
                          maxLength={20}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      {/* 단위 */}
                      <FormGroup className='mb-3'>
                        <label
                          className='form-control-label'
                          htmlFor='input-username'>
                          * 단위
                        </label>
                        <Input
                          placeholder='단위'
                          type='text'
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              unit: e.target.value,
                            })
                          }
                          value={formData.unit}
                          maxLength={20}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      {/* 수량 */}
                      <FormGroup
                        className={`mb-3 ${numberErr ? "has-danger" : ""}`}>
                        <label
                          className='form-control-label'
                          htmlFor='input-username'>
                          * 수량
                        </label>
                        <Input
                          invalid={numberErr ? true : false}
                          placeholder='수량'
                          type='text'
                          onChange={(e) => handleNumber(e, "orderQuantity")}
                          value={formData.orderQuantity}
                        />
                        <FormFeedback className='feedbackform'>
                          {numberErr}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>

                  <div className='custom-control custom-control-alternative custom-checkbox'>
                    <input
                      className='custom-control-input'
                      id=' customCheckLogin'
                      type='checkbox'
                    />
                  </div>
                  <div className='text-center'>
                    <Button
                      className='mb-4'
                      color='primary'
                      type='button'
                      disabled={isBtn}
                      onClick={() => handleSubmit()}>
                      수정
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default UpdatePurchaseModal;
