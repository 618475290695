import { Container } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

const NotFound = () => {
  const history = useHistory();

  useEffect(() => {
    history.push("/");
  }, []);

  return (
    <Container fluid>
      <h1>404 not found</h1>
    </Container>
  );
};

export default NotFound;
