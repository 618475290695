import React, { useState, useEffect } from "react";
import Autocomplete from "react-autocomplete";
import { Input } from "reactstrap";

const InstitutionAutoComplete = ({ companyOptions, setFormData, formData }) => {
  const [selected, setSelected] = useState(formData?.company);
  useEffect(() => {
    setFormData({
      ...formData,
      company: selected,
    });
  }, [selected]);

  return (
    <div id='auto-complete'>
      <Autocomplete
        getItemValue={(item) => item}
        items={companyOptions.filter((item) => {
          if (item.toUpperCase().includes(formData?.company.toUpperCase())) {
            return true;
          }
          return false;
        })}
        wrapperStyle={{
          position: "relative",
        }}
        menuStyle={{
          top: "calc(1.5em + 1.25rem + 2px)",
          left: 0,
          position: "absolute",
        }}
        renderItem={(item, isHighlighted) => (
          <option
            key={item}
            className='auto-item pl-2'
            style={{
              background: isHighlighted ? "lightgray" : "white",
              height: "calc(1.5em + 1.25rem + 2px)",
            }}>
            {item}
          </option>
        )}
        value={formData?.company}
        onChange={(e) => {
          setFormData({
            ...formData,
            company: e.target.value,
          });
        }}
        onSelect={(val) => setSelected(val)}
        inputProps={{
          placeholder: "품명",
        }}
      />
    </div>
  );
};

export default InstitutionAutoComplete;
