import React, { useState, useEffect, memo } from "react";
import { Input, Card, CardBody, Alert } from "reactstrap";
import _ from "lodash";

// 제작, 검사 단계의 생산, 불량 폼

const ProduceTable = ({
  isNext,
  userRequest,
  handleChange,
  handleRequestApi,
  orderProduceTableData,
  productOrder,
  productList,
  setErrMessage,
  errMessage,
  setProductOrder,
  targetKey,
  handleChangeErrorProduct,
}) => {
  const title =
    targetKey === "produceQuantity" ? "생산 수량 체크" : "불량 수량 체크";

  useEffect(() => {
    if (userRequest) {
      handleRequestApi();
    }
  }, [userRequest]);

  useEffect(() => {
    if (isNext) {
      handleRequestApi();
    }
  }, [isNext]);

  useEffect(() => {
    orderProduceTableData();
  }, [productOrder]);

  function calculatorTotal(item) {
    const data = {
      value: 0,
      total: 0,
    };

    const {
      confirmData: { one, two, three, four, five, six },
    } = item;

    console.log(item);

    data.value += one.value === "" ? 0 : one.value;
    data.value += two.value === "" ? 0 : two.value;
    data.value += three.value === "" ? 0 : three.value;
    data.value += four.value === "" ? 0 : four.value;
    data.value += five.value === "" ? 0 : five.value;
    data.value += six.value === "" ? 0 : six.value;

    data.total += one.total === "" ? 0 : one.total;
    data.total += two.total === "" ? 0 : two.total;
    data.total += three.total === "" ? 0 : three.total;
    data.total += four.total === "" ? 0 : four.total;
    data.total += five.total === "" ? 0 : five.total;
    data.total += six.total === "" ? 0 : six.total;

    return data;
  }

  const handleErrorProduct = (id, inputType, value) => {
    // errorTotal
    // errorQuantity
    console.log("qwer");
    handleChangeErrorProduct(id, inputType, value);
  };

  return (
    <>
      <hr />

      <div className='d-flex justify-content-between mb-4'>
        <div className='d-flex align-items-center'>
          <span>
            * {title} &nbsp;&nbsp;{" "}
            {targetKey === "produceQuantity"
              ? "( 수량 / 목표수량 )"
              : "( 총 불량수량 / 총 검수수량 )"}{" "}
          </span>
          <Input
            type='select'
            bsSize='sm'
            className='ml-3'
            style={{ width: "100px" }}
            value={productOrder}
            onChange={(e) => setProductOrder(e.target.value)}
          >
            <option value={"asc"}>오름차순</option>
            <option value={"desc"}>내림차순</option>
          </Input>
        </div>
        {/* <Button color='success' size='sm'>
          저장
        </Button> */}
      </div>
      <div className='assemble-quantity mt-3'>
        {productList.map((item, index) => {
          if (title === "불량 수량 체크") {
            const { errorTotal, errorQuantity, id } = item;
            // const { value, total } = calculatorTotal(item);

            return (
              <Card key={item.name} className='p-3 assemble-quantity-card'>
                <label className='form-control-label' htmlFor='input-username'>
                  * {item.name}
                </label>
                <div className='d-flex align-items-center'>
                  <Input
                    onChange={(e) => {
                      handleErrorProduct(id, "errorQuantity", e.target.value);
                    }}
                    placeholder='총 불량수량'
                    type='text'
                    value={errorQuantity}
                    className='text-right'
                    maxLength={8}
                  />
                  <span className='mx-2'>/</span>
                  {
                    <Input
                      onChange={(e) => {
                        handleErrorProduct(id, "errorTotal", e.target.value);
                      }}
                      placeholder='총 검수수량'
                      type='text'
                      value={errorTotal}
                      className='text-right'
                    />
                  }
                </div>
              </Card>
            );
          } else {
            return (
              <Card key={item.name} className='p-3 assemble-quantity-card'>
                <label className='form-control-label' htmlFor='input-username'>
                  * {item.name}
                </label>
                <div className='d-flex align-items-center'>
                  <Input
                    onChange={(e) => {
                      handleChange(e, item, "produceQuantity");
                    }}
                    placeholder='수량'
                    type='text'
                    className='text-right'
                    value={item.produceQuantity}
                    maxLength={8}
                  />
                  <span className='mx-2'>/</span>
                  {targetKey === "produceQuantity" ? (
                    <Input
                      onChange={(e) => handleChange(e, item, "targetQuantity")}
                      placeholder='수량'
                      type='text'
                      value={item.targetQuantity}
                      className='text-right'
                    />
                  ) : (
                    <Input
                      onChange={(e) => console.log()}
                      placeholder='수량'
                      type='text'
                      disabled
                      value={item.produceQuantity}
                      className='text-right'
                    />
                  )}
                </div>
              </Card>
            );
          }
        })}
      </div>
    </>
  );
};

export default memo(ProduceTable);
