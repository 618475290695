/** @format */

import { useEffect, useState, useCallback } from "react";
import { setCompletedStaus } from "store/actions/order";
import {
  Card,
  CardBody,
  Button,
  Alert,
  UncontrolledPopover,
  PopoverBody,
} from "reactstrap";
import { DatePicker, TimePicker } from "antd";

//
import useNextStep from "pages/hooks/useNextStep";
import ProduceTable from "../../components/ProduceTable";

//
import { currentDatehangle } from "pages/js/operator";
import orderApi from "services/order";
import { useDispatch } from "react-redux";
import moment from "moment";
import _ from "lodash";
import CheckErrorData from "./CheckErrorData";

const ErrorValue = "errorQuantity";
const initStep = {
  one: "육안검사",
  two: "성능검사",
  three: "치수검사",
  four: "샘플링검사",
};
const initValue = {
  one: {
    isUpdate: false,
    value: "",
    date: "",
    time: "",
  },
  two: {
    isUpdate: false,
    value: "",
    date: "",
    time: "",
  },
  three: {
    isUpdate: false,
    value: "",
    date: "",
    time: "",
  },
  four: {
    isUpdate: false,
    value: "",
    date: "",
    time: "",
  },
};

// rgb(80, 150, 255)

const ConfirmTab = ({
  isNext,
  order,
  handleSubmit,
  handleRequestEnd,
  setUserActive,
  userRequest,
  setStepListConfirm,
  isStepConfirm,
  setIsStepConfirm,
  stepListConfirm,
  setUpdateModal,
}) => {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");

  const dispatch = useDispatch();
  const [isuseNext, setIsuseNext] = useState(false);

  const otherOneTouch = useCallback((event) => {
    document.activeElement.blur(); // 현재 활성화된 element의 blur 이벤트 호출
  }, []);

  useNextStep({ isuseNext, order, handleSubmit, handleRequestEnd });

  const [currentStep, setCurrentStep] = useState(0);
  const [stepList, setStepList] = useState(initValue);
  const [updateForm, setUpdateForm] = useState(initValue);

  const handleClick = async (item, value) => {
    const { completedStatus } = _.cloneDeep(order);
    if (!value) {
      completedStatus.confirm[item] = new Date().getTime();

      otherOneTouch();
    } else {
      completedStatus.confirm[item] = "";
    }

    try {
      await orderApi.requestCompletedStaus(
        { completedStatus },
        {
          params: {
            orderID: id,
          },
        }
      );
      setStepList({
        ...stepList,
        [item]: {
          value: completedStatus.confirm[item],
          isUpdate: false,
        },
      });
      dispatch(setCompletedStaus({ id: order.id, completedStatus }));
      setCurrentStep(currentStep + 1);
    } catch (err) {
      console.log(err);
    }
  };

  // 가져오기
  useEffect(() => {
    const {
      completedStatus: { confirm },
    } = order;

    for (let [index, value] of Object.values(confirm).entries()) {
      if (!value) {
        setCurrentStep(index);
        break;
      }
    }
    const newValue = { ...initValue };

    for (let [key, value] of Object.entries(confirm)) {
      newValue[key] = {
        isUpdate: false,
        value: value,
        date: value,
        time: value,
      };
    }
    setStepList({
      ...newValue,
    });

    setUpdateForm({
      ...newValue,
    });
  }, [order, order.clientStatus]);

  // 테이블
  const [errMessage, setErrMessage] = useState(false);
  const [productOrder, setProductOrder] = useState("asc");
  const [productList, setProductList] = useState([]);

  useEffect(() => {
    orderApi
      .requestReadProductList({
        params: {
          orderID: id,
        },
      })
      .then((res) => {
        console.log(res);
        setProductList([
          ...res.map((item, index) => {
            // const confirmData = JSON.parse(item.confirmData);
            const confirmData = item.confirmData;
            if (_.isEmpty(item.confirmData)) {
              return {
                ...item,
                confirmData: {
                  one: {
                    value: 0,
                    total: 0,
                  },
                  two: {
                    value: 0,
                    total: 0,
                  },
                  three: {
                    value: 0,
                    total: 0,
                  },
                  four: {
                    value: 0,
                    total: 0,
                  },
                  five: {
                    value: 0,
                    total: 0,
                  },
                  six: {
                    value: 0,
                    total: 0,
                  },
                },
              };
            } else {
              return { ...item, confirmData };
            }
          }),
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // const handleChange = (e, item) => {
  //   const newData = _.cloneDeep(productList);
  //   const number = e.target.value.replace(/[^0-9]/g, "");
  //   for (let i = 0; i < newData.length; i++) {
  //     const newItem = newData[i];
  //     if (newItem.id === item.id) {
  //       newItem[ErrorValue] = number === "" ? "" : Number(number);
  //       break;
  //     }
  //   }

  //   setProductList(newData);
  //   setUserActive(true);
  // };

  useEffect(() => {
    if (isStepConfirm) {
      setIsStepConfirm(false);
      setErrMessage(`${stepListConfirm}단계를 완료해주세요.`);
    }
  }, [isStepConfirm]);

  useEffect(() => {
    if (order.status !== 5) {
      for (let [key, value] of Object.entries(stepList)) {
        if (!value["date"] || !value["time"] || !value["value"]) {
          // setErrMessage(`${initStep[key]} 단계를 완료해 주세요.`);
          // handleRequestEnd();
          setStepListConfirm(initStep[key]);
          return;
        }
      }
      setStepListConfirm(false);
    }
  }, [stepList]);

  const handleRequestApi = async () => {
    for (let item of productList) {
      const confirmData = item.confirmData;

      for (let [key, data] of Object.entries(confirmData)) {
        if (data.value === "") {
          setErrMessage(
            `${item.name} / ${initStep[key]} : 불량수량 값을 입력해 주세요.`
          );
          setUpdateModal({
            name: "",
            value: false,
          });
          return;
        }
        if (data.total === "") {
          setErrMessage(
            `${item.name} / ${initStep[key]} : 검수수량 값을 입력해 주세요.`
          );
          setUpdateModal({
            name: "",
            value: false,
          });
          return;
        }

        if (data.value > data.total) {
          setErrMessage(
            `${item.name} / ${initStep[key]} : 불량수량의 값이 검사수량 값보다 클 수 없습니다.`
          );
          setUpdateModal({
            name: "",
            value: false,
          });
          return;
        }
      }
    }

    try {
      await orderApi.requestUpdateProductList(
        { productList },
        {
          params: {
            orderID: id,
          },
        }
      );
    } catch (err) {
      console.log(err);
    }

    console.log(stepList);

    setErrMessage(false);

    if (!isNext) {
      handleRequestEnd();
    } else {
      for (let [key, value] of Object.entries(stepList)) {
        if (!value["date"] || !value["time"] || !value["value"]) {
          console.log(key, value);
          setErrMessage(`${initStep[key]} 단계를 완료해 주세요.`);
          handleRequestEnd();
          return;
        }
      }
      setIsuseNext(true);
    }
  };

  const orderProduceTableData = () => {
    if (productOrder === "asc") {
      const newData = _.cloneDeep(productList);
      for (let i = 0; i < newData.length; i++) {
        for (let j = 0; j < newData.length; j++) {
          if (newData[i].name < newData[j].name) {
            const temp = {
              ...newData[i],
            };
            newData[i] = newData[j];
            newData[j] = temp;
          }
        }
      }
      setProductList([...newData]);
    } else {
      const newData = _.cloneDeep(productList);
      for (let i = 0; i < newData.length; i++) {
        for (let j = 0; j < newData.length; j++) {
          if (newData[i].name > newData[j].name) {
            const temp = {
              ...newData[i],
            };
            newData[i] = newData[j];
            newData[j] = temp;
          }
        }
      }

      setProductList([...newData]);
    }
  };

  /// 단계 수정
  const startUpdate = (index) => {
    setStepList({
      ...stepList,
      [index]: {
        ...stepList[index],
        isUpdate: true,
      },
    });
  };

  const saveUpdate = async (index) => {
    let { date, time } = updateForm[index];

    date = new Date(date);
    time = new Date(time);

    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const datetime = ("0" + date.getDate()).slice(-2);

    const hours = ("0" + time.getHours()).slice(-2);
    const minutes = ("0" + time.getMinutes()).slice(-2);

    let newDate = `${year}/${month}/${datetime} ${hours}:${minutes}:00`;

    newDate = new Date(newDate).getTime();

    const { completedStatus } = _.cloneDeep(order);
    completedStatus.confirm[index] = newDate;

    try {
      await orderApi.requestCompletedStaus(
        { completedStatus },
        {
          params: {
            orderID: id,
          },
        }
      );
      dispatch(setCompletedStaus({ id: order.id, completedStatus }));
    } catch (err) {
      console.log(err);
    }

    setStepList({
      ...stepList,
      [index]: {
        isUpdate: false,
        value: newDate,
        time,
        date,
      },
    });
  };

  const closeUpdate = (index) => {
    setStepList({
      ...stepList,
      [index]: {
        ...stepList[index],
        isUpdate: false,
      },
    });
    setUpdateForm({
      ...updateForm,
      [index]: {
        ...stepList[index],
      },
    });
  };

  const [userInputQuantity, setUserInputQuantity] = useState({
    value: 0,
    total: 0,
  });

  const changeErrorForm = (number, valueType, target, confirmType, isAuto) => {
    // const findIndex = _.findIndex(productForm, { id: target.id })
    setUserActive(true);

    const newData = _.cloneDeep(productList);

    for (let i = 0; i < newData.length; i++) {
      const item = newData[i];
      if (item.id === target.id) {
        const data = {
          ...item,
          confirmData: {
            ...item.confirmData,
            [confirmType]: {
              ...item.confirmData[confirmType],
              [valueType]: number,
            },
          },
        };
        newData[i] = data;
        break;
      }
    }

    if (isAuto) {
      const data = {
        value: 0,
        total: 0,
      };

      for (let item of newData) {
        const {
          confirmData: { one, two, three, four, five, six },
        } = item;

        let value = 0;
        let total = 0;

        value += one.value === "" ? 0 : one.value;
        value += two.value === "" ? 0 : two.value;
        value += three.value === "" ? 0 : three.value;
        value += four.value === "" ? 0 : four.value;
        value += five.value === "" ? 0 : five.value;
        value += six.value === "" ? 0 : six.value;

        // total += one.total === "" ? 0 : one.total;
        // total += two.total === "" ? 0 : two.total;
        // total += three.total === "" ? 0 : three.total;
        // total += four.total === "" ? 0 : four.total;
        // total += five.total === "" ? 0 : five.total;
        // total += six.total === "" ? 0 : six.total;

        for (let i = 0; i < newData.length; i++) {
          const newItem = newData[i];
          if (newItem.id === item.id) {
            newItem[ErrorValue] = value;
            // newItem["errorTotal"] = total;
            break;
          }
        }
      }
    }

    setProductList(newData);
  };

  const handleChangeErrorProduct = (id, inputType, value) => {
    setUserActive(true);

    setProductList((pre) =>
      pre.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            [inputType]: value,
          };
        }
        return item;
      })
    );
  };

  console.log(productList);

  return (
    <>
      <Card className='mb-5'>
        <div className='d-flex justify-content-center'>
          <Alert
            toggle={() => setErrMessage(false)}
            isOpen={errMessage ? true : false}
            color='warning'
            style={{ position: "absolute", zIndex: 1000 }}
          >
            <span>{errMessage}</span>
          </Alert>
        </div>
        <CardBody style={{ minHeight: "350px" }}>
          <div className='confirm-status mt-4'>
            {/* 육안검사 */}
            <Card className='confirm-status-card'>
              <CardBody>
                {stepList?.one.isUpdate ? (
                  <div className='picker-container'>
                    <div className='d-flex justify-content-end mb-3'>
                      <i
                        className='fas fa-save'
                        onClick={() => saveUpdate("one")}
                      ></i>
                      <i
                        className='fas fa-window-close'
                        onClick={() => closeUpdate("one")}
                      ></i>
                    </div>
                    <div className='picker'>
                      <DatePicker
                        inputReadOnly={true}
                        clearIcon={false}
                        value={moment(updateForm?.one.date)}
                        className='mr-2'
                        onChange={(e) => {
                          console.log(e._d);
                          setUpdateForm({
                            ...updateForm,
                            one: {
                              ...updateForm.one,
                              date: e._d.getTime(),
                            },
                          });
                        }}
                      />
                      <TimePicker
                        clearIcon={false}
                        inputReadOnly={true}
                        format={"HH:mm"}
                        value={moment(updateForm?.one.time)}
                        onSelect={(value, dateString) => {
                          setUpdateForm({
                            ...updateForm,
                            one: {
                              ...updateForm.one,
                              time: value._d.getTime(),
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div className='step-conteiner'>
                    <Button
                      // color='primary'
                      style={{
                        backgroundColor: "rgb(80, 150, 255)",
                        color: "white",
                        borderRadius: "15px",
                      }}
                      // disabled={stepList?.one.value ? true : false}
                      id={`one`}
                      onClick={(e) => {
                        if (!stepList?.one.value) {
                          handleClick("one", stepList?.one.value, e);
                        }
                      }}
                      size='lg'
                    >
                      {stepList?.one.value ? (
                        <i className='fas fa-check'></i>
                      ) : (
                        <i className='fas fa-circle'></i>
                      )}
                    </Button>
                    {stepList?.one.value && (
                      <UncontrolledPopover
                        trigger='focus'
                        placement='top'
                        target={`one`}
                      >
                        <PopoverBody>
                          <span>취소하시겠습니까?</span> <br />
                          <div className='d-flex justify-content-end mt-2 confirm-step-btn'>
                            <span
                              className='mr-3'
                              onClick={() =>
                                handleClick("one", stepList?.one.value)
                              }
                            >
                              네
                            </span>
                            <span>아니오</span>
                          </div>
                        </PopoverBody>
                      </UncontrolledPopover>
                    )}
                    <div className='ml-3'>
                      <h3 className='d-flex justify-content-between align-items-center'>
                        육안검사
                        {stepList?.one.value && (
                          <span>
                            <i
                              className='fas fa-edit'
                              onClick={() => startUpdate("one")}
                            ></i>
                          </span>
                        )}
                      </h3>
                      <span>
                        {stepList?.one.value
                          ? currentDatehangle(stepList?.one.value) + " 완료"
                          : ""}
                      </span>
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>

            {/* 성능검사 */}
            <Card className='confirm-status-card'>
              <CardBody>
                {stepList?.two.isUpdate ? (
                  <div className='picker-container'>
                    <div className='d-flex justify-content-end mb-3'>
                      <i
                        className='fas fa-save'
                        onClick={() => saveUpdate("two")}
                      ></i>
                      <i
                        className='fas fa-window-close'
                        onClick={() => closeUpdate("two")}
                      ></i>
                    </div>
                    <div className='picker'>
                      <DatePicker
                        clearIcon={false}
                        inputReadOnly={true}
                        value={moment(updateForm?.two.date)}
                        className='mr-2'
                        onChange={(e) => {
                          console.log(e._d);
                          setUpdateForm({
                            ...updateForm,
                            two: {
                              ...updateForm.two,
                              date: e._d.getTime(),
                            },
                          });
                        }}
                      />
                      <TimePicker
                        clearIcon={false}
                        inputReadOnly={true}
                        format={"HH:mm"}
                        value={moment(updateForm?.two.time)}
                        onSelect={(value, dateString) => {
                          setUpdateForm({
                            ...updateForm,
                            two: {
                              ...updateForm.two,
                              time: value._d.getTime(),
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div className='step-conteiner'>
                    <Button
                      // color='primary'
                      style={{
                        backgroundColor: "rgb(80, 150, 255)",
                        color: "white",
                        borderRadius: "15px",
                      }}
                      // disabled={stepList?.two.value ? true : false}
                      id={`two`}
                      onClick={(e) => {
                        if (!stepList?.two.value) {
                          handleClick("two", stepList?.two.value, e);
                        }
                      }}
                      size='lg'
                    >
                      {stepList?.two.value ? (
                        <i className='fas fa-check'></i>
                      ) : (
                        <i className='fas fa-circle'></i>
                      )}
                    </Button>
                    {stepList?.two.value && (
                      <UncontrolledPopover
                        trigger='focus'
                        placement='top'
                        target={`two`}
                      >
                        <PopoverBody>
                          <span>취소하시겠습니까?</span> <br />
                          <div className='d-flex justify-content-end mt-2 confirm-step-btn'>
                            <span
                              className='mr-3'
                              onClick={() =>
                                handleClick("two", stepList?.two.value)
                              }
                            >
                              네
                            </span>
                            <span>아니오</span>
                          </div>
                        </PopoverBody>
                      </UncontrolledPopover>
                    )}
                    <div className='ml-3'>
                      <h3 className='d-flex justify-content-between align-items-center'>
                        성능검사
                        {stepList?.two.value && (
                          <span>
                            <i
                              className='fas fa-edit'
                              onClick={() => startUpdate("two")}
                            ></i>
                          </span>
                        )}
                      </h3>
                      <span>
                        {stepList?.two.value
                          ? currentDatehangle(stepList?.two.value) + " 완료"
                          : ""}
                      </span>
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>

            {/* 치수검사 */}
            <Card className='confirm-status-card'>
              <CardBody>
                {stepList?.three.isUpdate ? (
                  <div className='picker-container'>
                    <div className='d-flex justify-content-end mb-3'>
                      <i
                        className='fas fa-save'
                        onClick={() => saveUpdate("three")}
                      ></i>
                      <i
                        className='fas fa-window-close'
                        onClick={() => closeUpdate("three")}
                      ></i>
                    </div>
                    <div className='picker'>
                      <DatePicker
                        clearIcon={false}
                        inputReadOnly={true}
                        value={moment(updateForm?.three.date)}
                        className='mr-2'
                        onChange={(e) => {
                          console.log(e._d);
                          setUpdateForm({
                            ...updateForm,
                            three: {
                              ...updateForm.three,
                              date: e._d.getTime(),
                            },
                          });
                        }}
                      />
                      <TimePicker
                        clearIcon={false}
                        inputReadOnly={true}
                        format={"HH:mm"}
                        value={moment(updateForm?.three.time)}
                        onSelect={(value, dateString) => {
                          setUpdateForm({
                            ...updateForm,
                            three: {
                              ...updateForm.three,
                              time: value._d.getTime(),
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div className='step-conteiner'>
                    <Button
                      // color='primary'
                      style={{
                        backgroundColor: "rgb(80, 150, 255)",
                        color: "white",
                        borderRadius: "15px",
                      }}
                      // disabled={stepList?.three.value ? true : false}
                      id={`three`}
                      onClick={(e) => {
                        if (!stepList?.three.value) {
                          handleClick("three", stepList?.three.value, e);
                        }
                      }}
                      size='lg'
                    >
                      {stepList?.three.value ? (
                        <i className='fas fa-check'></i>
                      ) : (
                        <i className='fas fa-circle'></i>
                      )}
                    </Button>
                    {stepList?.three.value && (
                      <UncontrolledPopover
                        trigger='focus'
                        placement='top'
                        target={`three`}
                      >
                        <PopoverBody>
                          <span>취소하시겠습니까?</span> <br />
                          <div className='d-flex justify-content-end mt-2 confirm-step-btn'>
                            <span
                              className='mr-3'
                              onClick={() =>
                                handleClick("three", stepList?.three.value)
                              }
                            >
                              네
                            </span>
                            <span>아니오</span>
                          </div>
                        </PopoverBody>
                      </UncontrolledPopover>
                    )}
                    <div className='ml-3'>
                      <h3 className='d-flex justify-content-between align-items-center'>
                        치수검사
                        {stepList?.three.value && (
                          <span>
                            <i
                              className='fas fa-edit'
                              onClick={() => startUpdate("three")}
                            ></i>
                          </span>
                        )}
                      </h3>
                      <span>
                        {stepList?.three.value
                          ? currentDatehangle(stepList?.three.value) + " 완료"
                          : ""}
                      </span>
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>

            {/* 샘플링 검사 */}
            <Card className='confirm-status-card'>
              <CardBody>
                {stepList?.four.isUpdate ? (
                  <div className='picker-container'>
                    <div className='d-flex justify-content-end mb-3'>
                      <i
                        className='fas fa-save'
                        onClick={() => saveUpdate("four")}
                      ></i>
                      <i
                        className='fas fa-window-close'
                        onClick={() => closeUpdate("four")}
                      ></i>
                    </div>
                    <div className='picker'>
                      <DatePicker
                        clearIcon={false}
                        inputReadOnly={true}
                        value={moment(updateForm?.four.date)}
                        className='mr-2'
                        onChange={(e) => {
                          console.log(e._d);
                          setUpdateForm({
                            ...updateForm,
                            four: {
                              ...updateForm.four,
                              date: e._d.getTime(),
                            },
                          });
                        }}
                      />
                      <TimePicker
                        clearIcon={false}
                        inputReadOnly={true}
                        format={"HH:mm"}
                        value={moment(updateForm?.four.time)}
                        onSelect={(value, dateString) => {
                          setUpdateForm({
                            ...updateForm,
                            four: {
                              ...updateForm.four,
                              time: value._d.getTime(),
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div className='step-conteiner'>
                    <Button
                      // color='primary'
                      style={{
                        backgroundColor: "rgb(80, 150, 255)",
                        color: "white",
                        borderRadius: "15px",
                      }}
                      // disabled={stepList?.four.value ? true : false}
                      id={`four`}
                      onClick={(e) => {
                        if (!stepList?.four.value) {
                          handleClick("four", stepList?.four.value, e);
                        }
                      }}
                      size='lg'
                    >
                      {stepList?.four.value ? (
                        <i className='fas fa-check'></i>
                      ) : (
                        <i className='fas fa-circle'></i>
                      )}
                    </Button>
                    {stepList?.four.value && (
                      <UncontrolledPopover
                        trigger='focus'
                        placement='top'
                        target={`four`}
                      >
                        <PopoverBody>
                          <span>취소하시겠습니까?</span> <br />
                          <div className='d-flex justify-content-end mt-2 confirm-step-btn'>
                            <span
                              className='mr-3'
                              onClick={() =>
                                handleClick("four", stepList?.four.value)
                              }
                            >
                              네
                            </span>
                            <span>아니오</span>
                          </div>
                        </PopoverBody>
                      </UncontrolledPopover>
                    )}
                    <div className='ml-3'>
                      <h3 className='d-flex justify-content-between align-items-center'>
                        샘플링검사
                        {stepList?.four.value && (
                          <span>
                            <i
                              className='fas fa-edit'
                              onClick={() => startUpdate("four")}
                            ></i>
                          </span>
                        )}
                      </h3>
                      <span>
                        {stepList?.four.value
                          ? currentDatehangle(stepList?.four.value) + " 완료"
                          : ""}
                      </span>
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>
          </div>

          <CheckErrorData
            productList={productList}
            changeErrorForm={changeErrorForm}
          />

          <ProduceTable
            isNext={isNext}
            userRequest={userRequest}
            productOrder={productOrder}
            setProductOrder={setProductOrder}
            handleRequestApi={handleRequestApi}
            orderProduceTableData={orderProduceTableData}
            productList={productList}
            setErrMessage={setErrMessage}
            errMessage={errMessage}
            targetKey={ErrorValue}
            userInputQuantity={userInputQuantity}
            handleChangeErrorProduct={handleChangeErrorProduct}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default ConfirmTab;
