import React, { useState, useEffect } from "react";
import Autocomplete from "react-autocomplete";
import { debounce } from "lodash";
const AutoCompleteInstitution = ({
  placeholder,
  handleChange,
  formData,
  itemList,
  inputType,
}) => {
  const [selected, setSelected] = useState(formData?.[inputType]);

  useEffect(() => {
    handleChange(selected);
  }, [selected]);

  return (
    <div id='auto-complete'>
      <Autocomplete
        getItemValue={(item) => item}
        items={itemList.filter((item) => {
          if (
            item.toUpperCase().includes(formData?.[inputType].toUpperCase())
          ) {
            return true;
          }
          return false;
        })}
        wrapperStyle={{
          position: "relative",
        }}
        menuStyle={{
          top: "calc(1.5em + 1.25rem + 2px)",
          left: 0,
          position: "absolute",
        }}
        renderItem={(item, isHighlighted) => (
          <option
            key={item}
            className='auto-item pl-2'
            style={{
              background: isHighlighted ? "lightgray" : "white",
              height: "calc(1.5em + 1.25rem + 2px)",
            }}
          >
            {item}
          </option>
        )}
        value={formData?.[inputType]}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
        onSelect={(val) => setSelected(val)}
        inputProps={{
          placeholder,
          maxLength: 30,
        }}
      />
    </div>
  );
};

export default AutoCompleteInstitution;
