import React, { useState, useEffect } from "react";
import { Input, Card, CardBody, Alert } from "reactstrap";
import _ from "lodash";

const title = ["육안검사", "성능검사", "치수검사", "셈플링검사"];

const confirmData = ["one", "two", "three", "four"];

const StepCheckData = ({ product, productForm, changeErrorForm }) => {
  const handleNumber = (e, valueType, confirmType, isAuto = true) => {
    let number = e.target.value.replace(/[^0-9]/g, "");
    number = number === "" ? "" : Number(number);

    changeErrorForm(number, valueType, productForm, confirmType, isAuto);
  };
  return (
    <>
      <div className='assemble-quantity mt-3'>
        {new Array(4).fill(undefined).map((item, index) => {
          return (
            <Card key={index} className='p-3 assemble-quantity-card'>
              <label className='form-control-label' htmlFor='input-username'>
                * {title[index]}
              </label>
              <div className='d-flex align-items-center'>
                <Input
                  onChange={(e) => {
                    handleNumber(e, "value", confirmData[index]);
                  }}
                  placeholder='불량수량'
                  type='text'
                  className='text-right'
                  maxLength={8}
                  value={
                    productForm?.confirmData[confirmData[index]] === undefined
                      ? ""
                      : productForm?.confirmData[confirmData[index]].value
                  }
                />
                <span className='mx-2'>/</span>

                <Input
                  onChange={(e) => {
                    handleNumber(e, "total", confirmData[index], false);
                  }}
                  placeholder='검수수량'
                  type='text'
                  value={
                    productForm?.confirmData[confirmData[index]] === undefined
                      ? ""
                      : productForm?.confirmData[confirmData[index]].total
                  }
                  className='text-right'
                />
              </div>
            </Card>
          );
        })}
      </div>
    </>
  );
};

export default StepCheckData;
