/** @format */

import React from "react";
import { useState } from "react";
import _ from "lodash";
import designApi from "services/design";
import { useEffect } from "react";
import { setIsLoading } from "store/actions/user";
import { useDispatch } from "react-redux";

// reactstrap
import useNextStep from "../../../hooks/useNextStep";
import useIsMount from "../../../hooks/useIsMount";

import {
  Card,
  CardBody,
  FormGroup,
  CustomInput,
  Button,
  Col,
  Row,
} from "reactstrap";

const DesignDrawTab = ({
  order,
  isNext,
  handleSubmit,
  activeTab,
  setUserActive,
  userRequest,
  handleRequestEnd,
}) => {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");

  const [fileList, setFileList] = useState([]);
  const [savedFile, setSavedFile] = useState([]);
  const [isuseNext, setIsuseNext] = useState(false);
  const isMount = useIsMount();
  const dispatch = useDispatch();

  useNextStep({ order, isuseNext, handleSubmit, handleRequestEnd });

  useEffect(() => {
    if (userRequest || isNext) {
      handleUpload();
    }
  }, [userRequest, isNext]);

  // Design 가져오기
  useEffect(() => {
    designApi
      .requestReadDesign({
        params: {
          orderID: id,
          tab: activeTab,
        },
      })
      .then((res) => {
        console.log(res);
        if (isMount.current) {
          setFileList([...res]);
          setSavedFile([...res]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id, activeTab]);

  // 저장 버튼 활성화 및 inpuit value 값 넣기
  useEffect(() => {
    const label = document.querySelector(".custom-file-label");
    if (label) {
      if (!_.isEmpty(fileList)) {
        let names = "";

        for (let i = 0; i < fileList.length; i++) {
          if (i === 0) {
            names += fileList[i].name;
          } else {
            names += ` ,${fileList[i].name}`;
          }
        }
        label.innerText = names;
      } else {
        label.innerText = "";
      }
    }
  }, [fileList]);

  // 파일 찾기
  const handleFile = (e) => {
    dispatch(setIsLoading(true));
    setUserActive(true);
    const newFile = [];
    for (let [key, value] of Object.entries(e.target.files)) {
      newFile.push(value);
    }
    setFileList([...fileList, ...newFile]);
    dispatch(setIsLoading(false));
  };

  // 드래그 온 드랍
  const handleDrop = (e) => {
    dispatch(setIsLoading(true));
    setUserActive(true);
    const newFile = [];

    for (let [key, value] of Object.entries(e.dataTransfer.files)) {
      newFile.push(value);
    }
    setFileList([...fileList, ...newFile]);
    dispatch(setIsLoading(false));
  };

  // 드래드 온 드랍 텍스트
  const [isover, setIsover] = useState(false);

  // 파일 삭제
  const handleDelete = (target) => {
    setUserActive(true);
    setFileList([...fileList.filter((item) => item !== target)]);
  };

  // 제출 요청
  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("tab", activeTab);
    for (let item of fileList) {
      if (item instanceof File) {
        formData.append("uploadFile", item);
      }
    }
    // 기존 데이터 삭제
    for (let item of savedFile) {
      if (!fileList.includes(item)) {
        formData.append("deleteFile", JSON.stringify(item));
      }
    }
    try {
      const data = await designApi.requestCreateDesign(formData, {
        params: {
          orderID: id,
        },
      });
      if (!isNext) {
        handleRequestEnd();
      } else {
        setIsuseNext(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Card className='mb-5'>
        <CardBody>
          <div className='d-flex justify-content-end mb-4'>
            {/* <Button
                color='secondary'
                href='#pablo'
                onClick={(e) => e.preventDefault()}
                size='sm'>
                수정
              </Button>
              <Button
                color='secondary'
                href='#pablo'
                onClick={(e) => e.preventDefault()}
                size='sm'>
                저장
              </Button> */}
          </div>
          <FormGroup>
            <CustomInput
              type='file'
              id='fileBrowserExample'
              name='customFile'
              label={"choose an image file"}
              multiple
              onChange={(e) => handleFile(e)}
            />
          </FormGroup>

          {isover && (
            <div
              className='drag-and-drop card-body'
              onDrop={(e) => {
                e.preventDefault();
                handleDrop(e);
                setIsover(false);
              }}
              onDragOver={(e) => e.preventDefault()}
              onDragLeave={() => setIsover(false)}
            >
              <h1 className='drag-text'>Drag and Drop</h1>
            </div>
          )}

          <Card
            className='drag-and-drop-container'
            style={{
              border: "1px solid #cad1d7",
              height: "350px",
              overflow: "auto",
              position: "relative",
            }}
            onDrop={(e) => {
              e.preventDefault();
              handleDrop(e);
            }}
            onDragOver={(e) => {
              e.preventDefault();
              setIsover(true);
            }}

            // @drop.prevent="imgUpload"  @dragover.prevent
          >
            <CardBody>
              <Row>
                {fileList.map((item, index) => (
                  <div
                    key={index}
                    style={{ color: "#525f7f" }}
                    className='d-flex justify-content-between p-4 m-2 file-item'
                  >
                    <span>
                      {index + 1}. &nbsp;&nbsp;&nbsp; {item.name}
                    </span>
                    <span onClick={() => handleDelete(item)} className='delete'>
                      X
                    </span>
                  </div>
                ))}
              </Row>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </>
  );
};

export default DesignDrawTab;
