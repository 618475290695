/** @format */

import order from "./modules/order";
import user from "./modules/user";
import { combineReducers } from "redux";

const reducer = combineReducers({
  order,
  user,
});

export default reducer;
