/** @format */

import React, { useEffect } from "react";

import { useState } from "react";
import {
  Col,
  Row,
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Label,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
} from "reactstrap";
import ModelAPI from "services/model";

import { findIndex } from "lodash";

const initList = new Array(20)
  .fill("")
  .map((item, index) => ({ id: index + 1, name: "" }));

const ModelModal = ({ modalOpen, toggleMadal, onSumbit }) => {
  const [modelList, setModelList] = useState(initList);

  useEffect(() => {
    ModelAPI.requestReadModelList().then((res) => {
      setModelList(res);
    });
  }, []);

  const handleSubmit = async () => {
    ModelAPI.requestUpdateModel({
      modelList,
    })
      .then((res) => {
        console.log(res);
        toggleMadal();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Modal size='l' isOpen={modalOpen} toggle={toggleMadal}>
        <ModalHeader toggle={toggleMadal} tag='h3'>
          모델 등록
        </ModalHeader>
        <ModalBody>
          <div
            className='orderform'
            style={{
              height: "70vh",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <Row>
              <Col xs={12}>
                <Form role='form'>
                  <Table>
                    <tbody>
                      {modelList.map((item, index) => {
                        const { id, name } = item;
                        return (
                          <tr className='d-flex align-items-center' key={id}>
                            <td className='border-0'>{index}.</td>
                            <td
                              className='border-0'
                              style={{
                                flexShrink: 0,
                                flexGrow: 1,
                                flex: "0 0 85%",
                              }}
                            >
                              <Input
                                className=''
                                placeholder='모델명'
                                type='text'
                                value={name}
                                onChange={(e) => {
                                  setModelList((pre) =>
                                    pre.map((modelItem) => {
                                      if (modelItem.id === id) {
                                        return {
                                          ...modelItem,
                                          name: e.target.value,
                                        };
                                      }
                                      return modelItem;
                                    })
                                  );
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Form>
              </Col>
            </Row>
          </div>
          <div className='custom-control custom-control-alternative custom-checkbox'>
            <input
              className='custom-control-input'
              id=' customCheckLogin'
              type='checkbox'
            />
          </div>
          <div className='text-center'>
            <Button
              className='mb-4'
              color='primary'
              type='button'
              onClick={handleSubmit}
            >
              등록
            </Button>
            <Button
              className='mb-4'
              color='danger'
              type='button'
              onClick={toggleMadal}
            >
              취소
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ModelModal;
