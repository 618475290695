import React, { useState, useEffect } from "react";
import { Input, Card, CardBody, Alert } from "reactstrap";
import _ from "lodash";
import StepCheckData from "./StepCheckData";

const CheckErrorData = ({ productList, productForm, changeErrorForm }) => {
  return (
    <>
      <hr />
      {productList.map((item, index) => {
        return (
          <div key={index} className='mb-4'>
            <div className='d-flex justify-content-between mb-4'>
              <div className='d-flex align-items-center'>
                <span>* {item.name} &nbsp;&nbsp; ( 불량수량/검사수량 )</span>
              </div>
            </div>
            <StepCheckData
              changeErrorForm={changeErrorForm}
              product={item}
              productForm={item}
            />
          </div>
        );
      })}
    </>
  );
};

export default CheckErrorData;
