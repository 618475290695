import React from "react";
import DetailPageSub from "./DetailPageSub";
import { message } from "antd";

export default function DetailPage() {
  const [messageApi, contextHolder] = message.useMessage();

  return (
    <>
      {contextHolder}
      <DetailPageSub messageApi={messageApi} />
    </>
  );
}
