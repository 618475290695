/** @format */

import axios from "axios";

class ModelAPI {
  constructor() {
    this.URL = "";
    this.get = async (url, config) => {
      const res = await axios.get(url, { ...config });
      return res.data;
    };
    this.post = async (url, data, config = {}) => {
      const res = await axios.post(url, data, { ...config });
      console.log(config);
      return res.data;
    };
    this.put = async (url, data, config = {}) => {
      const res = await axios.put(url, data, { ...config });
      return res.data;
    };
    this.delete = async (url, config) => {
      const res = await axios.delete(url, { ...config });
      return res.data;
    };
  }

  // model list 가져오기
  async requestReadModelList(config) {
    const url = "/api/main/model";
    return this.get(url, config);
  }

  // model 만들기
  async requestCreateModel(data) {
    const url = "/api/main/model";
    return this.post(url, data);
  }

  // model 수정
  async requestUpdateModel(data) {
    const url = "/api/main/model";
    return this.put(url, data);
  }
}

export default new ModelAPI();
