/** @format */

import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import classnames from "classnames";
import { useDispatch } from "react-redux";
import { setTargetStatus } from "store/actions/order";
import { statusData } from "pages/js/orderData";
import default_img from "assets/img/theme/default_img.jpg";
import design_img from "assets/img/theme/design_img.jpg";
import order_img from "assets/img/theme/order_img.jpg";
import in_img from "assets/img/theme/in_img.jpg";
import assemble_img from "assets/img/theme/assemble_img.jpg";
import confirm_img from "assets/img/theme/confirm_img.jpg";
import drive_img from "assets/img/theme/drive_img.jpg";
import finish_img from "assets/img/theme/finish_img.jpg";
import { setActiveTab } from "store/actions/order";

const imgsrc = [
  `url(${default_img})`,
  `url(${design_img})`,
  `url(${order_img})`,
  `url(${in_img})`,
  `url(${assemble_img})`,
  `url(${confirm_img})`,
  `url(${drive_img})`,
  `url(${finish_img})`,
];

const StatusCard = ({ order, handleImageStep }) => {
  const dispatch = useDispatch();

  const features = statusData;

  const handleClick = (step) => {
    handleImageStep(step.toString());
  };

  return (
    <>
      <div className='card-container mb-7 mt-3'>
        {features.map((feature, key) => (
          <div key={key} className='pb-5 d-flex align-items-center'>
            <Card
              className={classnames(
                "progress-dark order-card border-0 text-center p-1 shadow  ",
                {
                  beforeStep:
                    order?.clientStatus !== feature.id &&
                    order?.status >= feature.id,
                },
                {
                  clientTarget: order?.clientStatus === feature.id,
                }
              )}
              style={{
                backgroundImage: imgsrc[key],
                filter: order?.status < feature.id ? "grayscale(100%)" : "none",
              }}
              onClick={() =>
                order?.status >= feature.id ? handleClick(feature.id) : ""
              }>
              <CardBody className=''>
                <div
                  className='d-flex align-items-center justify-content-center'
                  style={{ height: "calc(150px - 1rem)" }}></div>
                <h4 className=''>{feature.title}</h4>
              </CardBody>
            </Card>
            {order?.status < feature.id ? (
              <div className='block-card order-card'></div>
            ) : (
              ""
            )}
            {features.length - 1 !== key ? (
              <i className='fas fa-angle-double-right'></i>
            ) : (
              ""
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default StatusCard;
