/** @format */

import React from "react";

import {
  Card,
  CardBody,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
} from "reactstrap";
import { useState } from "react";
import classnames from "classnames";
import { ThreeDots } from "react-loader-spinner";

//  tab content
import DefaultInfo from "./default/DefaultInfoTab";
import PurchaseTab from "./purchase/PurchaseTab";
import DesignDrawTab from "./design/DesignDrawTab";
import NextStepModal from "./NextStepModal";
import AssembleTab from "./assemble/AssembleTab";
import ConfirmTab from "./confirm/ConfirmTab";
import DriveTab from "./drive/DriveTab";
import PurchaseInTab from "./purchaseIn/PurchaseInTab";

import { useEffect, useRef } from "react";
import OrderComplete from "./complete/OrderComplete";
import { statusData } from "pages/js/orderData";
import { useSelector, useDispatch } from "react-redux";
import { setActiveTab } from "store/actions/order";
import StatusCard from "../StatusCard";
import SaveModal from "../components/SaveModal";
import { setTargetStatus } from "store/actions/order";

let targetCardStep = "0";

const StepTabIndex = ({
  order,
  productList,
  handleConnectSerial,
  isSerialConnected,
  handleup,
  sevenPinValue,
  keepReading,
  writer,
  reader,
  messageApi,
  weldingTime,
  setIsReceive,
  isReceive,
  setWeldingTime,
  weldingStartState,
}) => {
  const [ismodal, toggleModal] = useState(false);
  const [updateModal, setUpdateModal] = useState({
    name: "",
    value: false,
  }); // 수정 모달

  const [userActive, setUserActive] = useState(false); // 사용자 수정있었는지 체크
  const [userRequest, setUserRequest] = useState(false); // 요청 보낼지 말지 (모달)

  const { activeTab } = useSelector((state) => ({
    activeTab: state.order.activeTab,
  }));

  const dispatch = useDispatch();

  // 그림 사진 눌렀을 때 (설계탭에서 activetab이 1이라면 구매품입를 누르면 0이되어야 함)
  useEffect(() => {
    dispatch(setActiveTab("0"));
  }, [order?.clientStatus]);

  // 기본 정보 수정

  // 현재 탭 로컬 스토리지 저장
  const activeRef = useRef(activeTab);
  const handleActiveTab = (data) => {
    // 여기서 수정을 확인하고 요청 한다. (userActive)
    // 그리고 tab을 바꾼다.
    activeRef.current = data;
    if (userActive) {
      setUpdateModal({
        name: "tab",
        value: true,
      });
    } else {
      dispatch(setActiveTab(data));
      handleMoveReset();
    }
  };

  const saveActiveTab = () => {
    // localStorage.setItem("activeTab", activeRef.current);
  };

  useEffect(() => {
    window.addEventListener("beforeunload", saveActiveTab);
    return () => {
      localStorage.setItem("activeTab", "0");
    };
  }, []);

  const navitems = statusData.map((item) => [...item.navTab]);

  // 다음 단계
  const [isNext, setIsNext] = useState(false);
  const nextstepHanddler = (id) => {
    toggleModal(false);
    setIsNext(true);
  };

  // status + 1 제출 성공
  const handleSubmit = (data) => {
    setIsNext(false);
    // dispatch(setActiveTab("0"));
    // if (data) {
    //   handleMoveReset();
    //   localStorage.setItem("activeTab", "0");
    // }
  };

  // 설계자료 또는 PDF 다운로드
  const [isDownload, setIsDownLoad] = useState(false);

  const handleRequestEnd = () => {
    handleMoveReset();
    if (updateModal.name === "card") {
      dispatch(setActiveTab("0"));
      dispatch(
        setTargetStatus({
          id: order.id,
          clientStatus: Number(targetCardStep),
        })
      );
    } else if (updateModal.name === "tab") {
      changeTab();
    }
  };

  const [stepListConfirm, setStepListConfirm] = useState(false);
  const [isStepConfirm, setIsStepConfirm] = useState(false);

  const tabitems = [
    [
      <DefaultInfo
        setIsNext={setIsNext}
        handleSubmit={handleSubmit}
        isNext={isNext}
        activeTab={activeTab}
        order={order}
        productList={productList}
        userActive={userActive}
        setUserActive={setUserActive}
        userRequest={userRequest}
        handleRequestEnd={handleRequestEnd}
        handleConnectSerial={handleConnectSerial}
        isSerialConnected={isSerialConnected}
        handleup={handleup}
        sevenPinValue={sevenPinValue}
      />,
    ],
    [
      <DesignDrawTab
        setIsNext={setIsNext}
        handleSubmit={handleSubmit}
        isNext={isNext}
        activeTab={activeTab}
        order={order}
        userActive={userActive}
        setUserActive={setUserActive}
        userRequest={userRequest}
        handleRequestEnd={handleRequestEnd}
      />,
      <DesignDrawTab
        setIsNext={setIsNext}
        handleSubmit={handleSubmit}
        isNext={isNext}
        activeTab={activeTab}
        order={order}
        userActive={userActive}
        setUserActive={setUserActive}
        userRequest={userRequest}
        handleRequestEnd={handleRequestEnd}
      />,
    ],
    [
      <PurchaseTab
        setIsNext={setIsNext}
        handleSubmit={handleSubmit}
        isNext={isNext}
        activeTab={activeTab}
        order={order}
        handleRequestEnd={handleRequestEnd}
      />,
      <PurchaseTab
        setIsNext={setIsNext}
        handleSubmit={handleSubmit}
        isNext={isNext}
        activeTab={activeTab}
        order={order}
        handleRequestEnd={handleRequestEnd}
      />,
    ],

    [
      <PurchaseInTab
        setIsNext={setIsNext}
        handleSubmit={handleSubmit}
        isNext={isNext}
        activeTab={activeTab}
        order={order}
        userActive={userActive}
        setUserActive={setUserActive}
        handleRequestEnd={handleRequestEnd}
        userRequest={userRequest}
      />,
    ],
    [
      <AssembleTab
        setIsNext={setIsNext}
        handleSubmit={handleSubmit}
        isNext={isNext}
        activeTab={activeTab}
        order={order}
        userActive={userActive}
        setUserActive={setUserActive}
        handleRequestEnd={handleRequestEnd}
        userRequest={userRequest}
        setStepListConfirm={setStepListConfirm}
        isStepConfirm={isStepConfirm}
        setIsStepConfirm={setIsStepConfirm}
        stepListConfirm={stepListConfirm}
        handleConnectSerial={handleConnectSerial}
        isSerialConnected={isSerialConnected}
        handleup={handleup}
        sevenPinValue={sevenPinValue}
        writer={writer}
        keepReading={keepReading}
        isReceive={isReceive}
        messageApi={messageApi}
        weldingTime={weldingTime}
        setIsReceive={setIsReceive}
        setWeldingTime={setWeldingTime}
        weldingStartState={weldingStartState}
      />,
    ],
    [
      <ConfirmTab
        setIsNext={setIsNext}
        handleSubmit={handleSubmit}
        isNext={isNext}
        activeTab={activeTab}
        order={order}
        userActive={userActive}
        setUserActive={setUserActive}
        handleRequestEnd={handleRequestEnd}
        userRequest={userRequest}
        setStepListConfirm={setStepListConfirm}
        isStepConfirm={isStepConfirm}
        setIsStepConfirm={setIsStepConfirm}
        stepListConfirm={stepListConfirm}
        setUpdateModal={setUpdateModal}
      />,
    ],
    // [
    //   <DriveTab
    //     setIsNext={setIsNext}
    //     handleSubmit={handleSubmit}
    //     isNext={isNext}
    //     activeTab={activeTab}
    //     order={order}
    //     userActive={userActive}
    //     setUserActive={setUserActive}
    //     handleRequestEnd={handleRequestEnd}
    //     userRequest={userRequest}
    //     setStepListConfirm={setStepListConfirm}
    //     isStepConfirm={isStepConfirm}
    //     setIsStepConfirm={setIsStepConfirm}
    //     stepListConfirm={stepListConfirm}
    //   />,
    // ],
    [
      <OrderComplete
        order={order}
        setIsDownLoad={setIsDownLoad}
        isDownload={isDownload}
      />,
    ],
  ];

  // 이미지 클릭
  const handleImageStep = (step) => {
    targetCardStep = step;
    if (stepListConfirm) {
      setIsStepConfirm(true);
      return;
    }
    if (userActive) {
      setUpdateModal({
        name: "card",
        value: true,
      });
    } else {
      dispatch(setActiveTab("0"));
      dispatch(
        setTargetStatus({
          id: order.id,
          clientStatus: Number(targetCardStep),
        })
      );
    }
  };

  // 요청 보낼지 말지 모달 핸들링 (image Or tab 클릭)
  const handleUpdateModal = (go) => {
    const { name, value } = updateModal;
    if (name === "card") {
      if (go) {
        // 요청보내고 step 바꿈
        setUserRequest({
          name: "card",
        });
      } else {
        dispatch(setActiveTab("0"));
        dispatch(
          setTargetStatus({
            id: order.id,
            clientStatus: Number(targetCardStep),
          })
        );
        handleMoveReset();
      }
    } else {
      if (go) {
        // 요청 보내기 (요청보내고 탭 바꿈)
        setUserRequest({
          name: "tab",
          tab: activeTab,
        });
      } else {
        // 탭 바꾸기
        changeTab();
      }
    }
  };

  // 탭 바꾸기
  const changeTab = () => {
    dispatch(setActiveTab(activeRef.current));
    handleMoveReset();
  };

  // 이동된 후 다 초기화
  const handleMoveReset = () => {
    setUpdateModal({
      name: "",
      value: false,
    });
    setUserRequest(false);
    setUserActive(false);
    setIsNext(false);
  };

  return (
    <>
      <Row className='status_card_container d-flex justify-content-center mx-auto'>
        <StatusCard order={order} handleImageStep={handleImageStep} />
      </Row>
      <div className=''></div>
      {order?.clientStatus !== navitems.length - 1 ? (
        <Nav className='nav mt-5 d-flex justify-content-between'>
          <div className='nav'>
            {navitems[order?.clientStatus]?.map((item, index) => (
              <NavItem key={index}>
                <NavLink
                  to='#'
                  className={classnames(
                    {
                      active: activeTab === `${index}`,
                    },
                    {
                      deactive: activeTab !== `${index}`,
                    },
                    "px-3",
                    "nav",
                    "mr-3"
                  )}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleActiveTab(`${index}`);
                  }}
                >
                  {item}
                </NavLink>
              </NavItem>
            ))}
            {order?.clientStatus === order?.status && (
              <NavItem>
                <NavLink
                  to='#'
                  className={classnames(
                    {
                      active:
                        activeTab === `${navitems[order?.status]?.length}`,
                    },
                    {
                      deactive:
                        activeTab !== `${navitems[order?.status]?.length}`,
                    },
                    "px-3",
                    "nav"
                  )}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    toggleModal(true);
                  }}
                >
                  다음 단계
                </NavLink>
              </NavItem>
            )}
          </div>
          {![2, 7].includes(order?.clientStatus) && (
            <NavItem>
              <NavLink
                to='#'
                className={classnames("deactive", "px-3", "nav", "mr-3")}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setUserRequest({
                    name: "card",
                  });
                }}
              >
                저장
              </NavLink>
            </NavItem>
          )}

          {/* 사용자가 원해서 보는 화면과 실제 주문 상태가 다르면 다음단계 버튼이 없음 */}

          {/* 기본정보는 다음단계 로직이 다름 그래서 수정이라는 버튼 달아주기 */}
          {/* {order?.clientStatus !== order?.status && order?.clientStatus === 0 && (
            <NavItem>
              <NavLink
                to='#'
                className={classnames(
                  {
                    active: activeTab === `${navitems[order?.status]?.length}`,
                  },
                  {
                    deactive:
                      activeTab !== `${navitems[order?.status]?.length}`,
                  },
                  "px-3",
                  "nav"
                )}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setIsDefault(true);
                }}>
                수정
              </NavLink>
            </NavItem>
          )} */}
        </Nav>
      ) : (
        <div className='mt-5'>
          <div className='d-flex justify-content-end mt-6'>
            <Button
              color='secondary'
              disabled={isDownload ? true : false}
              onClick={() => setIsDownLoad("design")}
            >
              <i className='fas fa-file-archive'></i> &nbsp;설계자료
            </Button>
            <Button
              color='secondary'
              disabled={isDownload ? true : false}
              onClick={() => setIsDownLoad("pdf")}
            >
              <i className='fas fa-file-pdf'></i> &nbsp;PDF
            </Button>
            <Button
              color='secondary'
              disabled={isDownload ? true : false}
              onClick={(e) => {
                e.preventDefault();
                setIsDownLoad("excel");
              }}
            >
              <i className='fas fa-file-csv'></i> &nbsp;KPI
            </Button>
          </div>
        </div>
      )}
      <br />
      <TabContent activeTab={activeTab}>
        {tabitems[order?.clientStatus]?.map((item, index) => {
          return (
            <TabPane key={index} tabId={`${index}`}>
              {activeTab === `${index}` && item}
            </TabPane>
          );
        })}
      </TabContent>
      <NextStepModal
        ismodal={ismodal}
        toggleModal={toggleModal}
        nextstepHanddler={nextstepHanddler}
        order={order}
      />
      {isDownload && (
        <div className='loading'>
          <div className='loading-child'>
            <ThreeDots
              height='80'
              width='80'
              radius='9'
              color='#4fa94d'
              ariaLabel='three-dots-loading'
              wrapperStyle={{}}
              wrapperClassName=''
              visible={true}
            />
          </div>
        </div>
      )}
      <SaveModal
        ismodal={updateModal}
        toggleModal={setUpdateModal}
        handleUpdateModal={handleUpdateModal}
      />
    </>
  );
};

export default StepTabIndex;
